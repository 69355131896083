<template>
    <div class="images-wrap">
        <div class="image-item" v-for="image in image_list" :key="image"
             :class="{'multiple':typeof multiple !== 'undefined' && multiple}">
            <el-tooltip class="item" effect="dark" content="Remove image" placement="top">
                <div class="image-remove-button" @click="removeImage(image)"></div>
            </el-tooltip>
            <div class="image-thumbnail" @click="$emit('click', image)">
                <img :src="replaceUrl(image)"/>
            </div>
        </div>

        <div class="image-add" v-if="showImageUpload"
             :class="{'full-width':typeof multiple !== 'undefined' && !multiple}">
            <el-upload :action="this.endpoint" multiple drag name="images[]" :on-progress="handleImageUploadProgress"
                       :show-file-list="false" :on-success="handleImageUploadSuccess"
                       :before-upload="handleBeforeImageUpload">

                <div v-show="!upload_in_progress" class="inner">
                    <img src="../../assets/img/add_image_icon.svg" width="50" alt="">&nbsp;&nbsp;&nbsp;&nbsp;+ {{(title
                    ? title : 'Add Image')}}
                </div>
                <div v-show="upload_in_progress" class="inner">
                    <div class="text-center">
                        <i class="el-icon-loading"></i>
                    </div>
                </div>

            </el-upload>
        </div>
    </div>
</template>
<style lang="less">
    .images-wrap {
        margin: 0 -15px;
        display: flex;
        flex-wrap: wrap;

        .image-item {
            width: 50%;
            display: inline-block;
            position: relative;
            padding: 15px;
            cursor: pointer;

            &:hover {
                .image-thumbnail {
                    &:before {
                        opacity: 1;
                    }
                }

                .image-remove-button {
                    display: block !important;
                }
            }

            .image-thumbnail {
                position: relative;
                border-radius: 3px;
                -webkit-border-radius: 3px;
                overflow: hidden;
                height: 90px;

                &:active {
                    cursor: grabbing;
                    cursor: -moz-grabbing;
                    cursor: -webkit-grabbing;
                }

                img {
                    width: 100%;
                }

                &.multiple:before {
                    transition: opacity 0.3s;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 2;
                    content: ' ';
                    display: block;
                    background-color: rgba(1, 26, 34, 0.4);
                    opacity: 0;
                }

                &.multiple:before {
                    background: url("../../assets/img/drag_icon.svg") no-repeat center center;
                }
            }

            .image-remove-button {
                cursor: pointer;
                background: url("../../assets/img/remove_video_icon.svg") no-repeat;
                position: absolute;
                top: 23px;
                right: 23px;
                z-index: 99;
                width: 23px;
                height: 23px;
                display: none;
            }
        }

        .image-add {
            width: 50%;
            display: inline-block;
            position: relative;
            padding: 15px;
            cursor: pointer;

            &.full-width {
                display: block;
                width: 100%;
            }

            .el-upload {
                display: block;
            }

            .el-upload-dragger {
                width: 100% !important;
                border: none !important;
                height: auto;
            }

            .el-upload__input {
                display: none !important;
            }

            .inner {
                height: 90px;
                background: #F4FDFF;
                color: #00ACDC;
                border-radius: 3px;
                -webkit-border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 3px;
                -webkit-border-radius: 3px;
                font-size: 14px;
            }
        }
    }
</style>
<script>

    export default {
        name: 'ImageInput',
        props: ['value', 'multiple', 'user', 'allowedType', 'title', 'video_id', 'images', 'type'],
        data() {
            return {
                image_list: [],
                upload_in_progress: false,
                endpoint: `${process.env.VUE_APP_BACKEND_URL}api/image/upload?uid=${this.user.id}&vid=${this.video_id}`,
                headerInfo: {
                    'Access-Control-Allow-Origin': this.endpoint,
                    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE',
                    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, X-File-Name, X-File-Size, X-File-Type'
                }
            }
        },
        created(){
            console.log('kljkjk', this.endpoint);
        },
        mounted() {

            // console.log(this.images, 'value');
            if (this.value)
                if (typeof this.multiple !== 'undefined' && this.multiple)
                    this.image_list = this.value;
                else
                    this.image_list = [this.value];

            if (typeof this.images !== 'undefined' && this.images) {

                var $this = this;
                this.image_list = [];

                if (typeof this.type !== 'undefined' & this.type === 'video') {

                    $this.images.forEach(function (el, i) {

                        // if ($this.type.includes(".gif")) {

                        $this.image_list.push(el.path)
                        // }
                    })
                }


                if (typeof this.type !== 'undefined' & this.type === 'image') {

                    $this.images.forEach(function (el, i) {
                        // if (!$this.type.includes(".gif")) {

                        $this.image_list.push(el.path)
                        // }
                    })
                }
                // this.image_list =  [this.images]
            }


        },
        computed: {
            showImageUpload() {
                if (typeof this.multiple !== 'undefined' && this.multiple)
                    return true;
                else if (!this.image_list)
                    return true;
                else if (this.image_list.length <= 0)
                    return true;
                return false;
            }
        },
        methods: {
            replaceUrl(image){

                var link = image;
                if(image.includes('adilo-encoding.s3.us-east-2.wasabisys.com')){
                    link = image.replace('adilo-encoding.s3.us-east-2.wasabisys.com', 'stream.adilo.com/adilo-encoding')
                }
                if(image.includes('s3.us-east-2.wasabisys.com/adilo-encoding')){
                    link = image.replace('s3.us-east-2.wasabisys.com/adilo-encoding', 'stream.adilo.com/adilo-encoding')
                }

                if(image.includes('adilo-encoding.stream.adilo.com/adilo-encoding')){
                    link = image.replace('s3.us-east-2.wasabisys.com/adilo-encoding', 'stream.adilo.com/adilo-encoding')
                }

                return link;
            },
            handleImageUploadProgress(ev, file, rawFile) {
                this.upload_in_progress = true;
                if (ev.total > 0) {
                    let percentage = parseInt(ev.loaded / ev.total * 100) || 0;
                    if (percentage === 100) {
                        this.$Progress.finish();
                    } else {
                        this.$Progress.update(percentage);
                    }
                }
            },

            handleImageUploadSuccess(res, file) {
                this.upload_in_progress = false;
                if (res.error) {
                    this.$message.error(res.error);
                } else {
                    var images = res.map(function (image) {
                        return image.url
                    });
                    this.image_list = this.image_list.concat(images);
                }
                //this.imageUrl = URL.createObjectURL(file.raw);
                // console.log(URL.createObjectURL(file.raw), "file raw url");





            },

            handleBeforeImageUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 8;
                if (!isLt2M) {
                    this.$message.error('Image picture size can not exceed 8MB!');
                    return false;
                }

                var allowedType = (this.allowedType ? this.allowedType : "image/(jpeg|jpg|png)");
                var valid = file.type.match(allowedType);
                if (!valid) {
                    this.$message.error('Image picture must be ' + allowedType + ' format!');
                    return false;
                } else {
                    this.$Progress.start();
                    return true;
                }
            },

            removeImage(src) {
                this.image_list = this.image_list.filter(function (image_list_item_src) {
                    return image_list_item_src != src;
                });


this.$emit('DefaultThumb')
            },

            updateValue(image_list) {
                if (typeof this.multiple !== 'undefined' && this.multiple) {
                    this.$emit('input', image_list);
                } else {
                    this.$emit('input', image_list[0]);
                }
            }
        },
        watch: {
            // update the value
            'image_list'(image_list) {
                this.updateValue(image_list);
            }
        }
    }
</script>
