<template>
    <div>
        <el-dialog
                title="ENABLE DYNAMIC VISUAL WATERMARKING"
                center :width=" `30%`"
                class="el-dialog--huge dialog_border_radius"
                :visible.sync="show"
                @close="$parent.showEnableVisualWatermarkingPopup=false"
                :modalAppendToBody="false"
        >
<!--                <div> You have used 14,830 sessions of your available 5,000 sessions in this billing cycle. Using this-->
<!--                    add-on costs $4/video/month and overages are billed at $2 per thousand sessions.-->
<!--                </div>-->
            <span slot="footer" class="dialog-footer">
		<el-button @click="$parent.showEnableVisualWatermarkingPopup = false" type="gray">Cancel</el-button>
		<el-button type="primary" @click="$parent.showEnableVisualWatermarkingPopup = false; $parent.video.visual_watermarking = true; ">Enable</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'EnableVisualWatermarking',
        components: {},
        props: ['showEnableVisualWatermarkingPopup'],
        data() {
            return {

                show: false,

            }
        },
        watch: {
            showEnableVisualWatermarkingPopup(newVal) {
                this.show = newVal
            },
            deep: true
        },
        methods: {
            enableConfirm: function () {



            },
        }
    }
</script>