<template>
    <div>
        <el-row>
            <el-col>
                <div class="clearfix opacity-75 opacity-hover" style="padding: 10px 30px">
                    <div class="pull-left" style="border-right: 3px solid #F3F1F2; padding: 20px 20px 20px 0;">
                        <img src="../../assets/img/project_views_icon.svg" height="15" alt="">
                    </div>
                    <div class="pull-left row-space-left-3" style="padding-top: 3px">
                        <span class="text-bold" style="font-size: 20px">{{ details.views }}</span>
                        <br>
                        <span class="text-lightest text-uppercase">
                                                        Views
                                                    </span>
                    </div>
                    <div class="pull-left row-space-left-6" style="padding-top: 3px">
                        <span class="text-bold" style="font-size: 20px">{{ details.clicks }}</span>
                        <br>
                        <span class="text-lightest text-uppercase">
                                                        Clicks
                                                    </span>
                    </div>
                    <div class="pull-left row-space-left-6" style="padding-top: 3px">
                        <span class="text-bold" style="font-size: 20px">{{ details.leads }}</span>
                        <br>
                        <span class="text-lightest text-uppercase">
                                                        Leads
                                                    </span>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <div style="padding: 10px 15px">
                    <p class="text-lightest smaller">
                        Video description
                    </p>
                    <el-input type="textarea" v-model="video.description" @keydown.native="changesMade = true" :rows="3"></el-input>
                </div>
            </el-col>
        </el-row>

        <el-row type="flex" align="middle" class="row-space-top-3">
            <el-col class="text-right">
                <el-button type="primary" @click="handleSave" size="large"
                           :loading="loading"
                           :disabled="changesMade == false"
                           class="btn btn-primary text-uppercase">Save description
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>
<style lang="less">
    .customize-collapse {
        border: none;
    }
</style>
<script>
    export default {
        props: [
            'video',
            'details'
        ],

        data () {
            return {
                loading: false,
                changesMade: false
            };
        },

        methods: {
            handleSave () {
                var $this = this;
                this.loading = true;

                this.axios({
                    url: 'editor',
                    method: 'PUT',
                    data: {
                        video: this.video
                    }
                }).then(function (response) {
                    $this.loading = false;
                    $this.changesMade = false;
                    $this.$message({
                        showClose: true,
                        message: 'Your changes have been saved.',
                        type: 'success'
                    });
                }).catch(function (response) {
                    if (response.status === 401) {
                        /* self.$http.get({
                         url: route.ajaxlogin,
                         method: 'GET',
                         dataType: 'json'}).then(function(response){
                         const loginModal = $('#loginModal');
                         loginModal.find('.modal-body').html(response.data.data);
                         loginModal.find('input[type=submit]').on('click', function(e){
                         e.stopPropagation();
                         e.preventDefault();
                         return self.ajaxLogin();
                         });
                         return loginModal.modal('show');
                         }); */
                        // Todo: user is unauthorised, show ajax login modal
                    }
                    $this.$message({
                        showClose: true,
                        message: response.message,
                        type: 'error'
                    });
                    return console.log('erResponse2', response);
                });
            }
        }
    };
</script>
