<template>
    <el-container v-bind:style="{'margin-left': asideWidth + 'px', width: containerWidth + 'px'}" class="main-container">
        <slot></slot>
    </el-container>
</template>
<style scoped lang="less">
    .main-container {
        /*background: var(--color-blue-light);*/
        background: var(--backgroundThemeColor);
        min-height: 100vh;
        max-width: 1600px !important;
        padding: calc(2%) calc(3%) calc(2%) calc(2%);
        margin: 0 auto;
    }
    @media (max-width: 960px) {
        .main-container {
            /*padding: 0 !important;*/
        }
    }
</style>
<script>
    export default {
        name: 'ThirdLevel',
        props: ['asideWidth'],
        methods: {
            containerWidth() {
                var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                return width - this.asideWidth;
            }
        }
    }
</script>
