<template>
    <div class='restricted-editing'>
        <div class="warn-sign">
            <img src="../../../static/img/warning.svg">
        </div>
        <p class="warn-message">{{ message }}</p>
    </div>
</template>

<script>

export default {
    props: ['message'],
}
</script>

<style scoped lang='less'>
    .restricted-editing {
        padding: 30px 30px;
        width: 100%;
        .warn-sign {
            position: relative;
            left: 0;
            right: 0;
            margin: auto;
        }
        .warn-message {
            text-align: left;
            line-height: 1.8;
            margin-top: 20px;
        }
    }
</style>