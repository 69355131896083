<template>
    <div>

        <el-row class="row-space-3" type="flex" align="middle" justify="center" :gutter="20">
            <el-col>
                <p class="text-lightest cropBottom">
                    Control visibility
                </p>
                <el-select
                        v-model="video.player_options.control_visibility"
                        placeholder="Select"
                        style="width: 100%;"
                        popper-class="custom_select_pop with_arrow_190"
                >
                    <el-option
                            v-for="item in controlOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col style="width: auto; padding-top: 24px; padding-right: 0;">
                <div class="colorpicker-button">
                    <el-popover
                            ref="popover1"
                            placement="bottom"
                            title="Player color"
                            width="242"
                            trigger="click">
                        <sketch-picker v-model="color"/>
                    </el-popover>

                    <i class="color-picker-button" v-popover:popover1
                       :style="{'background-color': video.player_options.color }">
                    </i>
                </div>
            </el-col>
            <el-col style="width: auto; padding-top: 20px;">
                <el-checkbox v-model="video.player_options.autoplay">Autoplay</el-checkbox>
            </el-col>
        </el-row>

        <el-row class="row-space-3 thumbnail-row text-center" type="flex" align="middle" :gutter="20" justify="left">
            <el-col :span="6">
                <div class="thumbnail-item text-white"
                     
                     :style="{'background-image' : 'url(' + croppa(video.default_thumbnail, false, 75) + ')' }">
                    <el-checkbox v-model="isDefaultThumbnail"></el-checkbox>
                </div>
                <!-- @click="changeThumbnail(video.default_thumbnail)" -->
            </el-col>
            <el-col :span="6" v-if="video.custom_thumbnail">
                <div class="thumbnail-item text-white is-active"
                     @click="changeThumbnail(video.custom_thumbnail)"
                     :style="{'background-image' : 'url(' + croppa(video.custom_thumbnail, false, 75) + ')' }">
                    <el-checkbox v-model="isCustomThumbnail"></el-checkbox>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="thumbnail-item" @click="createThumbnailFromCurrentFrame">
                    <i :class="{ 'el-icon-picture': !loadingCurrentFrameThumbnail, 'el-icon-loading': loadingCurrentFrameThumbnail }"></i>
                    Current frame
                </div>
            </el-col>
            <el-col :span="6">
                <div @click="openImageLibrary" class="thumbnail-item upload-custom">
                    <i class="el-icon-upload"></i>
                    Upload custom
                </div>
            </el-col>
        </el-row>

        <el-row class="row-space-3 advanced-options">
            <el-col>
                <el-collapse>
                    <el-collapse-item title="Advanced options" name="1">
                        <el-row :gutter="20" class="row-space-3">
                            <el-col style="width: auto;">
                                <el-checkbox v-model="video.player_options.speed_control">Show speed control
                                </el-checkbox>
                            </el-col>
                        </el-row>
                        <el-row class="row-space-3" :gutter="20">
                            <el-col :span="(showRedirectUrlInput ? 12 : 24)">
                                <p class="text-lightest cropBottom">Post-roll action</p>
                                <el-select
                                        v-model="postRollAction"
                                        placeholder="Select"
                                        style="width: 100%;"
                                        popper-class="custom_select_pop with_arrow_190"
                                >
                                    <el-option
                                            v-for="item in postRollOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="12" v-if="showRedirectUrlInput">
                                <p class="text-lightest cropBottom">
                                    Link url
                                </p>
                                <el-input placeholder="Please enter your url"
                                          class="redirect-url-input"
                                          :class="{'polluted': redirectUrlPolluted}"
                                          @keyup.native="handleRedirectKeyup"
                                          v-model="video.player_options.redirect_url"></el-input>
                            </el-col>
                        </el-row>
                        <el-row class="row-space-3">
                            <el-col>
                                <p class="text-lightest cropBottom">Pixel tracking</p>
                                <el-input type="textarea" placeholder="Paste code in here"
                                          v-model="video.player_options.pixel_tracking"></el-input>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                <el-checkbox v-model="video.player_options.playback">Playback tracker <span
                                        class="text-lightest">- start video from where user stopped earlier.</span>
                                </el-checkbox>
                            </el-col>
                        </el-row>

                    </el-collapse-item>
                </el-collapse>
            </el-col>
        </el-row>


        <el-row class="row-space-3 security-options">
            <el-col>
                <el-collapse>
                    <el-collapse-item title="Security" name="1">
                        <el-row :gutter="20">
                            <el-col style="width: auto;">
                                <el-checkbox v-model="video.player_options.protected">Enable domain protection</el-checkbox>
                            </el-col>
                        </el-row>
                        <el-row class="row-space-3" :gutter="20">
                            <el-col :span="24" v-show="video.player_options.protected">
                                <div style="padding-left: 25px;" class="row-space-top-1 row-space-3">
                                    <p class="text-lightest cropBottom">
                                        Whitelisted urls
                                    </p>
                                    <ul>
                                        <li v-for="(url, key) in video.player_options.whitelisted_domains" :key="key">
                                            {{ url }} <i class="el-icon-delete delete-whitelist"
                                                         @click="handleRemoveWhitelist(url)" style="color: #ff4949"></i>
                                        </li>
                                    </ul>
                                    <el-input placeholder="Domain" @keyup.enter.native="handleWhitelistSubmit"
                                              v-model="whitelistUrlInput">
                                        <el-button slot="append" @click.native="handleWhitelistSubmit"
                                                   icon="plus"></el-button>
                                    </el-input>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row :gutter="20">
                            <el-col style="width: auto;">
                                <el-checkbox v-model="passwordProtection">Enable password protection</el-checkbox>
                            </el-col>
                        </el-row>
                        <el-row class="row-space-3" :gutter="20">
                            <el-col :span="24" v-show="passwordProtection">
                                <div style="padding-left: 25px;" class="row-space-top-1">
                                    <p class="text-lightest cropBottom">
                                        Password
                                    </p>
                                    <el-input placeholder="Password"
                                              v-model="video.player_options.password">
                                    </el-input>
                                </div>
                            </el-col>
                        </el-row>

                    </el-collapse-item>
                </el-collapse>
            </el-col>
        </el-row>

    </div>
</template>
<style lang="less">
    .redirect-url-input {
        &.polluted input {
            border-color: red !important;
        }
    }

    .advanced-options, .security-options {
        .el-collapse {
            border: none;
            .el-collapse-item__header {
                padding: 0 !important;
            }
            .el-collapse-item__wrap {
                border: none !important;
            }
        }
    }

    .delete-whitelist {
        cursor: pointer;
        opacity: 0.3;
        &:hover {
            opacity: 1;
        }
    }

    .thumbnail-row {
        > .el-col {
            padding: 0 20px;
            .thumbnail-item {
                background-color: #fff;
                background-repeat: no-repeat;
                background-size: cover;
                -webkit-background-size: cover;
                height: 75px;
                border-radius: 8px;
                -webkit-border-radius: 8px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                color: #bcc7ce;
                &.text-white {
                    color: #fff;
                    position: relative;
                    .el-checkbox {
                        position: relative;
                        height: 100%;
                    }
                    .el-checkbox__input {
                        opacity: 0 !important;
                        position: absolute !important;
                        bottom: 0 !important;
                        left: 6px !important;
                        &.is-checked {
                            opacity: 1 !important;
                        }
                    }
                    -webkit-box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.3);
                    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.3);
                    &:hover {
                        -webkit-box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
                        box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
                    }
                }
                i {
                    color: #309CE3;
                    font-size: 24px;
                    opacity: 0.5;
                    &.el-icon-check {
                        opacity: 0;
                    }
                }
                &:hover {
                    i {
                        opacity: 0.75;
                    }
                    .el-icon-check {
                        opacity: 0.3;
                    }
                }
                &.is-active {
                    &:hover {
                        .el-icon-check {
                            opacity: 1;
                        }
                    }
                    i.el-icon-check {
                        opacity: 1;
                    }
                }
            }
        }
    }
</style>
<script>
    import Sketch from 'vue-color/src/components/Sketch.vue';

    export default {
        props: ['video', 'user'],
        data () {
            return {
                redirectUrlPolluted: false,
                passwordProtection: (this.video.player_options.password.length > 0),
                controlOptions: [{
                    value: 'on_hover',
                    label: 'Display on hover'
                }, {
                    value: 'always',
                    label: 'Display always'
                }, {
                    value: 'hide',
                    label: 'Hide'
                }, {
                    value: 'hide_progress',
                    label: 'Hide progress bar'
                }],
                postRollAction: this.video.player_options.post_roll_action,
                postRollOptions: [{
                    value: '',
                    label: 'Do nothing'
                }, {
                    value: 'redirect_to_link',
                    label: 'Redirect to link'
                }],
                showRedirectUrlInput: this.video.player_options.post_roll_action === 'redirect_to_link',
                color: this.video.player_options.color,
                loadingCurrentFrameThumbnail: false,
                isDefaultThumbnail: this.video.thumbnail === this.video.default_thumbnail,
                isCustomThumbnail: this.video.thumbnail === this.video.custom_thumbnail,
                whitelistUrlInput: ''
            }
        },

        mounted () {
            if (this.video.player_options.redirect_url) {
                this.showRedirectUrlInput = true;
                this.video.player_options.post_roll_action = 'redirect_to_link';
            }
        },

        methods: {
            openImageLibrary () {
                this.$emit('open-image-library', {
                    type: 'player_options',
                    model: {
                        url: 'thumbnail'
                    },
                    value: this.video
                })
            },

            changeThumbnail (thumbnailUrl) {
                this.video.thumbnail = thumbnailUrl;
                this.$parent.$parent.$parent.$parent.$parent.$parent.player.poster(thumbnailUrl);
                if (this.video.thumbnail === this.video.custom_thumbnail) {
                    this.isDefaultThumbnail = false;
                    this.isCustomThumbnail = true;
                } else {
                    this.isDefaultThumbnail = true;
                    this.isCustomThumbnail = false;
                }
            },

            createThumbnailFromCurrentFrame () {
                var $this = this;
                this.loadingCurrentFrameThumbnail = true;
                this.axios({
                    url: '/editor/video/thumbnail',
                    method: 'POST',
                    data: {
                        video_id: this.video.id,
                        time: this.$parent.$parent.$parent.$parent.$parent.$parent.currentTime
                    }
                }).then(function (response) {
                    $this.video.custom_thumbnail = response.data.url;
                    $this.video.thumbnail = response.data.url;
                    $this.loadingCurrentFrameThumbnail = false;
                }).catch(function (response) {
                    $this.loadingCurrentFrameThumbnail = false;
                    $this.$message({
                        'showClose': true,
                        'message': response.message,
                        'type': 'error'
                    });
                });
            },

            handleWhitelistSubmit () {
                if (!this.video.player_options.whitelisted_domains) {
                    this.video.player_options.whitelisted_domains = [];
                }
                // console.log((/^http:\/\/\w+(\.\w+)*(:[0-9]+)?\/?$/.test(this.whitelistUrlInput)));
                if ((/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(this.whitelistUrlInput)) || (/\w+(\.\w+)*(:[0-9]+)?\/?$/.test(this.whitelistUrlInput))) {
                    this.video.player_options.whitelisted_domains.push(this.whitelistUrlInput);
                    this.$message.success('The domain ' + this.whitelistUrlInput + ' was added to the whitelist');
                    this.whitelistUrlInput = '';
                } else {
                    this.$message.error('Please enter a valid domain, e.g. google.com');
                }
            },

            handleRemoveWhitelist (item) {
                let activeItems = this.video.player_options.whitelisted_domains.slice(0);
                let index = activeItems.indexOf(item);

                if (index > -1) {
                    activeItems.splice(index, 1);
                }
                this.video.player_options.whitelisted_domains = activeItems;
            },

            handleRedirectKeyup (value) {
                if (this.validateURL(value.target.value)) {
                    this.redirectUrlPolluted = false;
                } else {
                    this.redirectUrlPolluted = true;
                }
            },

            validateURL (textval) {
                var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
                return urlregex.test(textval);
            }
        },

        watch: {
            'color' (value) {
                this.video.player_options.color = 'rgba(' + value.rgba.r + ',' + value.rgba.g + ',' + value.rgba.b + ',' + value.rgba.a + ')';
            },
            'postRollAction' (value) {
                if (value === 'redirect_to_link') {
                    this.showRedirectUrlInput = true;
                } else {
                    this.showRedirectUrlInput = false;
                    this.video.player_options.redirect_url = '';
                }
                this.video.player_options.post_roll_action = value;
            },
            'passwordProtection' (value) {
                if (value === false) {
                    this.video.player_options.password = '';
                }
            }
        },

        computed: {
            'customThumbnailActive' () {
                return this.video.thumbnail !== this.video.default_thumbnail;
            }
        },

        components: {
            'sketch-picker': Sketch
        }
    }
</script>
