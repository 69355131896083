<template>
    <div>
        <el-row class="row-space-3" type="flex" style="flex-wrap: wrap;">
            <el-col style="min-width: 360px;">
                <el-row>
                    <el-col>
                        <p class="text-lightest row-space-1">Display link as</p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-radio class="radio" v-model="choice" label="thumbnail">Thumbnail</el-radio>
                    </el-col>
                </el-row>

                <el-row v-show="choice == 'thumbnail'" class="row-inner-3">
                    <el-col class="row-space-3">
                        <el-checkbox v-model="responsiveness">Responsiveness
                            <span style="word-break: break-all;" class="text-lightest">- (The video will resize to fill it's parent div)</span>
                        </el-checkbox>
                    </el-col>

                    <el-col>
                        <p class="text-lightest row-space-1">Image size</p>
                        <el-row type="flex" :gutter="30">
                            <el-col style="width: auto;">
                                <el-input-number v-model="width" style="width: 135px;" :step="30"></el-input-number>
                            </el-col>
                            <el-col style="width: auto;">
                                <el-input-number v-model="height" style="width: 135px;" :step="30"></el-input-number>
                            </el-col>
                        </el-row>
                    </el-col>

                    <el-col class="row-space-top-3">
                        <el-row class="row-space-3" type="flex" align="middle"
                                :style="{'background-image': (choice == 'thumbnail' ? 'url(' + video.thumbnail + ')' : ''), 'width': width + 'px', 'height': height + 'px'}"
                                id="popover-video-preview">
                            <el-col class="text-center play-button"></el-col>
                        </el-row>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-radio class="radio" v-model="choice" label="link">Text link</el-radio>
                    </el-col>
                </el-row>
                <el-row v-show="choice == 'link'" class="row-inner-3">
                    <el-col>
                        <p class="text-lightest row-space-1">Link text</p>
                        <el-input v-model="text"></el-input>
                    </el-col>
                </el-row>

            </el-col>
        </el-row>


        <el-row class="row-space-3">
            <el-col :span="24">
                <p class="text-lightest row-space-1">
                    Embed code
                </p>
                <textarea spellcheck="false" id="share-embed-code-textarea" cols="30" rows="3"
                          onclick="this.select()"
                          contenteditable="false" v-text="html" class="form-control"></textarea>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-button data-type="copy-embed-code-to-clipboard"
                           type="primary"
                           v-clipboard:copy="html"
                           @click="copyText = 'Copied!'"
                           v-text="copyText">
                    Copy
                </el-button>
            </el-col>
        </el-row>

    </div>
</template>
<style>
    .el-checkbox__label {
        white-space: normal !important;
        word-break: break-all !important;
    }
</style>
<style lang="less" scoped>
    .play-button {
        &:before {
            font-family: VideoJS;
            font-weight: normal;
            font-style: normal;
            content: "\F101";
            color: #fff;
            font-size: 8em;
            line-height: 0;
            vertical-align: middle;
        }
    }

    #popover-video-preview {
        height: 300px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        -webkit-background-size: cover;
    }
</style>
<script>
    export default {
        props: ['video'],
        data () {
            return {
                width: 360,
                height: 200,
                responsiveness: false,
                text: 'Click here to watch the video!',
                choice: 'thumbnail',
                copyText: 'Copy'
            }
        },
        components: {},
        computed: {
            'popover' () {
                return process.env.VUE_APP_BACKEND_URL + "js/popover.js";
            },
            'html' () {
                var string = '&lt;script type="text/javascript" src="' + this.popover +'" async=""&gt;&lt;/script&gt;<div class="motion_popover" data-options="width=' + (this.responsiveness ? '100%' : this.width) + ' height=' + this.height + ' video=' + this.video.video_id + '" data-id="' + this.video.video_id + '"></div>';
                string = string.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
                return string;
            }
        }
    }
</script>
