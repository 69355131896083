<template>
    <div>
        <label for="" class="row-space-2 themeColor">Type</label> <br>
        <el-radio v-model="video.player_options.thumbnail_type" label="image" class="themeColor">Image</el-radio>
        <br>
        <el-radio v-model="video.player_options.thumbnail_type" label="video" class="themeColor">Gif</el-radio>

        <div v-if="video.player_options.thumbnail_type == 'image'">


<!--            <div class="row-space-top-5">-->
<!--                <label for="">Random</label>-->
<!--            </div>-->
<!--            <div class="thumbnails-wrap">-->
<!--                &lt;!&ndash; This condition below was part of random images, which was restricting all random images &ndash;&gt;-->
<!--                &lt;!&ndash; v-if="image.includes('360') || image.includes('medium')" &ndash;&gt;-->
<!--                <div v-for="image in video.thumbnails" class="thumbnail-item" @click="changeThumbnail(image)">-->
<!--                    <div :style="{'background-image':'url('+croppa(image, 400)+')'}" class="thumbnail-image">&nbsp;-->
<!--                    </div>-->
<!--                    &lt;!&ndash;                        <div :style="{'background-image':'url('+image+')'}" class="thumbnail-image" v-else>&nbsp;</div>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="row-space-top-5">-->
<!--                <label for="">Custom</label>-->
<!--            </div>-->
            <div class="row-space-5">
                <image-input @DefaultThumb="DefaultThumb" v-if="mounted" @click="changeThumbnail" :multiple="false" v-model="customThumbnail"
                             :user="user" :video_id='video.video_id'
                             :images="video.player_options.thumbnail_type === 'image' ? this.video.video_custom_thumbnail : []"
                             :type="video.player_options.thumbnail_type"></image-input>
            </div>
        </div>

        <div v-if="video.player_options.thumbnail_type == 'video'" class="relative">
            <div>
<!--                <div class="row-space-top-4">-->
<!--                    <label for="">Random</label>-->
<!--                </div>-->

<!--                <div v-if="video.player_options.thumbnail_video_url"-->
<!--                     class="thumbnails-wrap">-->
<!--                    <div class="thumbnail-item" @click="changeThumbnail(video.player_options.thumbnail_video_url)">-->
<!--                        <div :style="{'background-image':'url('+ video.player_options.thumbnail_video_url+')', height: '150px'}"-->
<!--                             class="thumbnail-image">&nbsp;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="row-space-top-5">
<!--                    <label for="">Custom</label>-->
                    <div class="row-space-5">
                        <image-input @DefaultThumb="DefaultThumb" :type="video.player_options.thumbnail_type" :multiple="false"
                                     :images="video.player_options.thumbnail_type === 'video' ? this.video.video_custom_thumbnail : []"
                                     v-if="mounted"
                                     @click="changeGifThumbnail" v-model="customGifThumbnail" allowedType="image/gif"
                                     :user="user" :video_id='video.video_id' title="Add Gif"></image-input>
                        <image-input @DefaultThumb="DefaultThumb" :type="video.player_options.thumbnail_type" :multiple="false"
                                     :images="video.player_options.thumbnail_type === 'video' ? this.video.video_custom_thumbnail : []"
                                     v-else allowedType="image/gif" :user="user" :video_id='video.video_id'
                                     title="Add Gif"></image-input>
                    </div>
                </div>

<!--                <div v-if="this.subscriptionData && this.subscriptionData.plan.name === 'Free Forever'"-->
<!--                     class="upload-restriction-dialog">-->
<!--                    <div class="content_restriction">-->
<!--                        <div class="warn-sign"><img src="./../../../static/img/exclamation.svg" alt=""></div>-->
<!--                        <div>-->
<!--                            <p>-->
<!--                                Your current plan does not include Video Thumbnails.-->
<!--                            </p>-->
<!--                            <p>-->
<!--                                Start using engaging video thumbnails and access more features when you upgrade today.-->
<!--                            </p>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <el-button round class="restriction_dialog_btn" type="success" @click="showUpgradeOptions">-->
<!--                                See Upgrade Options-->
<!--                            </el-button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <!--
		 <button class="row-space-top-1 thumbnail-item btn btn-info rounded-lg row-space-inner-left-3 row-space-inner-right-3" @click="createThumbnailFromCurrentFrame('gif')" :disabled="loadingCurrentFrameThumbnail">
		 <i :class="{ 'el-icon-picture': !loadingCurrentFrameThumbnail, 'el-icon-loading': loadingCurrentFrameThumbnail }"></i>&nbsp;
		 <span v-if="video.player_options.thumbnail_video_url">Update</span>
		 <span v-else>Current frame</span>
		 </button>
	    -->
        </div>
    </div>
</template>
<style lang="less">
    .thumbnails-wrap {
        display: flex;
        margin: 0 -10px;
        flex-wrap: wrap;

        .thumbnail-item {
            width: 50%;
            flex-grow: 1;
            padding: 10px;
        }

        .thumbnail-item .thumbnail-image {
            height: 90px;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            border-radius: 3px;
            -webkit-border-radius: 3px;
            cursor: pointer;
        }
    }

    .upload-custom {
        background: #F7FDFF;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        color: #00ACDC;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        font-size: 14px;
        cursor: pointer;

        svg {
            margin-right: 10px;
        }
    }
</style>
<script>
    import ImageInput from '../UI/ImageInput.vue';

    export default {
        props: ['video', 'user', 'currentTime'],
        name: "editor-thumbnail-options",
        data() {
            return {
                loadingCurrentFrameThumbnail: false,
                customThumbnail: '',
                customGifThumbnail: '',
                mounted: false
            }
        },

        components: {
            ImageInput
        },
        created() {
            // console.log(this.video.video_custom_thumbnail, 'this.video.video_custom_thumbnail');
        },
        mounted() {
            // if this is a custom thumbnail
            // if (this.customThumbnailActive)
            //     this.customThumbnail = this.video.thumbnail;
            if (this.video.player_options.thumbnail_type == 'video'
                && this.video.thumbnail != this.video.player_options.thumbnail_video_url
                && this.video.thumbnail.match("\.gif$")) {
                this.customGifThumbnail = this.replaceUrl(this.video.thumbnail);
            }
            this.initThumbnail();
            this.mounted = true;
            this.$store.dispatch('getSubscriptionData');
        },

        watch: {
            'video.player_options.thumbnail_type'() {
                this.initThumbnail();
            }
        },

        methods: {
            initThumbnail() {
            },
            DefaultThumb(){
var $this = this;
                this.axios({
                    url: '/loadDefaultThumb',
                    method: 'post',
                    data: {
                        video_id: this.video.video_id
                    }
                }).then(function (response) {

                    $this.video.thumbnail = response.data.thumbnail;


                }).catch(function (response) {



                });
            },
            openImageLibrary() {
                this.$emit('open-image-library', {
                    type: 'player_options',
                    model: {
                        url: 'thumbnail'
                    },
                    value: this.video
                })
            },

            changeThumbnail(thumbnailUrl) {
                //  this.video.thumbnail = this.croppa(thumbnailUrl, 800, 450);
                this.video.thumbnail = this.replaceUrl(thumbnailUrl);
                console.log(this.video.thumbnail, '===================');
            },
            replaceUrl(image){

                var link = image;
                if(typeof image !== 'undefined'){

                    if(image.includes('adilo-encoding.s3.us-east-2.wasabisys.com')){
                        link = image.replace('adilo-encoding.s3.us-east-2.wasabisys.com', 'stream.adilo.com/adilo-encoding')
                    }
                    if(image.includes('s3.us-east-2.wasabisys.com/adilo-encoding')){
                        link = image.replace('s3.us-east-2.wasabisys.com/adilo-encoding', 'stream.adilo.com/adilo-encoding')
                    }

                    if(image.includes('adilo-encoding.stream.adilo.com/adilo-encoding')){
                        link = image.replace('s3.us-east-2.wasabisys.com/adilo-encoding', 'stream.adilo.com/adilo-encoding')
                    }

                }
                return link;
            },
            changeGifThumbnail(thumbnailUrl) {
                //  this.video.thumbnail = this.croppa(thumbnailUrl, 800, 450);
                this.video.thumbnail = this.replaceUrl(thumbnailUrl);
            },

            createThumbnailFromCurrentFrame(type) {
                if (type === 'jpg') return
                var $this = this;
                this.loadingCurrentFrameThumbnail = true;
                this.axios({
                    url: process.env.VUE_APP_BACKEND_URL + '/upload_video/gif_images',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: JSON.stringify({
                        video_id: this.video.video_id,
                        project_id: this.video.project.id,
                        user_id: parseInt(this.video.owner),
                        offset_time: Math.round(this.currentTime),
                        video_path: this.video.path,
                        type: type
                    })
                }).then(function (response) {
                    console.log("image requested", response);
                    $this.waitForThumbnailFile(response.data, type);
                }).catch(function (response) {
                    $this.loadingCurrentFrameThumbnail = false;
                    $this.$message({
                        'showClose': true,
                        'message': response.message,
                        'type': 'error'
                    });
                });
            },

            waitForThumbnailFile(url, type) {
                console.log("wait for file", url);
                var $this = this;

                this.axios({
                    url: url
                }).then(function () {
                    console.log("done loading", type);
                    if (type == 'jpg')
                        $this.video.player_options.thumbnail_image_url = url;
                    else if (type == 'gif')
                        $this.video.player_options.thumbnail_video_url = url;

                    $this.video.thumbnail = url;
                    $this.loadingCurrentFrameThumbnail = false;
                }).catch(function (response) {
                    console.log("error", response);
                    setTimeout(function () {
                        $this.waitForThumbnailFile(url, type);
                    }, 1000);
                });
            },

            handleRedirectKeyup(value) {
                if (this.validateURL(value.target.value))
                    this.redirectUrlPolluted = false;
                else
                    this.redirectUrlPolluted = true;
            },

            validateURL(textval) {
                var urlregex =
                    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
                return urlregex.test(textval);
            },
            showUpgradeOptions() {
                this.$router.push({name: 'ChangeSubscriptionPlan', params: {'section': 'billing'}});
            }
        },

        watch: {
            'customThumbnail'(value) {
                if (this.mounted)
                    this.video.thumbnail = value;


            },
            'video.thumbnail'(value) {
                var $this = this;
                console.log(this.video.thumbnail, 'video.thumbnail');

                if (typeof this.video.thumbnail === 'undefined' || this.video.thumbnail === '') {
                    this.video.thumbnail = this.replaceUrl(this.video.thumbnail)
                }

                // if (this.video.player_options.thumbnail_type === 'video'){
                //
                //     this.video.thumbnail = this.replaceUrl(this.video.player_options.thumbnail_video_url);
                //
                // }

                console.log(this.video.thumbnail, 'video.thumbnail----------');
                var link = this.video.thumbnail;
                if(typeof link !== 'undefined' && link.includes('stream.adilo.com/adilo-encoding')){
                    link = link.replace('stream.adilo.com/adilo-encoding', 'adilo-encoding.s3.us-east-2.wasabisys.com')
                }
                this.axios({
                    url: '/checkCustomThumb',
                    method: 'post',
                    data: {
                        path: link
                    }
                }).then(function (response) {

                    if (response.data.success === true || response.data.success === 'true') {
                        $this.video.custom_thumb_id = response.data.thumbnail.id
                    } else {
                        $this.video.custom_thumb_id = null
                    }

                    // console.log(response);
                }).catch(function (response) {
                    $this.video.custom_thumb_id = null
                });
            },

            'customGifThumbnail'(value) {
                if (this.mounted)
                    this.video.thumbnail = this.replaceUrl(value);
            }
        },

        computed: {
            subscriptionData() {
                return this.$store.state.subscription;
            },
            'customThumbnailActive'() {
                return (!this.video.thumbnail.includes('wasabi')
                    && !this.video.thumbnail.includes('b-cdn')
                    && this.video.thumbnail != this.video.player_options.thumbnail_image_url
                    && this.video.thumbnail != this.video.player_options.thumbnail_video_url
                    && this.video.thumbnail.match("\.(jpg|jpeg|png)$"));
            }
        }
    }
</script>
<style scoped lang="less">

    .blurred {
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }

    .relative {
        position: relative;
    }

    .upload-restriction-dialog {
        font-size: 14px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: absolute;
        top: 0;

        p {
            color: #21455E;
        }

        .warn-sign {
            position: relative;
            width: 60px;
            height: 60px;
            text-align: center;
            margin-bottom: 15px;

            img {
                max-width: 100%;
                object-fit: cover;
            }
        }

        .content_restriction {
            max-width: 575px;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        .restriction_dialog_btn {
            border-radius: 19px;
            padding: 8px 25px;
            background-color: #F27500;
            border-color: #F27500;
            color: white;
        }
    }
</style>