<template>
    <div>

        <el-dialog title="Share & Embed" class="el-dialog--huge share-dialog" :visible.sync="dialogVisible" :modalAppendToBody="false">

            <div class="el-dialog__body">

                        <el-menu mode="vertical" default-active="1" class="el-menu-vertical-demo">

                            <el-menu-item index="1" @click="currentTab = 'inline'">
                                <img src="../../assets/img/share_inline_icon.svg"><br>
                                Inline embed
                            </el-menu-item>
                            <el-menu-item index="2" @click="currentTab = 'email'">
                                <img src="../../assets/img/share_email_icon.svg"><br>
                                Link in email
                            </el-menu-item>
                            <el-menu-item index="3" @click="currentTab = 'popover'">
                                <img src="../../assets/img/share_popover_icon.svg"><br>
                                Pop-over embed
                            </el-menu-item>
                            <el-menu-item index="4" @click="currentTab = 'floating'">
                                <img src="../../assets/img/share_play_icon.svg"><br>
                                Floating embed
                            </el-menu-item>
                            <el-menu-item index="5" @click="currentTab = 'social'">
                                <img src="../../assets/img/share_link_icon.svg"><br>
                                Social sharing
                            </el-menu-item>

                        </el-menu>

                    <!-- main content -->
                    <div class="main-content">

                        <div class="clearfix">
                            <!-- inline -->
                            <div v-show="currentTab == 'inline'" id="inline-embed">
                                <editor-share-inline :video="video" :videoListPath="videoListPath"></editor-share-inline>
                            </div>
                            <!-- email -->
                            <div v-show="currentTab == 'email'" id="link-in-email">
                                <editor-share-email :video="video"></editor-share-email>
                            </div>
                            <!-- popover -->
                            <div v-show="currentTab == 'popover'" id="pop-over-embed">
                                <editor-share-popover :video="video"></editor-share-popover>
                            </div>
                            <!-- floating -->
                            <div v-show="currentTab == 'floating'" id="floating-embed">
                                <editor-share-floating :video="video"></editor-share-floating>
                            </div>
                            <!-- social -->
                            <div v-show="currentTab == 'social'" id="social-sharing">
                                <editor-share-social :video="video"></editor-share-social>
                            </div>

                        </div>

                    </div>
            </div>

        </el-dialog>

    </div>
</template>
<style lang="less">
 .share-dialog {
     .el-dialog {
         min-width: 700px;
         min-height: 640px;
         overflow: hidden;
     }
     .el-dialog__body {
         padding: 0 !important;
         .el-menu {
             min-width: 150px;
             position: absolute;
             height: 100%;
             padding-bottom: 80px;
             overflow-y: auto;
             .el-menu-item {
                 text-align: center;
                 word-wrap: break-word;
                 display: block;
                 height: auto;
                 width: auto;
                 line-height: auto;
                 img {
                     margin: 0;
                 }
             }
         }
         .main-content {
             margin-left: 150px;
             padding: 30px;
             & > .clearfix {
                 padding: 0 30px
             }
         }
     }
 }
</style>
<script>
    import EditorShareInline from './Share/EditorShareInline.vue';
    import EditorShareEmail from './Share/EditorShareEmail.vue';
    import EditorSharePopover from './Share/EditorSharePopover.vue';
    import EditorShareFloating from './Share/EditorShareFloating.vue';
    import EditorShareSocial from './Share/EditorShareSocial.vue';

    export default{
        name: 'EditorShare',
        props: ['video', 'videoListPath'],
        components: {
            EditorShareInline, EditorShareEmail, EditorSharePopover, EditorShareFloating, EditorShareSocial
        },
        data () {
            return {
                dialogVisible: false,
                currentTab: 'inline'
            }
        },
        methods: {
            handleClose () {
                this.dialogVisible = false
            }
        }
    }
</script>
