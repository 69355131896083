<template>
    <el-card v-if='data' class="box-card view-summary m-top-4">
        <div slot="header" class="d-flex justify-content-between align-items-center px-15">
            <span class="header-summary">Experiment Summary</span>
            <span class="header-sm-summary">Thumbnail {{ data.type == 'one' ? 'A' : 'B' }}</span>
        </div>
        <summary-video-section class="summary-section d-flex flex-wrap">
            <!-- Impressions -->
            <template v-slot:first-head>
                <el-popover ref="impressionsPop" placement="top" trigger="hover" popper-class="custom_popover pop_top_240x62"
                            content="This is the total number of people who visited your videos."></el-popover>
                <span class="head-text">IMPRESSIONS</span>
                <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:impressionsPop v-if="help_hover" alt @mouseout="help_hover = false"/>
                <img src="../../../assets/img/analytics/noun-info.svg" v-popover:impressionsPop v-else alt @mouseover="help_hover = true"/>
            </template>
            <template v-slot:first-body>
                <div  class="m-top-4 w20" style="width:50%;float:left"><div class="sum-number">{{ data.impressions }}</div></div>
            </template>

            <!-- Views -->
            <template v-slot:second-head>
                <el-popover ref="viewsPop" placement="top" trigger="hover" popper-class="custom_popover pop_top_330x62"
                            content="This is the total number of people who clicked the play button and watch your videos."></el-popover>
                <span class="head-text">VIEWS</span>
                <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:viewsPop v-if="help_hover1" alt @mouseout="help_hover1 = false"/>
                <img src="../../../assets/img/analytics/noun-info.svg" v-popover:viewsPop v-else alt @mouseover="help_hover1 = true"/>
            </template>
            <template v-slot:second-body>
                <div  class="m-top-4 w20" style="width:50%;float:left"><div class="sum-number">{{ data.views }}</div></div>
            </template>

            <!-- Watch Time -->
            <template v-slot:third-head>
                <el-popover ref="watchPop" placement="top" trigger="hover" popper-class="custom_popover pop_top_220x62"
                            content="This is the total number of watch time."></el-popover>
                <span class="head-text">WATCH TIME</span> <span class="head-text__minutes">(Minutes)</span>
                <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:watchPop v-if="help_hover2" alt @mouseout="help_hover2 = false"/>
                <img src="../../../assets/img/analytics/noun-info.svg" v-popover:watchPop v-else alt @mouseover="help_hover2 = true"/>
            </template>
            <template v-slot:third-body>
                <el-container>
                    <div style="width:100%">
                        <div class="m-top-4 w20 d-flex justify-content-between">
                            <div>
                                <div class="sum-number">{{ parseInt(data.watch_time) }}</div>
                                <div class="m-top-2 size-14">Total</div>
                            </div>
                            <div>
                                <div class="sum-number">{{ getDurF(data.watch_average) }}</div>
                                <div class="m-top-2 size-14" style="word-break:break-word;">Average Per Viewer</div>
                            </div>    
                        </div>
                    </div>
                </el-container>
            </template>

            <!-- Interactions -->
            <template v-slot:fourth-head>
                <el-popover ref="devicePopover" placement="top" trigger="hover" popper-class="custom_popover pop_top_220x62"
                            content="This is the total number of watched devices."></el-popover>
                <span class="head-text">INTERACTIONS</span> 
                <img src="../../../assets/img/analytics/noun-info-hover.svg" v-popover:devicePopover v-if="help_hover3" alt @mouseout="help_hover3 = false"/>
                <img src="../../../assets/img/analytics/noun-info.svg" v-popover:devicePopover v-else alt @mouseover="help_hover3 = true"/>
            </template>
            <template v-slot:fourth-body>
                <el-container> 
                    <div style="width:100%">
                        <div class="m-top-4 w20 d-flex justify-content-between">
                            <div>
                                <div class="sum-number">{{ data.clicks }}</div>
                                <div class="m-top-2 size-14">Clickthrough</div>
                            </div>
                            <div>   
                                <div class="sum-number">{{ data.email_capture}}</div>
                                <div class="m-top-2 size-14" style="word-break:break-word;">Email Capture</div>
                            </div>
                        </div>
                    </div>
                </el-container>
            </template>
        </summary-video-section>
    </el-card>
</template>

<style lang="less" scoped>
    .view-summary{
        border-color: #fff;
        box-shadow: 0 2px 6px rgba(255, 255, 255, .04);
        border-radius: 12px;
        margin: 30px 0 !important;
    }
    .m-top-4{
        margin-top: 20px !important
    }
    .px-15{
        padding-left: 15px;
        padding-right: 15px;
    }
    .header-summary {
        font-weight: 500;
        font-size: 20px;
        color: var(--color-content);
        @media (max-width: 414px){
            margin-left: 0
        }
    }
    .header-sm-summary{
        font-weight: normal;
        font-size: 14px;
        color: var(--color-sm-header);
    }

    .summary-section {
        margin: 0px 20px 0px 20px;
        color: var(--color-content);
    }

    .head-text {
        letter-spacing: 0.1rem;
        font-size: 15px;

        &__minutes {
            color: #9e9e9e;
            margin-left: 5px;
        }
    }

    .sum-number {
        font-size: 24px;
        margin-bottom: 5px;
    }

    .summary-section.d-flex.flex-wrap {
        margin-left: -5px !important;
    }

    .el-popover__reference {
        margin-left: 20px;
        margin-bottom: 4px;
    }
</style>

<script>
import VideoSummaryBody from "./VideoSummaryBody";
import SummarySection from "./SummarySection";
import SummaryVideoSection from "./SummaryVideoSection";

export default {
    name: 'VideoThumbnailSummary',
    components: {SummarySection, VideoSummaryBody,SummaryVideoSection},
    props: ['data'],
    data: () => ({
        help_hover: false,
        help_hover1: false,
        help_hover2: false,
        help_hover3: false,
        thumbnail_summaries: {
            a: null,
            b: null,
        }
    }),
    methods: {
        getDurF(s) {
            let d = new Date(s * 60 * 1000);
            let secs = d.getUTCSeconds();
            let minutes = d.getUTCMinutes();

            if (d.getUTCHours() > 0) {
                return (
                    d.getUTCHours() +
                    ":" +
                    (minutes >= 10 ? "" : "0") +
                    minutes +
                    ":" +
                    (secs >= 10 ? "" : "0") +
                    secs
                );
            } else {
                return (
                    (minutes >= 10 ? "" : "0") +
                    minutes +
                    ":" +
                    (secs >= 10 ? "" : "0") +
                    secs
                );
            }
        }
    },
}
</script>