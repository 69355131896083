<template>
    <div class="m-top-4 new-domain-form">
        <div class="inline-flex new-domain-input">
            <!-- <el-input
                    @blur="handleInputConfirm"
                    @keyup.enter.native="handleInputConfirm"
                    class="custom-el-input"
                    placeholder="Add domain"
                    ref="saveDomainInput"
                    size="medium"
                    v-model="edit_domain_input"
                    :class="{'has-error': (error)}"
            ></el-input>
            <el-button @click="handleInputConfirm" class="button-new-domain" size="medium">+</el-button> -->
            <input  
                @blur="handleInputConfirm"
                @change="handleInputConfirm"
                class="custom-el-input input-text form-control"
                placeholder="Enter domain"
                ref="saveDomainInput"
                size="medium"
                :class="{'has-error': (error)}"
                v-model="edit_domain_input"
            >
            <AddIcon @click="handleInputConfirm" class='add-icon'></AddIcon>
        </div>
        <label class="text-danger small" v-if="error">
            <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
            Enter a valid domain name
        </label>
        <div class="w-100 m-top-3 new-domain-input">
            <el-tag
                    :disable-transitions="false"
                    :key="`editor_domain_${key}`"
                    @close="handleClose(domain)"
                    closable
                    v-for="(domain, key) in domains"
            >{{ domain }}</el-tag>
        </div>
    </div>
</template>
<style lang="less" scoped>
    .input-text {
        height: 40px;
        font-family: Arial, Helvetica, sans-serif;
        border: 0.5px solid rgba(131, 146, 165, 0.589);
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .el-tag {
        font-weight: 100;
    }
</style>
<script>
    import AddIcon from '../AddIcon';
    export default {
        name: "EditorDomains",
        props: {
            propDomain: {
                type: [Array, String],
                required: true,
                default: []
            },
        },
        components: {
            AddIcon
        },
        data: () => ({
            edit_domain_input: "",
            domains: [],
            error: false
        }),
        mounted() {
            if (!this.propDomain) {
                this.domains = [];
            }else{
                this.domains = this.propDomain;
            }
        },
        methods: {
            updateContactInfo() {
                window.vEvent.fire("update-domain-data", this.domains);
            },

            /**
             * Domains Input
             */
            handleInputConfirm() {
                var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
                var regex = new RegExp(expression);

                if (this.edit_domain_input && this.edit_domain_input.match(regex)) {
                    this.domains.push(this.edit_domain_input);
                    this.edit_domain_input = "";
                    this.error = false;

                    this.updateContactInfo();
                }else {
                    this.error = this.edit_domain_input == "" ? false : true;
                }
            },

            /**
             * Domains remove
             */
            handleClose(domain) {
                this.domains.splice(this.domains.indexOf(domain), 1);

                this.updateContactInfo();
            }
        }
    };
</script>
