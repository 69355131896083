<template>
    <div class="pixel_retargeting">
        <div class="upload-restriction-dialog-2" v-if='restricted'>
            <div class="warn-sign"><img src="./../../../static/img/exclamation.svg" alt=""></div>
            <p>Your current plan does not include Pixel retargeting.</p>
            <el-button round class="restriction_dialog_btn" type="success" @click="$emit('openUpgrade')"> See Upgrade
                Options
            </el-button>
        </div>
        <div :class='restricted ? "blurred" : ""'>
            <b>Pixel Retargeting</b>
            <p class="row-space-top-2 row-space-5">
                Goal based video retargeting for building custom audiences of your viewers based the goals they complete.
            </p>
            <p class="row-space-top-2 row-space-5">
                Example: watched 50% of video.
            </p>

            <div id="listPixelRetargeting" class="apply" v-if="this.video.video_basecodes !== null">
                <!--            <div id="videoBaseCodeListing" class="stored">-->
                <div id="videoBaseCodeListing" class="">
                    <div class="baseHeader">
                        <b>Base code</b>
                    </div>
                    <div class="video_basecode_input " id="video_basecode_input" :data-id="this.video.video_basecodes.id"
                        data-type="base">
                        <div class="afterStore">
                            ({{video.video_basecodes.name}})


                            <el-dropdown

                                    trigger="click"
                                    class="dropdown-container PixelActions"
                                    menu-align="end"
                                    placement="bottom"
                            >
                                <i class="el-icon-more "></i>
                                <el-dropdown-menu slot="dropdown"
                                                class="video-settings-dropdown custom_dropdown dropdown_bottom_200">
                                    <el-dropdown-item @click.native="editEventCode(video.video_basecodes.id, 'base')">Edit
                                    </el-dropdown-item>
                                    <el-dropdown-item @click.native="deleteEventCode(video.video_basecodes.id, 'base')">
                                        Remove
                                    </el-dropdown-item>

                                </el-dropdown-menu>

                            </el-dropdown>


                        </div>
                        <el-input
                                v-model="video.video_basecodes.code"
                                type="textarea"
                                placeholder="Enter base code(s)"
                                rows="3"
                                cols="50"
                                class="resize_none"
                        ></el-input>
                        <div class="event-remove-btn" @click="removeEvent('base', 0)">
                                        <span>
                                            <i class="el-dialog__close el-icon el-icon-close"></i>
                                        </span>
                        </div>
                    </div>
                    <label class="text-danger small" v-if="removeBasecode">
                        <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                        You can't remove base code except all event codes are removed
                    </label>
                </div>

                <div id="videoEventCodeListing" class="">
                    <!--            <div id="videoEventCodeListing" class="stored">-->

                    <div class="eventHeader">
                        <b>Event codes</b>
                        <p>Measure your custom audience based on action or goals reached</p>
                    </div>
                    <div v-for="(data, index) in this.video.video_basecodes.event_code" :key='index'>

                        <div class="video_basecode_input" v-bind:class="{ edit: data.edit}" id="video_basecode_input" :data-id="data.id" data-type="event">
                            <div class="afterStore">
                                {{data.time}} ({{data.name}})
                                <el-dropdown

                                        trigger="click"
                                        class="dropdown-container PixelActions"
                                        menu-align="end"
                                        placement="bottom"
                                >
                                    <i class="el-icon-more "></i>
                                    <el-dropdown-menu slot="dropdown"
                                                    class="video-settings-dropdown custom_dropdown dropdown_bottom_200">
                                        <el-dropdown-item @click.native="editEventCode(data.id, 'event')">Edit
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteEventCode(data.id, 'event')">Remove
                                        </el-dropdown-item>

                                    </el-dropdown-menu>

                                </el-dropdown>
                            </div>
                            <div class="eventCodeFormate">

                                <div class="event_time">
                                    <input type="text" name="" autocomplete="off" placeholder=""
                                        class="el-input__inner" value=""
                                        v-model="video.video_basecodes.event_code[index].time"
                                        @keyup="validateTime(index, 'time')">
                                </div>

                                <div class="event_code">

                                    <el-input
                                            v-model="video.video_basecodes.event_code[index].code"
                                            type="textarea"
                                            placeholder="Enter event code here"
                                            rows="3"
                                            cols="50"
                                            class="resize_none"

                                    ></el-input>
                                </div>
                                <div class="event-remove-btn" @click="removeEvent('event', index)">
                                                <span>
                                                    <i class="el-dialog__close el-icon el-icon-close"></i>
                                                </span>
                                </div>


                            </div>
                            <label class="text-danger small"
                                v-if="validateTimeAndEvent.length > 0 && validateTimeAndEvent[index].time !== true">
                                <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>
                                {{validateTimeAndEvent[index].time}}
                            </label>

                        </div>
                    </div>

                </div>


                <!--            <div v-for="(data, index) in video.video_basecodes">-->
                <!--                <div v-if="data.type == 'base'">-->
                <!--                    <div class="video_basecode_input">-->
                <!--                        <el-input-->
                <!--                                v-model="video.video_basecodes[index].code"-->
                <!--                                type="textarea"-->
                <!--                                placeholder="Enter base code(s)"-->
                <!--                                rows="3"-->
                <!--                                cols="50"-->
                <!--                                class="resize_none"-->
                <!--                        ></el-input>-->
                <!--                        <div class="event-remove-btn" @click="removeEvent(index)">-->
                <!--                          <span>-->
                <!--                            <i class="el-dialog__close el-icon el-icon-close"></i>-->
                <!--                          </span>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                    <label class="text-danger small" v-if="removeBasecode">-->
                <!--                        <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>-->
                <!--                        You can't remove base code except all event codes are removed-->
                <!--                    </label>-->
                <!--                </div>-->
                <!--                <div v-else>-->
                <!--                    <div class="video_basecode_input">-->
                <!--                        <div class="inline-flex">-->

                <!--                            <div class="event_time">-->
                <!--                                <input type="text" name="" autocomplete="off" placeholder=""-->
                <!--                                       class="el-input__inner" value=""-->
                <!--                                       v-model="video.video_basecodes[index].time" @keyup="validateTime(index, 'time')">-->
                <!--                            </div>-->
                <!--                            <div class="event_code">-->

                <!--                                <el-input-->
                <!--                                        v-model="video.video_basecodes[index].code"-->
                <!--                                        type="textarea"-->
                <!--                                        placeholder="Enter event code here"-->
                <!--                                        rows="3"-->
                <!--                                        cols="50"-->
                <!--                                        class="resize_none"-->

                <!--                                ></el-input>-->
                <!--                            </div>-->
                <!--                            <div class="event-remove-btn" @click="removeEvent(index)">-->
                <!--                                  <span>-->
                <!--                                    <i class="el-dialog__close el-icon el-icon-close"></i>-->
                <!--                                  </span>-->
                <!--                            </div>-->


                <!--                        </div>-->

                <!--                    </div>-->
                <!--                    <label class="text-danger small" v-if="validateTimeAndEvent[index].time !== true">-->
                <!--                        <img alt class="warning-icon" src="../../assets/img/auth/warning.svg"/>-->
                <!--                        {{validateTimeAndEvent[index].time}}-->
                <!--                    </label>-->
                <!--                </div>-->

                <!--            </div>-->

            </div>

            <div class="">
                <div class="add_new_pixel" @click="appendEventInput">
                    + Add a goal
                </div>
            </div>


        </div>
    </div>
</template>
<script>
    export default {
        props: ["video", "restricted"],
        data() {
            return {
                // eventInput: [],
                validateTimeAndEvent: [],
                removeBasecode: false
            }
        },

        computed: {},
        mounted: function () {
            this.checkData();
        },

        methods: {
            checkData() {
                if (this.video.video_basecodes !== null) {

                    // console.log(document.getElementById('videoEventCodeListing'), this.video.video_basecodes, 'created');
                    document.getElementById('videoEventCodeListing').classList.add('stored')
                    document.getElementById('videoBaseCodeListing').classList.add('stored')
                }
            },
            appendEventInput() {
                if (this.restricted) return false;
                this.removeBasecode = false;
                if (this.video.video_basecodes == null) {
                    this.video.video_basecodes = {
                        type: 'base',
                        code: null,
                        event_code: [],
                    };
                    this.video.video_basecodes.event_code.push({
                        type: 'event',
                        time: '00:00:00',
                        code: null
                    });
                } else {

                    var element = document.getElementById('videoBaseCodeListing');

                    if(element.classList.contains('stored')){

                        this.video.video_basecodes.event_code.push({
                            type: 'event',
                            time: '00:00:00',
                            code: null,
                            edit: true
                        });
                    }else{
                        this.video.video_basecodes.event_code.push({
                            type: 'event',
                            time: '00:00:00',
                            code: null,
                            edit: false

                        });
                    }
                }

                if (this.video.video_basecodes.event_code.length > 0) {
                    this.validateTimeAndEvent = [];
                    this.video.video_basecodes.event_code.forEach((value, i) => {
                        var expression = /^(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/;
                        var regex = new RegExp(expression);
                        var time = value.time;
                        if (time !== '' && time.match(regex)) {

                            var a = time.split(':');
                            var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

                            if (seconds > this.video.duration) {
                                this.validateTimeAndEvent.push({
                                    time: 'The timestamp you entered exceeds the video timeframe',
                                    event: true
                                })
                            } else {
                                this.validateTimeAndEvent.push({
                                    time: true,
                                    event: true
                                })
                            }
                        } else {
                            this.validateTimeAndEvent.push({
                                time: 'Add valid time formate',
                                event: true
                            })
                        }


                    });
                }

            },
            removeEvent(type, index) {


                if (type == 'base') {

                    if (this.video.video_basecodes.event_code.length > 0) {
                        this.removeBasecode = true;
                    } else {
                        this.video.video_basecodes = null;
                    }
                }


                if (type == 'event') {
                    var temp = [];

                    this.video.video_basecodes.event_code.forEach((value, i) => {
                        if (i !== index) {
                            temp[temp.length] = value;
                        }
                    });
                    this.video.video_basecodes.event_code = temp;
                }

            },
            validateTime(index, type) {
                if (type == "time") {
                    var expression = /^(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/;
                    var regex = new RegExp(expression);
                    var time = this.video.video_basecodes.event_code[index].time;
                    if (time !== '' && time.match(regex)) {
                        var a = time.split(':');

                        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
                        if (seconds > this.video.duration) {

                            this.validateTimeAndEvent[index] = {
                                time: 'The timestamp you entered exceeds the video timeframe',
                                event: true
                            }
                        } else {
                            this.validateTimeAndEvent[index] = {
                                time: true,
                                event: true
                            }
                        }
                    } else {
                        this.validateTimeAndEvent[index] = {
                            time: 'Add valid time formate',
                            event: true
                        }
                    }

                } else {

                    // var title = this.video.video_chapters[index].title;
                    // console.log(title);
                    // if (title !== '') {
                    //     this.video.video_chapters[index].titleError = false;
                    // } else {
                    //     this.video.video_chapters[index].titleError = true;
                    //     return false;
                    // }

                }

            },
            deleteEventCode(id, type) {


                var $this = this;
                if (type == "base" && this.video.video_basecodes.event_code !== "undefined" &&  this.video.video_basecodes.event_code.length > 0) {

                    $this.$message({
                        showClose: true,
                        message: "You can't remove base code except all event codes are removed",
                        type: "error"
                    });
                    return false

                }
                let post_data = {
                    data: {
                        id: id,
                        type: type

                    },
                    url: "/removeVideoPixelRetargeting"
                };
                $this.$store.dispatch("post", {...post_data})
                    .then(response => {
                        if (type == "event") {
                            var temp = [];
                            $this.video.video_basecodes.event_code.forEach((value, index) => {
                                if (value.id !== id) {
                                    temp[temp.length] = value;
                                }
                            });
                            $this.video.video_basecodes.event_code = temp;
                        }else{
                            this.video.video_basecodes = null;
                        }
                    })
                    .catch(error => {
                    });
            },
            editEventCode(id, type) {
                console.log(this.video, 'bbfnbngb');
                document.querySelector('[data-id="' + id + '"][data-type="' + type + '"]').classList.add('edit');
            },

        },

    };
</script>
<style lang="less">
    .pixel_retargeting {

        .add_new_pixel {
            background: #ebf9fa;
            padding: 8px;
            border-radius: 5px;
            color: #33abd8;
            text-align: center;
            cursor: pointer;
        }

        .video_basecode_input {
            padding: 15px;
            margin-bottom: 10px;
            background: #f3f3f3;
            border-radius: 5px;
            position: relative;

            .el-textarea__inner {
                border-color: #81D3EA !important;
                resize: none;
            }

            .el-input__inner {
                border-color: #81D3EA !important;
            }
        }

        .event_time {
            margin: auto 0;
        }

        .event_code {
            margin: auto 0 auto 10px;
        }

        .event-remove-btn {
            position: absolute;
            top: 43%;
            right: 4px;
            display: none;
            cursor: pointer;
            font-size: 8px;
            color: #828282;
        }

        .video_basecode_input:hover .event-remove-btn {
            display: block;
        }

        #videoBaseCodeListing, #videoEventCodeListing {
            .afterStore {
                display: none;
            }

            .baseHeader, .eventHeader {
                display: none;
            }
        }

        .eventCodeFormate {
            display: inline-flex;
        }

        /*#listPixelRetargeting.apply {*/
        /*    display: none;*/
        /*}*/

        #listPixelRetargeting.apply {


        }

        #videoBaseCodeListing.stored {
            .baseHeader {
                display: block;
            }

            .video_basecode_input:not(.edit) {
                padding: 10px;
                height: 40px;

                .PixelActions {
                    position: absolute;
                    right: 10px;
                    top: 12px;
                    cursor: pointer;
                }

                .el-textarea__inner {
                    display: none;
                }

                .afterStore {
                    display: block;
                }

                .event-remove-btn {
                    display: none;
                }
            }


        }

        #videoEventCodeListing.stored {
            .eventHeader {
                display: block;
            }

            .video_basecode_input:not(.edit) {
                padding: 10px;
                height: 40px;

                .eventCodeFormate {
                    display: none !important;
                }

                .afterStore {
                    display: block;
                }

                .PixelActions {
                    position: absolute;
                    right: 10px;
                    top: 12px;
                    cursor: pointer;
                }

            }

        }

        .upload-restriction-dialog-2 {
            font-size: 14px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 95;
        }

        .warn-sign {
            position: relative;
            width: 60px;
            height: 60px;
            text-align: center;
            margin-bottom: 15px;

            img {
                max-width: 100%;
                object-fit: cover;
            }
        }

    }
</style>
