<template>
    <div class="modal">
        <div class="modal-content">
            <div class="modal-header">
                <div class="modal-title">Header</div>
                <div class="modal-close" @click='closeModal'><i class="fas fa-times"></i></div>
            </div>
            <div class="modal-body">


                <div v-if="type == 'during_video_call_to_action'">

                    <div v-if="video.player_options.interaction_during_active" class="row-space-top-4">
                        <div class="timeBox">
                            <el-input
                                    class="time"
                                    placeholder="00:00:00"
                                    :value="during_time"
                                    @input="changeInteractionDuringTime($event)"
                                    style="width: 50%;"
                                    pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$"
                            >
                                <template slot="prepend">Time</template>
                            </el-input>
                        </div>

                        <div class="centerInputs">
                            <div class="row-sapce-top-3 inputBox">
                                <label for="">Type</label>
                                <el-select
                                        v-model="video.player_options.interaction_during_type"
                                        popper-class="custom_select_pop with_arrow_190"
                                        placeholder="Select"
                                        style="width: 100%;"
                                >
                                    <el-option
                                            v-for="item in [{value: 'text', label: 'Text'}, {value: 'image', label:'Image'},{ value: 'html_code', label: 'HTML'}]"
                                            :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>

                            <!-- text -->
                            <div v-if="video.player_options.interaction_during_type == 'text'" class="row-space-top-3">
                                <div class="row-space-3 inputBox">
                                    <label for="">Text</label>
                                    <el-input type="textarea" v-model="video.player_options.interaction_during_text"
                                              placeholder="Enter text"></el-input>
                                </div>

                                <div class="row-space-3 inputBox">
                                    <label for="">URL Link</label>
                                    <el-input v-model="video.player_options.interaction_during_link_url"
                                              placeholder="Enter URL Link"></el-input>
                                </div>
                            </div>
                            <!-- /text -->

                            <!-- image -->
                            <div v-if="video.player_options.interaction_during_type == 'image'" class="row-space-top-3">
                                <label for="">Image</label>
                                <div class="row-space-3">
                                    <image-input v-model="video.player_options.interaction_during_image"
                                                 :user="user"></image-input>
                                </div>

                                <label for="">URL Link</label>
                                <div class="row-space-3">
                                    <el-input v-model="video.player_options.interaction_during_link_url"
                                              placeholder="Enter URL Link"></el-input>
                                </div>
                            </div>
                            <!-- /image -->

                            <!-- html code -->
                            <div v-if="video.player_options.interaction_during_type == 'html_code'"
                                 class="row-space-top-3">
                                <label for="">HTML</label>
                                <div class="row-space-3">
                                    <el-input type="textarea" placeholder="HTML Code"
                                              v-model="video.player_options.interaction_during_html_code"></el-input>
                                </div>
                            </div>
                            <!-- /html code -->

                            <hr>


                            <label for="">Options</label>
                            <div class="row-space-top-1 allow">
                                <el-checkbox v-model="video.player_options.interaction_during_allow_skip">Allow skip
                                </el-checkbox>
                            </div>

                        </div>
                    </div>

                </div>


                <div v-if="type == 'during_video_email'">

                    <div v-if="video.player_options.interaction_during_email_capture" class="row-space-top-3">
                        <el-input placeholder="00:00:00"
                                  class="time timeBox"
                                  :value="capture_time"
                                  @input="changeInteractionDuringEmailCaptureTime($event)"
                                  style="width: 50%;"
                        >
                            <template slot="prepend">Time</template>
                        </el-input>

                        <div class="row-space-top-3 inputBox">
                            <label for="">Type</label>
                            <br>
                            <el-radio v-model="video.player_options.interaction_during_email_capture_type" label="full">
                                Full
                            </el-radio>
                            <br>
                            <el-radio v-model="video.player_options.interaction_during_email_capture_type"
                                      label="minimized">Minimized <span class="mini-text">(desktop)</span>
                            </el-radio>
                        </div>

                        <div class="row-space-top-3 inputBox">
                            <label for="">Options</label>

                            <div class="">
                                <el-checkbox v-model="video.player_options.interaction_during_email_capture_firstname">
                                    Firstname <span class="mini-text">(desktop)</span>
                                </el-checkbox>
                            </div>
                            <div class="">
                                <el-checkbox v-model="video.player_options.interaction_during_email_capture_lastname">
                                    Lastname <span class="mini-text">(desktop)</span>
                                </el-checkbox>
                            </div>
                            <div class="">
                                <el-checkbox v-model="true_value" disabled>Email</el-checkbox>
                            </div>
                            <div class="">
                                <el-checkbox
                                        v-model="video.player_options.interaction_during_email_capture_phone_number">
                                    Phone number <span class="mini-text">(desktop)</span>
                                </el-checkbox>
                            </div>
                            <div class="">
                                <el-checkbox v-model="video.player_options.interaction_during_email_capture_allow_skip">
                                    Allow skip
                                </el-checkbox>
                            </div>
                        </div>

                        <hr>

                        <div>
                            <label for="">Upper Text</label>

                            <el-input type="textarea" class="row-space-top-1"
                                      v-model="video.player_options.interaction_during_email_capture_upper_text"></el-input>
                        </div>


                        <hr>

                        <div>
                            <label for="">Lower Text</label>

                            <el-input type="textarea" class="row-space-top-1"
                                      v-model="video.player_options.interaction_during_email_capture_lower_text"></el-input>
                        </div>

                        <hr>

                        <div>
                            <label for="">Button</label>

                            <el-input class="row-space-top-1"
                                      v-model="video.player_options.interaction_during_email_capture_button_text"></el-input>
                        </div>

                        <hr>

                        <div>
                            <label for="">Email List</label>

                            <div class="row-space-top-1">
                                <el-select
                                        v-model="video.player_options.interaction_during_email_capture_email_list"
                                        popper-class="custom_select_pop with_arrow_190"
                                        style="width:100%"
                                        placeholder="Select">
                                    <el-option-group v-for="group in email_providers" :key="group.mailer"
                                                     :label="getNicename(group.mailer)">
                                        <el-option v-for="item in group.lists" :key="item.value" :label="item.name"
                                                   :value="group.mailer + '_' + item.id">
                                        </el-option>
                                    </el-option-group>
                                </el-select>
                            </div>
                        </div>

                        <hr>

                        <div class="">
                            <label for="">Tags
                                <span v-if="video.player_options.interaction_during_email_capture_email_tags">({{video.player_options.interaction_during_email_capture_email_tags.length}})</span>
                            </label>

                            <div class="row-space-top-1 interaction-tool">
                                <el-input placeholder="Enter tag" v-model="interaction_during_tag_input"
                                          @keyup.enter.native="addTag('during')" required>
                                    <el-button slot="append" @click="addTag('during')" class="el-icon-plus"></el-button>
                                </el-input>
                            </div>

                            <div class="row-space-top-2">
                                <el-tag v-for="tag in video.player_options.interaction_during_email_capture_email_tags"
                                        v-bind:key="tag" closable @close="removeTag('during', tag)" type="success">
                                    {{tag}}
                                </el-tag>
                            </div>
                        </div>

                    </div>

                </div>


                <div v-if="type == 'after_video'">

                    <div class="centerInputs" v-if='!showAfterRestrictionModal'>
                        <div class="row-space-3 clearfix inputBox">
                            <label for="">Type</label>
                            <div class="">
                                <el-select
                                        style="width:100%"
                                        v-model="video.player_options.interaction_after_type"
                                        popper-class="custom_select_pop with_arrow_190"
                                        placeholder="Select">
                                    <el-option v-for="item in after_interactions" :key="item.value"
                                               :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>

                        <!-- more videos -->
                        <div v-if="video.player_options.interaction_after_type == 'more_videos'">
                            <div class="inputBox">
                                <label for="">Videos</label>
                                <video-selector
                                        multiple
                                        :project="video.project"
                                        :video="video"
                                        v-model="video.player_options.interaction_after_more_videos_list"
                                ></video-selector>
                            </div>

                            <div class="row-space-top-3 inputBox" style="margin-top:30px">
                                <label for="">Text</label>
                                <el-input type="textarea"
                                          v-model="video.player_options.interaction_after_more_videos_text"></el-input>
                            </div>
                        </div>
                        <!-- /more videos -->
                    </div>


                    <!-- email capture after -->
                    <div v-if="video.player_options.interaction_after_type == 'email_capture'"
                         class="row-space-top-3 row-space-5">
                        <div>
                            <div class="inputBox">
                                <label for="">Type</label>
                                <br>
                                <el-radio v-model="video.player_options.interaction_after_email_capture_type"
                                          label="full">
                                    Full
                                </el-radio>
                                <br>
                                <el-radio v-model="video.player_options.interaction_after_email_capture_type"
                                          label="minimized">
                                    Minimized <span class="mini-text">(desktop)</span>
                                </el-radio>
                            </div>

                            <div class="inputBox">
                                <label for="">Options</label>

                                <div class="">
                                    <el-checkbox
                                            v-model="video.player_options.interaction_after_email_capture_firstname">
                                        Firstname <span class="mini-text">(desktop)</span>
                                    </el-checkbox>
                                </div>
                                <div class="">
                                    <el-checkbox
                                            v-model="video.player_options.interaction_after_email_capture_lastname">
                                        Lastname <span class="mini-text">(desktop)</span>
                                    </el-checkbox>
                                </div>
                                <div class="">
                                    <el-checkbox v-model="true_value" disabled>Email</el-checkbox>
                                </div>
                                <div class="">
                                    <el-checkbox
                                            v-model="video.player_options.interaction_after_email_capture_phone_number">
                                        Phone number <span class="mini-text">(desktop)</span>
                                    </el-checkbox>
                                </div>
                                <div class="">
                                    <el-checkbox
                                            v-model="video.player_options.interaction_after_email_capture_allow_skip">
                                        Allow
                                        skip
                                    </el-checkbox>
                                </div>
                            </div>

                            <hr>

                            <div>
                                <label for="">Upper Text</label>

                                <el-input type="textarea" class="row-space-top-1"
                                          v-model="video.player_options.interaction_after_email_capture_upper_text"></el-input>
                            </div>


                            <hr>

                            <div>
                                <label for="">Lower Text</label>

                                <el-input type="textarea" class="row-space-top-1"
                                          v-model="video.player_options.interaction_after_email_capture_lower_text"></el-input>
                            </div>

                            <hr>

                            <div>
                                <label for="">Button</label>

                                <el-input class="row-space-top-1"
                                          v-model="video.player_options.interaction_after_email_capture_button_text"></el-input>
                            </div>

                            <hr>

                            <div>
                                <label for="">Email List</label>

                                <div class="row-space-top-1">
                                    <el-select
                                            v-model="video.player_options.interaction_after_email_capture_email_list"
                                            popper-class="custom_select_pop with_arrow_190"
                                            placeholder="Select">
                                        <el-option-group v-for="group in email_providers" :key="group.mailer"
                                                         :label="getNicename(group.mailer)">
                                            <el-option v-for="item in group.lists" :key="item.value" :label="item.name"
                                                       :value="group.mailer + '_' + item.id">
                                            </el-option>
                                        </el-option-group>
                                    </el-select>
                                </div>
                            </div>

                            <hr>

                            <div class="">
                                <label for="">Tags
                                    <span v-if="video.player_options.interaction_after_email_capture_email_tags">({{video.player_options.interaction_after_email_capture_email_tags.length}})</span>
                                </label>

                                <div class="row-space-top-1 interaction-tool">
                                    <el-input placeholder="Enter tag" v-model="interaction_after_tag_input"
                                              @keyup.enter.native="addTag('after')" required>
                                        <el-button slot="append" @click="addTag('after')"
                                                   class="el-icon-plus"></el-button>
                                    </el-input>
                                </div>

                                <div class="row-space-top-2">
                                    <el-tag v-for="tag in video.player_options.interaction_after_email_capture_email_tags"
                                            v-bind:key="tag" closable @close="removeTag('after', tag)" type="success">
                                        {{tag}}
                                    </el-tag>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- /email capture after-->

                    <!-- call to action -->
                    <div v-if="video.player_options.interaction_after_type == 'call_to_action'" class="">
                        <label for="">Type</label>
                        <div class="row-sapce-top-3 inputBox">
                            <el-select
                                    v-model="video.player_options.interaction_after_cta_type"
                                    popper-class="custom_select_pop with_arrow_190"
                                    placeholder="Select">
                                <el-option
                                        v-for="item in [{value: 'text', label: 'Text'}, {value: 'image', label:'Image'},{ value: 'html_code', label: 'HTML'}]"
                                        :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <!-- text -->
                        <div v-if="video.player_options.interaction_after_cta_type == 'text'">
                            <label for="">Text</label>
                            <div class="inputBox">
                                <el-input type="text" v-model="video.player_options.interaction_after_cta_text"
                                          placeholder="Enter text"></el-input>
                            </div>

                            <label for="">URL Link</label>
                            <div class="inputBox">
                                <el-input v-model="video.player_options.interaction_after_cta_link_url"
                                          placeholder="Enter URL Link"></el-input>
                            </div>
                        </div>
                        <!-- /text -->

                        <!-- image -->
                        <div v-if="video.player_options.interaction_after_cta_type == 'image'" class="inputBox">
                            <label for="">Image</label>
                            <div class="row-space-3">
                                <image-input v-model="video.player_options.interaction_after_cta_image"
                                             :user="user"></image-input>
                            </div>

                            <label for="">URL Link</label>
                            <div class="row-space-3">
                                <el-input v-model="video.player_options.interaction_after_cta_link_url"
                                          placeholder="Enter URL Link"></el-input>
                            </div>
                        </div>
                        <!-- /image -->

                        <!-- html code -->
                        <div v-if="video.player_options.interaction_after_cta_type == 'html_code'"
                             class="row-space-top-3">
                            <label for="">HTML</label>
                            <div class="row-space-3">
                                <el-input type="textarea" placeholder="HTML Code"
                                          v-model="video.player_options.interaction_after_cta_html_code"></el-input>
                            </div>
                        </div>
                        <!-- /html code -->
                    </div>
                    <!-- /call to action -->

                    <!-- redirect -->
                    <div v-if="video.player_options.interaction_after_type == 'redirect'" class="">
                        <label for="">URL Link</label>

                        <el-input type="url" v-model="video.player_options.interaction_after_cta_link_url"></el-input>
                    </div>
                    <!-- /redirect -->

                </div>


            </div>
            <div class="modal-footer">

                <button class="el-button el-button--gray" @click="closeModal" type="gray">Cancel</button>

                <span class="btn btn-primary" @click="storeBranchVideo">Apply</span>

            </div>
        </div>

    </div>
</template>

<style scoped lang="less">
    .modal {
        position: fixed;
        width: 100vw;
        height: 100vw;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(1, 26, 34, 0.301);
        z-index: 3000;

        .modal-body {
            padding: 15px 15px;
        }

        .modal-footer {
            padding: 15px 15px;
            text-align: center;
        }
    }

    .modal-body {

        max-height: 500px;
        overflow-y: scroll;

    }

    @keyframes fadeIn {
        from {
            opacity: 0;
            top: -10px;
        }
        to {
            opacity: 1;
            top: 0;
        }
    }

    .modal-content {
        position: absolute;
        width: 637px;
        height: max-content;
        margin: auto;
        left: 0;
        right: 0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #00000014;
        border: 1px solid #0BACDB;
        border-radius: 12px;
        opacity: 1;
        animation: fadeIn 300ms cubic-bezier(0.57, 0.25, 0.65, 1) 1 forwards;
        /* overflow: scroll; */
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px;
        color: #21455E;
        border-bottom: 1px solid #00000014;
    }

    .modal-header .modal-close {
        cursor: pointer;
    }

    .modal-header .modal-close i {
        color: #A3BAC6;
    }

    .modal-header .modal-title {
        position: relative;
        left: 50%;
        margin-left: -50px;
    }

    .modal-sections {
        margin-left: 102px;
        padding: 25px 25px;
    }

    ::-webkit-scrollbar {
        width: 20px;
    }

    ::-webkit-scrollbar-track {
        background: rgb(255, 255, 255);
    }

    ::-webkit-scrollbar-thumb {
        background: #C9EDF8;
        background-clip: padding-box;
        border: 15px solid rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #C9EDF8;
    }

    ::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
    }


</style>

<script>

    import AppHelper from "../../utils/AppHelper";
    import Popover from '../Common/Popover';
    import VideoSelector from '../UI/VideoSelector.vue';
    import ImageInput from '../UI/ImageInput.vue';


    export default {
        props: ['video', 'type', 'user'],
        name: 'FunnelIntegrationModal',
        data: () => ({
            true_value: true,
            email_providers: [],
            during_time: '00:00:00',
            capture_time: '00:00:00',
            AppHelper: AppHelper,
            nicenames: {
                'mailchimp': 'MailChimp',
                'aweber': 'Aweber',
                'getresponse': 'GetResponse',
                'sendlane': 'SendLane',
                'sendreach': 'SendReach',
                'benchmark': 'Benchmark',
                'infusionsoft': 'Infusionsoft',
                'activecampaign': 'ActiveCampaign',
                'gotowebinar': 'GoToWebinar',
                'hubspot': 'HubSpot',
                'campaignmonitor': 'CampaignMonitor',
                'constantcontact': 'ConstantContact',
                'icontact': 'iContact'
            },
            interaction_before_tag_input: '',
            interaction_during_tag_input: '',
            interaction_after_tag_input: '',
            loading: false,
            showRestrictionModal: true,
            email_capture_restrict: false,
            showEmailRestrictPopover: false,
            after_interactions: [
                {value: 'more_videos', label: 'More Videos'},
                {value: 'email_capture', label: 'Capture Email'},
                {value: 'call_to_action', label: 'Call-to-Action'},
                {value: 'loop', label: 'Loop the Video'},
                {value: 'show_last_frame', label: 'Pause on the Last Frame'},
                {value: 'show_thumbnail', label: 'Reset to the Thumbnail'},
                {value: 'redirect', label: 'Redirect to a URL Link'}
            ],
            showAfterRestrictionModal: false,
            showDuringRestrictionModal: false,
        }),
        components: {
            VideoSelector: VideoSelector,
            ImageInput: ImageInput,
            Popover: Popover,
        },
        created() {

        },
        mounted() {
            this.during_time = AppHelper.convertSecondsToTime(this.video.player_options.interaction_during_time);
            this.capture_time = AppHelper.convertSecondsToTime(this.video.player_options.interaction_during_email_capture_time)
            this.loadEmailProviderList();
            // this.getRestrictions();
        },
        methods: {
            changeInteractionDuringTime(e) {
                this.during_time = e;
                this.video.player_options.interaction_during_time = AppHelper.convertTimeToSeconds(e);
            },
            storeBranchVideo() {
                this.$emit('store');
            },
            closeModal() {
                this.$emit('trigger', false);
            },
            loadEmailProviderList() {
                var $this = this;
                this.axios({
                    url: 'integrations/lists',
                    method: 'GET'
                }).then(function (response) {
                    $this.email_providers = $this.email_providers.concat(response.data);
                    $this.providersLoaded = true;
                }).catch(function (response) {
                    $this.$message({
                        'showClose': true,
                        'message': 'An error occurred while retrieving email providers: ' + response.message,
                        type: 'error'
                    });
                });
            },
            addTag(where, e) {
                var value = this['interaction_' + where + '_tag_input'];
                if (value !== '') {
                    this['interaction_' + where + '_tag_input'] = '';
                    if (!this.video.player_options['interaction_' + where + '_email_capture_email_tags'])
                        this.video.player_options['interaction_' + where + '_email_capture_email_tags'] = [];
                    this.video.player_options['interaction_' + where + '_email_capture_email_tags'].push(value);
                }
            },
            removeTag(where, tag) {
                this.video.player_options['interaction_' + where + '_email_capture_email_tags'].splice(
                    this.video.player_options['interaction_' + where + '_email_capture_email_tags'].indexOf(tag), 1);
            },
            getNicename(name) {
                if (this.nicenames[name] !== undefined) {
                    return this.nicenames[name];
                }
                return name;
            },
            changeInteractionDuringEmailCaptureTime(e) {
                this.capture_time = e;
                this.video.player_options.interaction_during_email_capture_time = AppHelper.convertTimeToSeconds(e);
            },
        }
    }
</script>