<template>
    <div>

        <el-row class="row-space-3">
            <el-col :span="24">
                <p class="text-lightest row-space-1">
                    Public link for sharing
                </p>
                <div class="el-input">
                    <input type="text" class="el-input__inner" onclick="this.select()" v-model="url">
                </div>
            </el-col>
        </el-row>

        <el-row class="row-space-3" type="flex" align="middle">
            <el-col>
                <el-checkbox class="row-space-right-2" v-model="time">Link to a specific time: {{ timeString }}</el-checkbox>
                <i @click="decreaseTime" class="pointer el-icon-minus row-space-right-1"></i>
                <i @click="increaseTime" class="pointer el-icon-plus"></i>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-button data-type="copy-embed-code-to-clipboard"
                           type="primary"
                           v-clipboard:copy="url"
                           @click="copyText = 'Copied!'"
                           v-text="copyText">
                    Copy
                </el-button>
            </el-col>
        </el-row>

    </div>
</template>
<script>
    export default {
        props: ['video'],
        data () {
            return {
                width: 360,
                height: 200,
                copyText: 'Copy',
                time: false,
                timeNumber: 0
            }
        },
        methods: {
            increaseTime () {
                if (this.timeNumber + 30 <= this.video.duration) {
                    this.timeNumber = this.timeNumber + 30;
                }
            },

            decreaseTime () {
                if (this.timeNumber - 30 >= 0) {
                    this.timeNumber = this.timeNumber - 30;
                }
            },

            toHHMMSS (duration) {
                var secNum = parseInt(duration, 10); // don't forget the second param
                var hours = Math.floor(secNum / 3600);
                var minutes = Math.floor((secNum - (hours * 3600)) / 60);
                var seconds = secNum - (hours * 3600) - (minutes * 60);

                if (hours < 10) {
                    hours = "" + hours;
                }
                if (minutes < 10 && hours > 0) {
                    minutes = "0" + minutes;
                }
                if (seconds < 10) {
                    seconds = "0" + seconds;
                }
                return (hours > 0 ? hours + ':' : '') + minutes + ':' + seconds;
            }
        },
        computed: {
            'url' () {
                return this.video.embed_url + (this.time ? '/' + this.timeString : '');
            },

            'timeString' () {
                return this.toHHMMSS(this.timeNumber);
            }
        }
    }
</script>
