<template>
    <el-card class="box-card view-summary m-top-4" style="padding-bottom:30px">
        <div  slot="header" class="d-flex justify-content-between align-items-center px-15">
            <span class="header-summary">Video Ratings &nbsp; {{ ratings.length }}</span>
            <span v-if="ratings.length > 0" class="header-summary">Average {{ totalRating }} Of 5 Stars</span>
<!--            <span class="header-sm-summary" @click="redirectAnalyitics()" style="cursor: pointer;">Video Analytics</span>-->
        </div>
        <div v-if="ratings.length > 0">
            <div v-for="rated in ratings" :key="rated.id" class="d-flex row mt-5">
                <div class="col-md-1 text-center">
                    <!-- <span v-if="!rated.comment">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 36 36">
                            <g id="Group_13028" data-name="Group 13028" transform="translate(-1480 -67)">
                                <circle id="Ellipse_4183" data-name="Ellipse 4183" cx="13" cy="13" r="13" transform="translate(1485 74.91)" fill="#21455e"/>
                                <g id="noun_profile_1995071" transform="translate(1480 67)">
                                <path id="Path_9196" data-name="Path 9196" d="M20.5,2.5a18,18,0,1,0,18,18A18.016,18.016,0,0,0,20.5,2.5Zm0,10.307a5.646,5.646,0,1,1-5.646,5.646A5.66,5.66,0,0,1,20.5,12.807ZM10.647,31.414V30.58a4.358,4.358,0,0,1,4.358-4.358H25.995a4.358,4.358,0,0,1,4.358,4.358v.834a14.7,14.7,0,0,1-19.705,0Z" transform="translate(-2.5 -2.5)" fill="#e8ecee"/>
                                </g>
                            </g>
                        </svg>
                    </span> -->
                    <span v-if="!rated.name || !rated.comment || confirmTwoWordsName(rated.name) != ' '">
                        <img class="rating-vatar" :src="require(`../../../assets/img/icons/avatar/${vatars[ratingVatar()]}`)" />
                    </span>
                    <span v-else>
                        <avatar :username="rated.name" :size="40"></avatar>
                    </span>
                </div>
                <div class="col-md-10">
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <div>{{ rated.name }}</div> -->
                        <div>{{ rated.name }}</div>
                        <!-- <div>Sept. 2, 2019</div> -->
                        <div>{{ momentizeDate(rated.created_at) }}</div>
                        <div class="d-flex justify-content-between align-items-center" >
                            <span v-if="iDeleteLoading">
                                <span v-loading="iDeleteLoading == rated.id" ></span>
                            </span>
                            <span class="rate-del-copy rate-del-copy-all" @click.prevent="deleteRating(rated.id)">Delete &nbsp;</span> 
                            <span class="rate-del-copy-all">
                                <i class="fa fa-circle" aria-hidden="true" style="font-size: 6px;"></i> &nbsp;
                            </span> 
                            <span class="rate-del-copy rate-del-copy-all" @click="copyEmail(rated)">Copy email</span>
                        </div>
                    </div>
                    <div>
                        <span>{{ rated.comment }}</span>
                    </div>
                    <div>
                        <span>
                            <el-rate
                                v-model="rated.rating"
                                disabled
                                text-color="#ff9900">
                            </el-rate>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h3 style="text-align:center">This video has no rating at the moment</h3>
        </div>
    </el-card>
</template>

<script>
import Avatar from 'vue-avatar';
import moment from 'moment';

    export default {
        name: "VideoRatingsSummary",
        components: {
            Avatar
        },
        props: ['video_ratings'],
        data: () => {
            return {
                ratings: [],
                vatars : [
                    "1.jpg",
                    "2.jpg",
                    "3.jpg",
                    "4.png",
                    "5.jpg",
                    "6.png",
                    "7.jpg",
                    "8.png",
                    "9.jpg",
                    "10.png",
                    "11.jpg",
                    "12.png",
                    "13.jpg",
                    "14.jpg",
                    "15.jpg",
                    "16.jpg",
                    "17.jpg",
                    "18.jpg",
                    "19.jpg",
                    "20.png",
                    "21.jpg",
                    "22.png",
                    "23.jpg",
                    "24.jpg",
                    "25.jpg",
                ],
                iDeleteLoading: null,
            }
        },
        created() {
            this.ratings = this.video_ratings;
        },
        computed: {
            totalRating() {
                let rates = 0;
                this.ratings.forEach(rate => {
                    rates += +rate.rating
                })
                return (rates/this.ratings.length).toFixed(1);
            }
        },
        methods: {
            async copyEmail(rate) {
                // this.people.email.select();
                // document.execCommand('copy');
                await navigator.clipboard.writeText(rate.email);
                this.$message({
                    'showClose': true,
                    'message': 'Email copied successfully',
                    type: 'success'
                });
            },
            ratingVatar() {
                let idd = Math.floor(Math.random() * this.vatars.length);
                return idd;
            },
            nitialVatar() {
                let idd = Math.floor(Math.random() * this.initialsVatr.length);
                return idd;
            },
            momentizeDate(dat) {
                return moment(dat).format('MMM DD, YYYY');
            },
            confirmTwoWordsName(name) {
                let spaceName = name.split("").find(na => na == ' ');
                return spaceName;
            },  
            async deleteRating(id) {
                try {
                    this.iDeleteLoading = id;
                    const response = await this.axios.delete('/video-ratings/'+id);
                    if(response.data.status == 200) {
                        this.ratings = response.data.data;
                        this.$message({
                            'showClose': true,
                            'message': response.data.message,
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            'showClose': true,
                            'message': response.data.message,
                            type: 'error'
                        });
                    }
                    this.iDeleteLoading = null;
                } catch (error) {
                    this.iDeleteLoading = null;
                    console.log(error);
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .rating-vatar {
        width: 40px;
        border-radius: 50%;
        height: 40px;
    }
    .star-icon {
        color: #FFBB00;
    }
    .rate-del-copy {
        cursor: pointer;
    }
    .rate-del-copy-all {
        opacity: .4;
    }
    .rate-del-copy-all:hover {
        opacity: 1;
    }
    .view-summary{
        border-color: #fff;
        box-shadow: 0 2px 6px rgba(255, 255, 255, .04);
        border-radius: 12px;
        margin: 30px 0 !important;
    }
    .m-top-4{
        margin-top: 20px !important
    }
    .px-15{
        padding-left: 15px;
        padding-right: 15px;
    }
    .header-summary {
        font-weight: 500;
        font-size: 20px;
        color: var(--color-content);
        @media (max-width: 414px){
            margin-left: 0
        }
    }

    .el-popover__reference {
        margin-left: 20px;
        margin-bottom: 4px;
    }
</style>
