<template>
    <h4 class="back-link">
        <i class="cm-icon-arrow-back"></i>
        <router-link :to="{path: url}" class="no-underline" id="go-back-to-projects-page">{{ label }}</router-link>
    </h4>
</template>
<style scoped>
    @import "../../assets/less/motioncta-icons.less";

    .back-link {
        position: relative;
    }

    .back-link a {
        border-bottom: 2px solid #CFEFFC;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.11em;
        line-height: 1.1em;
        text-transform: uppercase;
        text-decoration: none;
    }

    .back-link i {
        color: #d3dadd;
        font-size: 1em;
        position: absolute;
        left: -20px;
        top: 1px;
        transition: ease .2s;
        -webkit-transition: ease .2s;
        -moz-transition: ease .2s;
    }

    .back-link:hover i {
        left: -26px;
    }
</style>
<script>
    export default{
        props: ['url', 'label'],
        data () {
            return {}
        }
    }
</script>
