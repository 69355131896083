<template>
    <div>
        <el-dialog
                title="ARE YOU SURE?"
                center :width=" `30%`"
                class="el-dialog--huge dialog_border_radius"
                :visible.sync="show"
                @close="$parent.showDisableWatermarkingPopup=false"
                :modalAppendToBody="false"
        >

            <div class="row">
            <div>You are about to disable <b>{{ disableType }}</b>. Your video will no  longer be fully protected.  Taking this action makes it easy
                for content  thieves and copyright violators to steal and pirate your content.
            </div>

                <label style="margin-top: 20px">Type <b style="color:red">DISABLE</b> to confirm</label>
                <div class="row-space-top-1 el-input">
                    <input type="text" autocomplete="off" v-model="typeConfirmDelete"
                           class="el-input__inner custom_input_new_style">
                </div>

                <span style="color:red" v-if="isTypedDeleteError">You must type <b style="color:red">DISABLE</b> to confirm</span>
            </div>

            <span slot="footer" class="dialog-footer">
		<el-button @click="$parent.showDisableWatermarkingPopup = false" type="gray">Cancel</el-button>
		<el-button type="danger" @click="disableWatermarkingConfirm">Delete</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'DisableWatermarking',
        components: {},
        props: ['showDisableWatermarkingPopup', 'disableType'],
        data() {
            return {
                show: false,
                typeConfirmDelete: '',
                isTypedDeleteError: false,
            }
        },
        watch: {
            showDisableWatermarkingPopup(newVal) {
                this.show = newVal
            },
            deep: true
        },
        methods: {
            disableWatermarkingConfirm: function() {

                if (this.typeConfirmDelete === 'DISABLE') {

                    this.typeConfirmDelete = '';

                        if(this.$parent.disableType === 'Forensic Watermarking'){
                            this.$parent.video.forensic_watermarking = false;
                        }else{
                            this.$parent.video.visual_watermarking = false;
                        }
                    this.$parent.showDisableWatermarkingPopup = false;
                } else {
                    this.isTypedDeleteError = true;
                }
            },
        }
    }
</script>