<template>
    <el-row :gutter="40" class="sum-content-row mobile-flex-wrap">
        <el-col :span="showColumn > 3 ? 6 : 0" :xs="24" class="between-vertical-line mobile-col-md-6 mobile-col-sm-12 mob-bottom-2" v-if="showColumn > 3">
            <div class="sum-content-header">
                <slot name="first-head"></slot>
            </div>
            <div class="sum-content-body">
                <slot name="first-body"></slot>
            </div>
        </el-col>
   
        <el-col :span="showColumn > 3 ? 6 : 8" :xs="24" class="between-vertical-line mobile-col-md-6 mobile-col-sm-12 mob-bottom-2" v-if="showColumn > 2">
            <div class="sum-content-header">
                <slot name="second-head"></slot>
            </div>
            <div class="sum-content-body">
                <slot name="second-body"></slot>
            </div>
        </el-col>
        <el-col :span="showColumn > 3 ? 6 : (showColumn > 2 ? 8 : 12)" :xs="24" class="between-vertical-line mobile-col-md-6 mobile-col-sm-12 mob-bottom-2" v-if="showColumn > 1">
            <div class="sum-content-header">
                <slot name="third-head"></slot>
            </div>
            <div class="sum-content-body">
                <slot name="third-body"></slot>
            </div>
        </el-col>
        <el-col :span="showColumn > 3 ? 6 : (showColumn > 2 ? 8 : (showColumn > 1 ? 12 : 24))" :xs="24" v-if="showColumn > 0" class="mobile-col-md-6 mobile-col-sm-12 mob-bottom-2">
            <div class="sum-content-header">
                <slot name="fourth-head"></slot>
            </div>
            <div class="sum-content-body">
                <slot name="fourth-body"></slot>
            </div>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: "SummarySection",
        props: {
            showColumn: {
                type: Number,
                default: 4
            }
        }
    }
</script>
