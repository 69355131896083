<template>
    <el-container v-bind:style="{width: asideLevel2Width + 'px', display: asideLevel2Display}" class="aside-level-2">
        <slot></slot>
        
    </el-container>
</template>
<style lang="less">
    .aside-level-2 {
        p {
            /*font-size: 15px !important;*/
        }
        label {
            /*font-weight: 600 !important;*/
            /*font-size: 14px;*/
        }
    }
</style>
<script>
export default {
    name: 'SecondLevel',
    props: ['asideLevel2Width', 'asideLevel2Display']
}
</script>
