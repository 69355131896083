<template>
    <el-container v-bind:style="{width: asideLevel1Width + 'px', display: 'inline-block'}" class="aside-level-1">
        <slot></slot>
    </el-container>
</template>
<style lang="less">
</style>
<script>
export default {
    name: 'FirstLevel',
    props: ['asideLevel1Width']
}
</script>
