<template>
    <div class="share-inline-embed">
        <div>
            <label class="text-lightest">Video size 1:</label>
        </div>

        <el-select
                v-model="value7"
                popper-class="custom_select_pop with_arrow_190"
                placeholder="Select">
            <el-option-group
                    v-for="(group, groupIndex) in options3"
                    :key="group.label"
                    :label="group.label">
                <el-option
                        v-for="(item, index) in group.options"
                        :key="index"
                        :label="item.label"
                        :value="groupIndex + '.' + index">
                </el-option>
            </el-option-group>
        </el-select>

        <div class="row-space-top-3">
            <el-checkbox v-model="responsiveness">Responsiveness <span class="text-lightest">- (The video will resize to fill it's parent div)</span>
            </el-checkbox>
        </div>

        <div class="row-space-top-3">
            <label for="share-embed-code-textarea"
                   class="text-lightest">Embed code:</label>
        </div>

        <div class="row-space-top-3">
            <div class="video-size-options">
                <p class="title-small">Player Type</p>
                <div class="responsive-size d-flex size-options justify-content-between">
                    <div :class="player_size == 'full' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click="playerSize('full')">
                        <div :class="player_size == 'full' ? 'icon-inner-selected' : ''"></div>
                    </div>
                    <div class="label">Full Audio Player</div>
                </div>

                <div class="responsive-size d-flex size-options justify-content-between blurred minified-option" v-if="!this.allAudio">
                    <div :class="player_size == 'icon-outer-unselected'">
                        <div :class="player_size == 'minimized' ? 'icon-inner-selected' : ''"></div>
                    </div>
                    <div class="label">Minimized Player</div>
                </div>
                <div class="responsive-size d-flex size-options justify-content-between minified-option" v-else>
                    <div :class="player_size == 'minimized' ? 'icon-outer-selected' : 'icon-outer-unselected'" @click="playerSize('minimized')">
                        <div :class="player_size == 'minimized' ? 'icon-inner-selected' : ''"></div>
                    </div>
                    <div class="label">Minimized Player</div>
                </div>
            </div>
        </div>

        <div>
            <textarea spellcheck="false" id="share-embed-code-textarea" cols="30" rows="4" onclick="this.select()"
                      contenteditable="false" v-text="html" class="form-control" style="min-height: 130px"></textarea>
        </div>
        <div class="row-space-top-3 relative">
            <el-button data-type="copy-embed-code-to-clipboard"
                       type="primary"
                       v-clipboard:copy="html"
                       @click="copyText = 'Copied!'"
                       v-text="copyText">
                Copy to clipboard
            </el-button>
        </div>
    </div>
</template>
<style>
    .share-inline-embed .el-checkbox__label {

    }
    .icon-outer-selected {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        border: 2px solid #21455E;
    }

    .icon-inner-selected {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background: #21455E 0% 0% no-repeat padding-box;
        position: relative;
        left: 0;
        top: 2px;
        right: 0;
        margin: auto;
    }

    .icon-outer-unselected {
        border: 2px solid #D8DCE6;
        border-radius: 8px;
        width: 16px;
        height: 16px;
    }

    .unselected {
        border: 2px solid #D8DCE6;
    }


    .video-size-options .responsive-size {
        width: 150px;
        margin-top: 20px;
    }

    .video-size-options .fixed-size {
        width: 75px;
        margin-top: 20px;
    }

     .video-size-options { margin-bottom: 25px;}
     .video-size-options .label {
        line-height: 15px;
        font-family: Helvetica Neue;
    }
    .blurred{
        position: relative;

        z-index: 95;
        background: rgb(146 146 146 / 38%);
        backdrop-filter: blur(5px);
        opacity: 1;
    }


</style>
<script>
    export default{
        props: ['video', 'videoListPath'],
        data () {
            return {
                copyText: 'Copy',
                player_size: 'full',
                value7: 0 + '.' + 0,
                options3: [{
                    label: 'Standard 4:3',
                    options: [{
                        value: {
                            width: 640,
                            height: 480
                        },
                        label: '640x480'
                    }, {
                        value: {
                            width: 800,
                            height: 600
                        },
                        label: '800x600'
                    }, {
                        value: {
                            width: 1024,
                            height: 768
                        },
                        label: '1024x768'
                    }]
                }, {
                    label: 'Widescreen 16:9',
                    options: [{
                        value: {
                            width: 640,
                            height: 360
                        },
                        label: '640x360'
                    }, {
                        value: {
                            width: 800,
                            height: 450
                        },
                        label: '800x450'
                    }, {
                        value: {
                            width: 1280,
                            height: 720
                        },
                        label: '1280x720'
                    }]
                }],
                responsiveness: false,
                pathVideo: '',
                allAudio: false,
                queryParams: null
            }
        },
        watch: {
            video(){
                this.pathVideo = this.video.path
            }
        },
        computed: {
            'html' () {
                var responsiveHTML = '';
                    var valueIndexes = this.value7.split('.');
                var option = this.options3[valueIndexes[0]].options[valueIndexes[1]];
                if (this.responsiveness === true) {
                    responsiveHTML = ' style="width: 100% !important;height: 100% !important"';
                    // responsiveHTML = ' style="width: 100% !important;height:'+((option.value.height *100)/document.documentElement.clientWidth).toFixed(1)+'vw;max-height:calc(8 * (100vw / 12) - 28px) !important"';
                }

                // if(this.videoListPath === undefined && this.videoListPath !== true){
                    this.pathVideo = this.video.embed_url
                // }else{
                //     this.pathVideo = this.video.path
                // }

                // Get current selection, output html

                if(this.allAudio && this.player_size == "minimized"){
                    return '<iframe width="738" height="209" allowtransparency="true" src="' + this.pathVideo + (this.player_size == "minimized" ? "&minified=true" : "") + '" frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no"' + responsiveHTML + '></iframe>';
                }

                return '<iframe width="' + option.value.width + '" height="' + option.value.height + '" allowtransparency="true" src="' + this.pathVideo + (this.player_size == "minimized" ? "&minified=true" : "") + '" frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no"' + responsiveHTML + '></iframe>';
            }
        },
        created: function () {

        },
        mounted:function (){
            this.queryParams = this.getParameterByName('list', this.pathVideo);
            console.log(this.queryParams);
            if (this.queryParams !== null) {
                var $this = this;
                this.axios({
                    method: "post",
                    url: process.env.VUE_APP_BACKEND_URL + "api/check-playlist",
                    data: {'id': this.queryParams},
                }).then(function (response) {
                    console.log(response, 'response');
                    if(response.data){
                        $this.allAudio = true;
                    }
                });
            }
        },
        methods: {
            playerSize(type){
                this.player_size = type;
            },
            getParameterByName(name, url) {
                name = name.replace(/[\[\]]/g, '\\$&');
                var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                    results = regex.exec(url);
                if (!results) return null;
                if (!results[2]) return '';
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }
        }
    }
</script>
