<template>
    <el-row :gutter="40" class="sum-content-row mobile-flex-wrap" :class='sideLevelActive ? "mini-summary" : ""'>
        <el-col :span="showColumn > 3 ? 5 : 0" :xs="24" class="between-vertical-line mobile-col-md-6 mobile-col-sm-12 mob-bottom-2" v-if="showColumn > 3">
            <div class="sum-content-header">
                <slot name="first-head"></slot>
            </div>
            <div class="sum-content-body">
                <slot name="first-body"></slot>
            </div>
        </el-col>
   
        <el-col :span="showColumn > 3 ? 3 : 8" :xs="24" class="between-vertical-line mobile-col-md-6 mobile-col-sm-12 mob-bottom-2" v-if="showColumn > 2">
            <div class="sum-content-header">
                <slot name="second-head"></slot>
            </div>
            <div class="sum-content-body">
                <slot name="second-body"></slot>
            </div>
        </el-col>
        <el-col :span="showColumn > 3 ? 7 : (showColumn > 2 ? 7 : 12)" :xs="24" class="between-vertical-line mobile-col-md-6 mobile-col-sm-12 mob-bottom-2" v-if="showColumn > 1">
            <div class="sum-content-header">
                <slot name="third-head"></slot>
            </div>
            <div class="sum-content-body">
                <slot name="third-body"></slot>
            </div>
        </el-col>
        <el-col :span="showColumn > 3 ? 6 : (showColumn > 2 ? 6 : (showColumn > 1 ? 12 : 24))" :xs="24" v-if="showColumn > 0" class="mobile-col-md-6 mobile-col-sm-12 mob-bottom-2">
            <div class="sum-content-header">
                <slot name="fourth-head"></slot>
            </div>
            <div class="sum-content-body">
                <slot name="fourth-body"></slot>
            </div>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: "SummaryVideoSection",
        props: {
            showColumn: {
                type: Number,
                default: 4
            },
            sideLevelActive: [Boolean, String]
        }
    }
</script>

<style lang="less" scoped>
    .sum-content-row{
        .between-vertical-line{
            border-right: 1px solid #C9EDF8;
        }
    }

    .mini-summary {
        display: flex;
        flex-direction: column;
        .mobile-col-sm-12 {
            border-right: none !important;
            // border-bottom: 1px solid #C9EDF8;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            .sum-content-header {
                margin-top: 25px;
            }
        }
    }
</style>