<template>
    <el-container style="border: 1px solid #eee">
        <slot></slot>
    </el-container>
</template>
<style scoped lang="less">
</style>
<script>
export default {
    name: 'ThreeLevelStructure',
    data() {
        return {
            activeLevel2: true,
        }
    },
    methods: {
        setActiveLevel(item) {
            this.activeLevel2 = item;
        },
        toggleActiveLevel(item) {
            if (this.activeLevel2 == item)
                return this.activeLevel2 = false;
            this.setActiveLevel(item);
        }
    },
    components: {},
    computed: {
        asideWidth() {
            return this.asideLevel1Width + this.asideLevel2Width + 5;
        },
        asideLevel1Width() {
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (width < 300)
                return 300;
            return width / 5;
        },
        asideLevel2Width() {
            if (this.activeLevel2)
                return this.asideLevel1Width;
            return 0;
        },
        asideLevel2Display() {
            if (this.asideLevel2Width == 0)
                return 'none';
            return 'inline-block';
        },
        containerWidth() {
            var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            return width - this.asideWidth;
        }
    }
}
</script>
