<template>
    <div>
        <div v-if='desktopResolution'>
            <el-col :span="2" :xs="2" style="margin-right: -10px;" :class="{'mt-20': child}">
                <img class="img-circle"
                    :width="(child ? 30 : childFromChild ? 30 : 46)"
                    :height="(child ? 30 : childFromChild ? 30 : 46)"
                    :src="croppa(comment.user.photo_url, 60, 60)"
                    alt=""/>
            </el-col>
            <el-col :span="22" :xs="22" :class="{'mt-20': child}" class='comment-details' :style='{"margin-left": !desktopResolution ? "34px" : "10px"}'>
                <el-row>
                    <el-col :span="24" :xs="24" class="d-flex commentsInfo">
                        <div class="pr-20">
                            <span class="user-profile-link" :class="{'smaller': child}">
                                {{ comment.user.name }}
                            </span>
                        </div>
                        <div class="pr-20">
                            <p>{{ comment.time_ago }}</p>
                        </div>
                        <div class="pr-20 d-flex align-items-center">
                            <p @click="handleDelete" class="pointer">Delete</p>
                            <span class="dot" v-if="!childFromChild"></span>
                        </div>
                        <div class="pr-20">
                            <span v-if="child == true && !childFromChild" class="comment-reply-link-a pointer" @click="showReplyInput(parentcomment)">Reply</span>
                            <span v-else-if="!childFromChild" class="comment-reply-link-a pointer" @click="showReplyInput(comment)">Reply</span>
                        </div>
                    </el-col>
                    <el-col :span="24" :xs="24" class="py-5">
                        <p class="commentTxt size-14"> {{ comment.body }} </p>
                    </el-col>
                    <el-col :span="24" :xs="24" class="d-flex align-items-center pb-10 pointer" v-if="comment.children.length && !childFromChild">
                        <p class="" @click="displaySubComments(comment)">View {{comment.children.length}} Replies</p>
                        <img @click="displaySubComments(comment)" src="../../assets/img/icons/arrow.png" alt="" class="ml-5" :id="'replyIcon_'+comment.id">
                    </el-col>
                </el-row>

            </el-col>
        </div>
        <div v-else class='d-flex'>
            <!-- Smaller Screens -->
            <div>
                <img class="img-circle"
                    :width="(child ? 30 : childFromChild ? 30 : 46)"
                    :height="(child ? 30 : childFromChild ? 30 : 46)"
                    :src="croppa(comment.user.photo_url, 60, 60)"
                    alt=""/>
            </div>
            <div class='responsive-comment-section flex-columns' style="font-size: 12px">
                <div class="user-profile-link" :class="{'smaller': child}">
                    {{ comment.user.name }}
                </div>
                <div style='padding: 5px 0'>
                    <p class="commentTxt size-14"> {{ comment.body }} </p>
                    <div class="d-flex align-items-center pb-10 pointer" v-if="comment.children.length && !childFromChild">
                        <p class="" @click="displaySubComments(comment)">View {{comment.children.length}} Replies</p>
                        <img @click="displaySubComments(comment)" src="../../assets/img/icons/arrow.png" alt="" class="ml-5" :id="'replyIcon_'+comment.id">
                    </div>
                </div>
                <div class='responsive-comment-info d-flex justify-content-between'>
                    <div>
                        <p style="font-size: 12px">{{ comment.time_ago }}</p>
                    </div>
                    <div style='margin-left: 15px;'>
                        <p @click="handleDelete" class="pointer" style="font-size: 12px">Delete</p>
                        <!-- <span class="dot" v-if="!childFromChild"></span> -->
                    </div>
                    <div style='margin-left: 15px;'>
                        <p v-if="child == true && !childFromChild" class="comment-reply-link-a pointer" @click="showReplyInput(parentcomment)" style="font-size: 12px">Reply</p>
                        <p v-else-if="!childFromChild" class="comment-reply-link-a pointer" @click="showReplyInput(comment)" style="font-size: 12px">Reply</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="pull-left user-avatar" style="margin-top:50px">
            <img class="img-circle"
                 :width="(child ? 40 : 50)"
                 :height="(child ? 40 : 50)"
                 :src="croppa(comment.user.photo_url, 60, 60)"
                 alt=""/>
        </div>
        <div class="comment-inner">
            <el-row type="flex" class="comment-head clearfix" align="middle">
                <div class="pull-left online-sign"
                     data-type="presence-indicator"
                     style="margin-top: -2px;"
                     :data-user-id="comment.user.id">
                    <span :class="{ 'online': user.id == comment.user.id, 'offline': user.id != comment.user.id }"></span>
                </div>
                <el-col style="width: auto" class="username pull-left">
                    <span class="user-profile-link" :class="{'smaller': child}">
                        {{ comment.user.name }}
                    </span>
                </el-col>

                <el-col v-if="comment.video_time_formatted"
                        style="width: auto"
                        :class="{'smaller': child}"
                        class="comment-video-time pointer pull-left row-space-left-1"
                        @click="jumpToCommentTime(comment.video_time)">
                    <span>{{ comment.video_time_formatted }}</span>
                </el-col> -->

                <!-- delete -->
                <!-- <i class="row-space-left-2 delete-comment pull-left"
                     data-type="delete-comment"
                     @click="handleDelete"></i> -->

                <!-- mark as complete -->
                <!-- <el-col class="mark-as-completed pull-left row-space-left-2"
                     :class="{ 'completed': comment.is_resolved }"
                        style="width: auto"
                     @click="markAsComplete">
                    <el-tooltip class="item" effect="dark" content="Mark as completed" placement="right">
                        <i></i>
                    </el-tooltip> -->
                    <!--<span class="hidden">Mark as completed</span>-->
                <!-- </el-col>
            </el-row>

            <div class="comment-content">
                {{ comment.body }}
            </div>
            <div class="comment-footer clearfix">
                <div class="pull-left comment-time-ago row-space-right-2">
                    {{ comment.time_ago }}
                </div>
                <div class="comment-reply-link" v-if="child == true">
                    <span class="comment-reply-link-a pointer" @click="showReplyInput(parentcomment)">Reply</span>
                </div>
                <div class="comment-reply-link" v-else>
                    <span class="comment-reply-link-a pointer" @click="showReplyInput(comment)">Reply</span>
                </div>
            </div>
        </div> -->
    </div>
</template>
<script>
    export default{
        props: ['comment', 'parentcomment', 'child', 'childFromChild', 'desktopResolution'],
        data () {
            return {
                inputVisible: false,
                user: {
                    id: 1
                }
            }
        },
        methods: {
            submit () {
                // Todo: handle submit data.
                this.$emit('submit', {});
            },
            markAsComplete () {
                this.comment.is_resolved = true;
                this.$emit('resolve', this.comment);
            },
            handleDelete () {
                this.$emit('delete', this.comment);
            },
            jumpToCommentTime () {
                this.$emit('timechange', this.comment.video_time)
            },
            showReplyInput (comment) {
                this.$emit('reply', comment);
            },
            displaySubComments(com){
                com.subCommentShow = !com.subCommentShow;
                let icon = document.getElementById(`replyIcon_${this.comment.id}`);
                icon.classList.contains('rotateReplyIcon') ? icon.classList.remove('rotateReplyIcon') : icon.classList.add('rotateReplyIcon')
                
            }
        }
    }
</script>
<style lang="less" scoped>
    .pr-20{
        padding-right: 20px;
    }
    .py-5{
        padding-top: 1px;
        padding-bottom: 5px;
    }
    .pb-10{
        padding-bottom: 10px;
    }
    .ml-5{
        margin-left: 5px;
    }
    .mt-20{
        margin-top: 20px;
    }
    p{
        margin-bottom: 0;
        color: var(--color-content);
    }
    .pointer{
        cursor: pointer;
    }
    .commentsInfo{
        font-size: 14px;
        color: var(--color-content);
        font-weight: normal;

        .user-profile-link{
            text-transform: capitalize;
            font-weight: 500;
        }
        .comment-reply-link-a{
            opacity: .7;
        }
        .dot{
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--color-content);
            margin-left: 7px;
        }
    }
    .commentTxt{
        color: var(--color-content);
        text-align: justify;
    }
    .rotateReplyIcon{
        transform: rotate(180deg);
    }

    .comment-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .responsive-comment-section {
        margin-left: 15px;
        .user-profile-link,
        .responsive-comment-info {
            font-size: 14px;
            font-weight: 500;
        }
    }
</style>
