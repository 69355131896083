<template>
    <div class="relative">
        <div class="upload-restriction-dialog-3" v-if='freePack'>
            <div  class="warn-sign"><img src="../../../../static/img/exclamation.svg" alt=""></div>
            <p>Your current plan does not include Video embed in email campaigns.</p>
            <p>Upgrade today and take your email marketing to the next</p>
            <p>level by embedding your hard-to-ignore videos directly</p>
            <p>inside your email campaigns.</p>
            <DesktopIcon class='desktop-icon'></DesktopIcon>
            <el-button round class="restriction_dialog_btn" type="success" @click="showUpgradeOptions"> 
                <span style='color: #fff'>
                    See Upgrade Options 
                </span>
            </el-button>
        </div>
        <div :class='freePack ? "blurred" : ""'>
            <div class="left-content">
                <el-row class="row-space-3">
                    <el-col>
                        <p class="text-lightest row-space-1">Image size</p>
                        <el-row type="flex" :gutter="30" style="flex-wrap: wrap;">
                            <el-col style="width: auto; margin-bottom: 20px;">
                                <el-input-number v-model="width" style="width: 135px;" :step="30"></el-input-number>
                            </el-col>
                            <el-col style="width: auto;">
                                <el-input-number v-model="height" style="width: 135px;" :step="30"></el-input-number>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>

                <el-row class="row-space-3">
                    <el-col>
                        <p class="text-lightest row-space-1">Links to</p>
                        <el-input v-model="url"></el-input>
                    </el-col>
                </el-row>
            </div>
            <div v-if='!freePack' class="preview" :style="{'background-image': 'url(' + croppa(this.video.thumbnail, 200) + ')'}">
                <div class="text-center play-button"></div>
            </div>

            <el-row class="row-space-3">
                <el-col>
                    <p class="text-lightest row-space-1">
                        Embed code
                    </p>
                    <textarea spellcheck="false" id="share-embed-code-textarea" cols="30" rows="4" onclick="this.select()"
                            contenteditable="false" v-text="html" class="form-control"></textarea>
                </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <el-button data-type="copy-embed-code-to-clipboard"
                            type="primary"
                            v-clipboard:copy="html"
                            @click="copyText = 'Copied!'"
                            v-text="copyText"
                            v-if='!freePack'>
                        Copy
                    </el-button>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<style lang="less" scoped>
 .left-content {
     margin-right: 320px;
     min-height: 240px;
     max-width: 100%;
 }
 .preview {
     position: absolute;
     right: 0;
     width: 300px;
     height: 200px;
     top: 0;
     overflow: hidden;
     background-repeat: no-repeat;
     background-position: center center;
     background-size: cover;
     -webkit-background-size: cover;
 }
 .play-button {position: absolute; z-index: 2}
 .upload-restriction-dialog-3 {
    font-size: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 200px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 40;
    height: 50vw;
}
.warn-sign {
    position: relative;
    width: 60px;
    height: 60px;
    text-align: center;
    margin-bottom: 15px;
    img {
        max-width: 100%;
        object-fit: cover;
    }
}

.restriction_dialog_btn{
    border-radius: 19px;
    padding: 8px 25px;
    background-color: #F27500;
    border-color: #F27500;
    color: white !important;
    margin-top: 15px;
}

.relative {
    position: relative;
}

.blurred {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.desktop-icon {
    margin: 15px 0;
}
</style>
<script>
import DesktopIcon from '../Items/DesktopIcon';
    export default {
        props: ['video'],
        components: {
            DesktopIcon
        },
        data () {
            return {
                width: 420,
                height: 264,
                url: this.video.embed_url,
                copyText: 'Copy',
                freePack: true
            }
        },
        computed: {
            'html' () {
                return '<a href="' + this.url + '" target="_blank"><img width="' + this.width + '" height="' + this.height + '" src="' + process.env.VUE_APP_BACKEND_URL + 'thumbnail/' + this.video.video_id + '/w_' + this.width + ',h_' + this.height + '"/></a>';
            }
        },
        mounted()
        {
            this.getPlan();
        },
        methods: {
            getPlan()
            {
                this.axios.post('owner-plan').then(r => {
                    if (r && r.data) {
                        this.freePack = r.data.name == 'Free Forever';
                    }
                });
            },
            showUpgradeOptions() {
                this.$router.push({name : 'ChangeSubscriptionPlan', params: {'section': 'billing'} });
            }
        }
    }
</script>
