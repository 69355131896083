<template>
  <div class="scheduled_publishing">
    <div class='publish-restriction' v-if='packRestrict || lifetimePackRestrict'>
      <div class="warn-sign"><img src="./../../../static/img/exclamation.svg" alt=""></div>
      <p>Your current plan does not include Scheduled Publishing.</p>
      <p>If you want to build buzz, premiere your video, host watch events, create live-like feeling and more, upgrade your plan.</p>
      <el-button round class="restriction_dialog_btn" type="success" @click="showUpgradeOptions"> 
        <span style='color: #fff'>
            See Upgrade Options 
        </span>
      </el-button>
    </div>
    <div :class='packRestrict || lifetimePackRestrict ? "blurred" : ""'>
      <label class="heading" for>Scheduled Publishing</label>
      <p class="row-space-top-1">
        Are you not ready to go live yet? No problem, set your time and let your
        audience know when your video will begin streaming.
      </p>
      <div class="sch_pub">
        <div v-if='!packRestrict && !lifetimePackRestrict'>
          <el-switch
            active-color="#0AD688"
            @change="setDate('start',$event)"
            v-model="publish.is_schedule"
            class="row-space-right-1 switch-right"
          ></el-switch>Enable Scheduled Publishing
        </div>
        <div v-else>
          <el-switch
            active-color="#0AD688"
            class="row-space-right-1 switch-right"
          ></el-switch>Enable Scheduled Publishing
        </div>
      </div>
      <div v-if="publish.is_schedule">
        <div class="sch_pub-date">
          <el-row :gutter="10">
            <el-col :xs="4" :sm="6" :md="8" :lg="12" :xl="11"> 
              <el-date-picker
                v-model="publish.stream_start_date"
                @change="selecStartDate"
                type="date"
                placeholder="DATE"
              ></el-date-picker>
              <span class="date-text">Date</span>
            </el-col>

            <el-col :xs="8" :sm="6" :md="4" :lg="6" :xl="1">
              <el-select
                :class="'schedule-select'"
                v-model="publish.stream_start_hour"
                @change="selecStartHour"
                placeholder="HH"
              >
                <el-option v-for="item in 24" :key="item" :label="(item-1)" :value="(item-1)"></el-option>
              </el-select>
              <span class="date-text">Hours</span>
            </el-col>

            <el-col :xs="8" :sm="6" :md="4" :lg="6" :xl="1">
              <el-select
                :class="'schedule-select'"
                v-model="publish.stream_start_min"
                @change="selecStartMin"
                placeholder="MM"
              >
                <el-option v-for="item in 60" :key="item" :label="item-1" :value="item-1"></el-option>
              </el-select>
              <span class="date-text">Minutes</span>
            </el-col>
          </el-row>
        </div>
        <span class="utc-time">Note: time is based on UTC</span>
        <br />
        <label class="heading" style="margin-top:40px">Streaming Soon Text</label>
        <div class="sch_pub">
          <el-switch
            active-color="#0AD688"
            v-model="publish.is_stream_start_text"
            class="row-space-right-1 switch-right"
          ></el-switch>Enable Text Overlay
        </div>
        <div class="sch_pub">
          <p>Text your viewers will see if they load your video before live time</p>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="Please input"
            :disabled="!publish.is_stream_start_text"
            v-model="publish.stream_start_text"
          ></el-input>
        </div>
        <div class="sch_pub stream_setting">
          <label class="heading" style="font-size:18px;font-weight: 500;">END STREAMING SETTINGS</label>
          <div class="sch_pub">
            <el-switch
              active-color="#0AD688"
              @change="setDate('end',$event)"
              v-model="publish.is_end_stream"
              class="row-space-right-1 switch-right"
            ></el-switch>Enable End Stream
          </div>
          <p>This settings allow you to stop streaming your video after a specific date and time</p>
          <div class="sch_pub-date"> 
            <el-row :gutter="10">
              <el-col :xs="4" :sm="6" :md="8" :lg="12" :xl="11">
                <el-date-picker
                  v-model="publish.stream_end_date"
                  type="date"
                  placeholder="DATE"
                  @change="selecEndDate"
                  :disabled="!publish.is_end_stream"
                ></el-date-picker>
                <span class="date-text">Date</span>
              </el-col>

              <el-col :xs="8" :sm="6" :md="4" :lg="6" :xl="1">
                <el-select
                  :class="'schedule-select'"
                  v-model="publish.stream_end_hour"
                  @change="selecEndHour"
                  placeholder="HH"
                  :disabled="!publish.is_end_stream"
                >
                  <el-option v-for="item in 24" :key="item" :label="(item-1)" :value="(item-1)"></el-option>
                </el-select>
                <span class="date-text">Hours</span>
              </el-col>

              <el-col :xs="8" :sm="6" :md="4" :lg="6" :xl="1">
                <el-select
                  :class="'schedule-select'"
                  v-model="publish.stream_end_min"
                  @change="selecEndMin"
                  placeholder="MM"
                  :disabled="!publish.is_end_stream"
                >
                  <el-option v-for="item in 60" :key="item" :label="item-1" :value="item-1"></el-option>
                </el-select>
                <span class="date-text">Minutes</span>
              </el-col>
            </el-row>
          </div>
          <div class="sch_pub">
            <label class="heading" for>End Stream Text</label>
          </div>
          <div class="sch_pub_setting">
            <el-switch
              active-color="#0AD688"
              v-model="publish.is_stream_end_text"
              class="row-space-right-1 switch-right"
            ></el-switch>Enable Text Overlay
          </div>
          <div class="sch_pub_setting">
            <p>Text your viewers will see if they load your video after stream has ended.</p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              :disabled="!publish.is_stream_end_text"
              placeholder="Please input"
              v-model="publish.stream_end_text"
            ></el-input>
          </div>
          <div class="sch_pub_setting">
            <el-switch
              active-color="#0AD688"
              v-model="publish.is_action_button"
              class="row-space-right-1 switch-right"
            ></el-switch>Call to Action Button
          </div>
          <div class="sch_pub_setting">
            <p>Button is displayed on video after stream ends.</p>
          </div>
        </div>
        <div class="input-button">
          <el-input
            placeholder="Button Text"
            :disabled="!publish.is_action_button"
            v-model="publish.button_text"
          ></el-input>
          <p></p>
          <el-input
            placeholder="Button Link"
            :disabled="!publish.is_action_button"
            v-model="publish.button_link"
          ></el-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["video", 'packRestrict'],
  data() {
    return {
    value1: "",
    value: "",
    input: "",
    textarea2: "",
    lifetimePackRestrict: false,
    }
  },
  computed: {
      publish(){
        return this.$store.state.publish.video_Publish
      },
     
  },
  methods: {
    selecStartDate(e){
       
      this.$store.commit('updateDate',{value:e,type:'start'})
    },
    selecEndDate(e){
      this.$store.commit('updateDate',{value:e,type:'end'})
    },
    selecStartHour(e){
      this.$store.commit('updateHour',{value:e,type:'start'})
    },
    selecEndHour(e){
      this.$store.commit('updateHour',{value:e,type:'end'})
    },
    selecStartMin(e){
      this.$store.commit('updateMin',{value:e,type:'start'})
    },
    selecEndMin(e){
      this.$store.commit('updateMin',{value:e,type:'end'})
    },
    selecDate(e) {
      // let sDate = new Date(e);
      // this.video.schedular[
      //   `stream_${type}_date`
      // ] = `${sDate.getFullYear()} ${sDate.getMonth()} ${sDate.getDate()}`;
    },
    setDate(type, e) {
     /* if (e) {
        if (
          !this.video.schedular[`stream_${type}_date`] ||
          this.video.schedular[`stream_${type}_date`] == ""
        ) {
          console.log(this.video.schedular[`stream_${type}_date`]);
          let date = new Date();
          if (type == "end") {
            date.setDate(date.getDate() + 1);
            this.video.schedular[`stream_${type}_date`] = date;
            this.video.schedular[`stream_${type}_hour`] = date.getHours();
            this.video.schedular[`stream_${type}_min`] = date.getMinutes();
          } else {
            this.video.schedular[`stream_${type}_date`] = date;
            this.video.schedular[`stream_${type}_hour`] = date.getHours();
            this.video.schedular[`stream_${type}_min`] = date.getMinutes();
          }
        }
        this.$store.commit('updatePublishStatus',{type:'is_schedule',value:true})

      } else {
        this.video.schedular={}
        this.video.schedular[`stream_${type}_date`] = "";
        this.video.schedular[`stream_${type}_hour`] = "";
        this.video.schedular[`stream_${type}_min`] = "";
        this.$store.commit('updatePublishStatus',{type:'is_schedule',value:false})

      }*/
    },
    showUpgradeOptions() {
      this.$router.push({name : 'ChangeSubscriptionPlan', params: {'section': 'billing'} });
    },
    getLifeTimePlanDetails()
    {
      this.loading = true
      this.axios.get('restrictions/index').then(response => {
        this.loading = false;
        if (response.data.lifetime) {
          var modules = response.data.lifetime;
          if (modules['sub_modules'].includes('scheduled_streaming')) {
            this.lifetimePackRestrict = true;
          } else {
            this.lifetimePackRestrict = false;
          }
        }
      }).catch(error => {
        console.log(error);
        this.loading = true;
      })
    }
  },
  created() {
    this.video.schedular = this.video.schedular ? this.video.schedular : {};
    this.$store.commit('setPublishVideo',this.video.schedular );
    this.getLifeTimePlanDetails();

if (this.video.schedular) {
      if (this.video.schedular.is_schedule == "1")
        this.video.schedular.is_schedule = true;
      if (this.video.schedular.is_schedule == "0")
        this.video.schedular.is_schedule = false;
      if (this.video.schedular.is_stream_start_text == "1")
        this.video.schedular.is_stream_start_text = true;
      if (this.video.schedular.is_stream_end_text == "1")
        this.video.schedular.is_stream_end_text = true;
      if (this.video.schedular.is_end_stream == "1")
        this.video.schedular.is_end_stream = true;
      if (this.video.schedular.is_action_button == "1")
        this.video.schedular.is_action_button = true;
    } else {
     /* this.video.schedular.is_schedule = 0;
      this.video.schedular.stream_start_date = new Date();
      this.video.schedular.stream_start_hour = 0;
      this.video.schedular.is_end_stream = 0;
      this.video.schedular.stream_start_min = 0;
      this.video.schedular.is_stream_start_text = 0;
      this.video.schedular.stream_end_date = new Date();
      this.video.schedular.stream_end_hour = 0;
      this.video.schedular.stream_end_min = 0;
      this.video.schedular.is_action_button = 0;
      this.video.schedular.is_stream_end_text = "";
      this.video.schedular.stream_end_text = "";
      this.video.schedular.button_text = "";
      this.video.schedular.button_link = "";*/
    }

    /*  this.video.schedular.is_stream_start_text =
      this.video.schedular.is_stream_start_text &&
      this.video.schedular.is_stream_start_text == "1"
        ? true
        : false;
    this.video.schedular.is_stream_end_text =
      this.video.schedular.is_stream_end_text &&
      this.video.schedular.is_stream_end_text == "1"
        ? true
        : false;
    this.video.schedular.is_end_stream =
      this.video.schedular.is_end_stream &&
      this.video.schedular.is_end_stream == "1"
        ? true
        : false;
    this.video.schedular.is_action_button =
      (this.video.schedular.is_action_button &&
      this.video.schedular.is_action_button == "1")
        ? true
        : false;*/
  }
};
</script>
<style lang="less">
.scheduled_publishing {
  .heading {
    margin-bottom: 9px;
  }
  p {
    line-height: 1.7;
  }
  .switch-right {
    margin-right: 30px;
  }
  .sch_pub_setting {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .sch_pub {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .el-date-editor.el-input {
    width: 100% !important;
  }
  .sch_pub-date {
    padding: 14px;
    background: #f5f6f9 0% 0% no-repeat padding-box;
    i {
      display: none;
    }
    input.el-input__inner::placeholder {
      color: #333;
      font-weight: 700;
    }
    input {
      border-radius: 0px !important;
    }
  }
  .utc-time {
    font-size: 12px;
    color: #6789a0;
  }
  .el-textarea__inner {
    border: 1px solid rgb(0, 172, 220);
  }
  .stream_setting {
    margin-top: 40px;
  }
  .date-text {
    font-size: 10px;
    width: 100%;
    text-align: center;
    display: inline-block;
  }
}
.vjs-big-play-button{
  display: none;
}

.publish-restriction {
		font-size: 14px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
		p{
			color: #21455E;
		}
		.warn-sign {
			position: relative;
			width: 60px;
			height: 60px;
			text-align: center;
			margin-bottom: 15px;
			img {
				max-width: 100%;
				object-fit: cover;
			}
		}
		.content_restriction{
			max-width: 575px;
			display: flex;
			align-items: center;
			flex-direction: column;
		}
		.restriction_dialog_btn{
			border-radius: 19px;
			padding: 8px 25px;
			background-color: #F27500;
			border-color: #F27500;
			color: white;
		}
  }
  
  .blurred {
    filter: blur(8px);
    -webkit-filter: blur(8px);
  }

  .restriction_dialog_btn{
    border-radius: 19px;
    padding: 8px 25px;
    background-color: #F27500;
    border-color: #F27500;
    color: white !important;
    margin-top: 15px;
}
</style>
