<template>
    <div id="comments-wrap">

        <div class="d-flex justify-content-between align-items-center" id="comments-heading">
            <span :style="{'font-size': !desktopResolution ? '12px' : ''}" class="header-comments">Comments <span style="font-weight: 400">{{comments.length}}</span></span>
            <span class="header-sm-comments">Comment Settings</span>
        </div>

        <div id="comment-main-input" class="row-space-top-5">
            <div class="limitedComments d-flex align-items-center" v-if="video.player_options.commenting_permissions == 'collaborators'">
                <img src="../../assets/img/icons/warning.png" alt="">
                <p>Comments on this video is limited to Project Collaborators</p>
            </div>

            <div class="row-space-2" style="margin-top:20px">
                <img :src="user.photo_url" :alt="user.full_name" class="img-circle profile-avatar row-space-right-1" width="46">
                <span class="userName">{{user.full_name}}</span>
            </div>

            <textarea name="comment" id="comment" style="margin-top:17px" cols="10" :rows="(formVisible ? 3 : 1)"
                      placeholder="Enter your comment"
                      v-model="comment" class="form-control" @click="formVisible = true"
                      @keyup="pauseVideo"
                      :class="{'collapsed': formVisible === false}"></textarea>

            <div v-show="formVisible" class="row-space-top-1 clearfix" style="width:100%;margin-top: 20px;margin-bottom: 20px;">
                <!-- pause video -->
                <div class="pull-left d-flex flex-wrap justify-content-between" :style="{'width': desktopResolution ? '50%': '100%'}"> 
                    <div class="d-flex align-items-center" :style="{'font-size': !desktopResolution ? '12px' : ''}">
                    <input class="checkbox mt-0"
                           type="checkbox"
                           checked
                           v-model="pauseVideoWhileTyping"
                           id="pauseVideoWhileCommenting">
                    <label for="pauseVideoWhileCommenting">
                        <span></span>
                    </label>
                    <label for="pauseVideoWhileCommenting"
                           class="normal inline-block mb-0"
                           style="margin-left: 4px; word-break:break-word">
                        Pause video while typing
                    </label>
                </div>
                    <div class="d-flex align-items-center" :style="{'font-size': !desktopResolution ? '12px' : ''}">
                    <input class="checkbox mt-0"
                           type="checkbox"
                           checked
                           v-model="commentWithVideoTime"
                           id="commentVideoTime">
                    <label for="commentVideoTime">
                        <span></span>
                    </label>
                    <label for="commentVideoTime"
                           class="normal inline-block thicker mb-0"
                           style="margin-left: 4px; word-break:break-word"
                           id="commentVideoTimeValue">
                        {{ toHHMMSS(currentTime) }}
                        Include timestamp
                    </label>

                    </div>
                </div>
                <!-- video time -->
     
                <div class="pull-right" :class="!desktopResolution ? 'd-flex' : ''">
                    <el-button size="large" type="gray" class="btn btn-info" :loading="loading" @click="formVisible=false" :style="{'font-size': !desktopResolution ? '12px' : ''}">Cancel</el-button>
                    <el-button size="large" class="btn btn-primary" :loading="loading" @click="submit" :style="{'font-size': !desktopResolution ? '12px' : ''}">Comment</el-button>
                </div>
            </div>
        </div>

        <!-- comments -->
        <div class="row-space-top-4" :style="{'font-size': !desktopResolution ? '12px' : ''}">

            <div v-if="loaded">
                <ul v-if="comments.length > 0" id="comments" class="no-styling">

                    <li v-for="(comment, index) in comments"
                        :key="index"
                        class="comment clearfix"
                        :class="'comment-' + comment.id"
                        :data-id="comment.id">

                        <editor-comment :comment="comment"
                                        v-on:timechange="handleTimeChange"
                                        v-on:resolve="handleResolve"
                                        v-on:reply="handleOpenReplyInput"
                                        v-on:delete="handleDelete"
                                        :desktopResolution='desktopResolution'
                                        ></editor-comment>

                        <ul v-if="comment.children" v-show="comment.subCommentShow"
                            class="sub-comments" :style='{"margin-left": !desktopResolution ? "10px" : ""}'>
                            <li v-for="(child, childIndex) in comment.children"
                                :key="childIndex"
                                class="comment clearfix"
                                :class="'comment-' + comment.id"
                                :data-id="comment.id">

                                <editor-comment :comment="child"
                                                :parentcomment="child"
                                                :child="true"
                                                v-on:timechange="handleTimeChange"
                                                v-on:resolve="handleResolve"
                                                v-on:reply="handleOpenReplyInput"
                                                v-on:delete="handleDelete"
                                                :desktopResolution="desktopResolution"></editor-comment>

                                    <ul v-if="child.children" v-show="child.subCommentShow"
                                        class="sub-comments">
                                        <li v-for="(ch, childIndex) in child.children"
                                            :key="childIndex"
                                            class="comment clearfix"
                                            :class="'comment-' + ch.id"
                                            :data-id="ch.id">

                                            <editor-comment :comment="ch"
                                                            :parentcomment="child"
                                                            :childFromChild="true"
                                                            v-on:timechange="handleTimeChange"
                                                            v-on:resolve="handleResolve"
                                                            v-on:reply="handleOpenReplyInput"
                                                            v-on:delete="handleDelete"></editor-comment>
                                        </li>
                                    </ul>

                                    <el-col :span="22" :xs="22" v-show="child.showReplyInput"
                                        class="comment-reply row-space-top-5"
                                        :class="'comment-' + child.id"
                                        :id="'comment-reply-' + child.id"
                                        :style='{"margin-left": !desktopResolution ? "10px" : ""}'>
                                        <div class="comment-reply-avatar row-space-2">
                                            <img class="img-circle"
                                                width="46"
                                                :src="croppa(user.photo_url, 60, 60)" alt=""/>
                                            <span class="userName">{{user.full_name}}</span>
                                        </div>
                                        <textarea name="comment" :id="'comment-reply-input-' + child.id" style="margin-top:17px; margin-lefy:-10px;" cols="10" :rows="(formVisible1 ? 3 : 1)" placeholder="Enter your comment" v-model="commentReplies[child.id]" class="form-control" @click="formVisible1 = true" @keyup.enter="submitReply(child)"></textarea>
                                        <div v-show="formVisible1" class="row-space-top-1 clearfix" style="width:100%;margin-top: 20px;margin-bottom: 20px;">
                                            <div class="pull-right" :class="!desktopResolution ? 'd-flex' : ''">
                                                <el-button size="large" type="gray" class="btn btn-info" :loading="loading" @click="child.showReplyInput=false;formVisible1=false">Cancel</el-button>
                                                <el-button size="large" class="btn btn-primary" :loading="loading" :data-parent-id="child.id" @click.prevent="submitReply(child)" data-type="submit-comment-reply">Comment</el-button>
                                            </div>
                                        </div>
                                    </el-col>
                            </li>
                        </ul>

                        <el-col :span="22" :xs="22" v-show="comment.showReplyInput"
                             class="comment-reply row-space-top-5"
                             :class="'comment-' + comment.id"
                             :id="'comment-reply-' + comment.id"
                            :style='{"margin-left": !desktopResolution ? "10px" : ""}'>
                            <div class="comment-reply-avatar row-space-2">
                                <img class="img-circle"
                                     width="46"
                                     :src="croppa(user.photo_url, 60, 60)" alt=""/>
                                <span class="userName">{{user.full_name}}</span>
                            </div>
                            <textarea name="comment" :id="'comment-reply-input-' + comment.id" style="margin-top:17px" cols="10" :rows="(formVisible2 ? 3 : 1)" placeholder="Enter your comment" v-model="commentReplies[comment.id]" class="form-control" @click="formVisible2 = true" @keyup.enter="submitReply(comment)"></textarea>
                            <div v-show="formVisible2" class="row-space-top-1 clearfix" style="width:100%;margin-top: 20px;margin-bottom: 20px;">
                                <div class="pull-right" :class="!desktopResolution ? 'd-flex' : ''">
                                    <el-button size="large" type="gray" class="btn btn-info" :loading="loading" @click="comment.showReplyInput=false;formVisible2=false">Cancel</el-button>
                                    <el-button size="large" class="btn btn-primary" :loading="loading" :data-parent-id="comment.id" @click.prevent="submitReply(comment)" data-type="submit-comment-reply">Comment</el-button>
                                </div>
                            </div>
                        </el-col>
                    </li>
                </ul>
                <div v-else style="padding: 0 15px">
                    There are no comments on this video yet.
                </div>
            </div>
            <div v-else class="text-center row-space-inner-top-3">
                <i class="el-icon-loading" style="font-size: 26px"></i>
            </div>

        </div>
    </div>
</template>
<style lang="less">
    #comments-wrap {
        background: #fff;
        padding: 20px 40px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
    }

    #comments-heading {
        color: var(--color-content);
        font-weight: 600;
        border-bottom: 1px solid #C9EDF8;
        margin: 0 -40px;
        padding: 0 35px 20px 35px;
        .header-comments{
            font-size: 20px;
            font-weight: 500;
        }
        .header-sm-comments{
            font-size: 14px;
            font-weight: normal;
            color: var(--color-sm-header);
        }
    }

    #comments {
        border-top: 1px solid #C9EDF8;
        margin: 20px 0;
        padding-left: 0;
        list-style: none;
    }

    #comment-main-input {
        .limitedComments{
            padding: 5px 40px 5px 10px;
            width: fit-content;
            border-radius: 17px;
            background-color: rgba(242, 117, 0, .04);
            margin: 15px 0;
            p{
                color: #F27500;
                margin: 0 0 0 5px;
                font-size: 16px;
            }
        }
        .userName{
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
            color: var(--color-content);
            padding-left: 14px;
        }
        textarea{
            height: auto !important;
            &::placeholder{
                font-size: 13px;
                color: #98A4B3
            }
        }
        
    }

    #comment-main-input textarea {
        font-size: 16px;
        box-shadow: 0 0 0 0 #FFFFFF;
        padding: 15px 15px;
        resize: none;
        border-color: #DFF5FC;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        &:active, &:focus {
            border: 1px solid #00ACDC;
        }
    }

    #comment-main-input textarea::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #8A999E;
    }

    #comment-main-input textarea:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #8A999E;
        opacity: 1;
    }

    #comment-main-input textarea::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #8A999E;
        opacity: 1;
    }

    #comment-main-input textarea:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #8A999E;
    }

    #comments .comment {
        margin-top: 25px;
        margin-bottom: 0px;
    }

    #comments .comment .user-avatar {
        width: 60px;
    }

    .sub-comments .user-avatar {
        width: 40px !important;
    }

    #comments .comment .comment-inner {
        margin-left: 60px;
        margin-top: 3px;
    }

    .sub-comments .comment-inner {
        margin-left: 50px !important;
    }

    #comments .comment .comment-inner .comment-head {
        height: 25px;
    }

    #comments .comment .comment-inner .comment-head .online-sign .online {
        background-color: #6ABD23;
    }

    #comments .comment .comment-inner .comment-head .online-sign .offline {
        background-color: #636363;
    }

    #comments .comment .comment-inner .comment-head .online-sign .online,
    #comments .comment .comment-inner .comment-head .online-sign .offline {
        border-radius: 100px;
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-top: 0;
    }

    #comments .comment .comment-inner .comment-head .username .user-profile-link {
        color: #363636;
        margin-left: 5px;
    }

    #comments .user-profile-link.smaller {
        font-size: 15px;
    }

    #comments .comment-video-time.smaller {
        font-size: 15px !important;
        margin-top: 0 !important;
    }

    #comments .comment .comment-inner .comment-head .comment-video-time {
        background-color: #E4ECEF;
        border-radius: 5px;
        padding: 2px 8px;
        margin-top: -3px;
    }

    #comments .comment .comment-inner .comment-head .comment-video-time span {
        font-weight: bold;
    }

    #comments .delete-comment {
        width: 11px;
        height: 16.5px;
        background-repeat: no-repeat;
        background-image: url(../../assets/img/Sprites.svg);
        background-position: -130.5px 0;
        cursor: pointer;
        opacity: 0.7;
    }

    #comments .delete-comment:hover {
        opacity: 1;
    }

    #comments .comment .comment-inner .comment-head .mark-as-completed {
        /*width: 150px;*/
        height: 20px;
        cursor: pointer;
        position: relative;
    }

    #comments .comment .comment-inner .comment-head .mark-as-completed i {
        display: inline-block;
        width: 12px;
        height: 9px;
        background-repeat: no-repeat;
        background-image: url(../../assets/img/Sprites.svg);
        background-position: -39px 0;
        margin-right: 3px;
        margin-top: 5px;
        float: left;
    }

    #comments .comment .comment-inner .comment-head .mark-as-completed .hidden {
        padding-top: 2px;
    }

    /*#comments .comment .comment-inner .comment-head .mark-as-completed:hover i::after {
        content: 'Mark as completed';
        white-space: nowrap;
        margin-top: -8px;
        display: inline-block;
        position: absolute;
        margin-left: 16px;
        font-style: initial;
    }*/

    #comments .comment .comment-inner .comment-head .mark-as-completed.completed:hover i::after {
        content: '' !important;
    }

    #comments .comment .comment-inner .comment-head .mark-as-completed:hover i {
        width: 12px;
        height: 9px;
        background-repeat: no-repeat;
        background-image: url(../../assets/img/Sprites.svg);
        background-position: -51px 0;
    }

    #comments .comment .comment-inner .comment-head .mark-as-completed:hover .hidden {
        display: inline-block !important;
        visibility: visible !important;
        margin-top: -7px !important;
    }

    #comments .comment .comment-inner .comment-head .mark-as-completed.completed:hover {
        cursor: default !important;
    }

    #comments .comment .comment-inner .comment-head .mark-as-completed.completed:hover .hidden {
        display: none !important;
        visibility: hidden !important;
    }

    #comments .comment .comment-inner .comment-head .mark-as-completed.completed i {
        width: 12px;
        height: 9px;
        background-repeat: no-repeat;
        background-image: url(../../assets/img/Sprites.svg);
        background-position: -27px 0;
    }

    #comments .comment .comment-inner .comment-content,
    #comments .comment .comment-inner .comment-footer {
        /** fix online button */
        margin-left: 14px;
    }

    #comments .comment .comment-inner .comment-content {
        color: #9099A0;
    }

    #comments .comment .comment-inner .comment-footer {
        margin-top: 6px;
    }

    #comments .comment .comment-inner .comment-footer .comment-time-ago {
        color: #DEE3E7;
    }

    #comments .comment .comment-inner .comment-footer .comment-reply-link-a {
        color: #BBC1C1;
    }

    #comments .comment .comment-inner .comment-footer .comment-reply-link-a:hover {
        text-decoration: underline;
    }

    #comments .comment .comment-reply {
        margin-left: 81px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    #comments .comment .comment-reply .comment-reply-avatar {
        margin-left: -10px;
        margin-top: 15px;
    }

    #comments .comment .comment-reply .comment-reply-action {
        margin-right: 120px;
        margin-left: 50px;
    }

    #comments .comment .comment-reply .comment-reply-action .comment-reply-input {
        border: none;
        box-shadow: 0 0 0 0 #FFFFFF;
    }

    #comments .comment .comment-reply .comment-reply-submit {
        margin-top: -3px;
        text-align: left;
        input {
            float: left;
        }
        button {
            float: right;
        }
    }

    #comments .comment .comment-reply .comment-reply-submit button {
        padding: 10px 20px;
        letter-spacing: 2px;
        font-size: 13px;
    }

    #comments .sub-comments {
        margin-left: 70px;
        padding: 0;
        list-style: none;
    }

    #comments .sub-comments .comment {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    #comments .sub-comments .comment:last-of-type {
        margin-bottom: 10px;
    }
    .mt-0{
        margin-top: 0 !important;
    }
    .mb-0{
        margin-bottom: 0 !important;
    }
    .pull-right button{
        height: 36px;
        font-size: 16px;
    }
    .userName{
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
        color: var(--color-content);
        padding-left: 14px;
    }
</style>
<script>
    import EditorComment from './EditorComment.vue';

    export default {
        name: 'EditorComments',
        props: ['video', 'currentTime', 'user', 'desktopResolution'],
        data () {
            return {
                comments: {},
                formVisible: false,
                formVisible1: false,
                formVisible2: false,
                pauseVideoWhileTyping: true,
                commentWithVideoTime: true,
                comment: '',
                commentReplies: {},
                loading: false,
                loaded: false
            }
        },
        components: {
            EditorComment
        },
        mounted () {
            this.load();
        },
        watch: {
            'video' () {
                this.load();
            }
        },
        methods: {
            load () {
                var $this = this;
                // Loaded
                this.axios({
                    url: 'editor/' + this.video.id + '/comments',
                    method: 'GET'
                }).then(function (response) {
                    var comments = response.data;
                    comments = comments.map(function (comment) {
                        comment.showReplyInput = false;
                        comment.subCommentShow = false;
                        
                        comment.children.map((child)=>{
                            child.showReplyInput = false;
                            child.subCommentShow = false;
                        });

                        return comment;
                    });
                    $this.comments = comments;
                    $this.video.comments = response.data;
                    $this.loaded = true;
                }).catch(function (response) {
                    $this.$message({
                        showClose: true,
                        message: response.message,
                        type: 'error'
                    });
                    $this.loaded = true;
                });
            },
            toHHMMSS (duration) {
                var secNum = parseInt(duration, 10); // don't forget the second param
                var hours = Math.floor(secNum / 3600);
                var minutes = Math.floor((secNum - (hours * 3600)) / 60);
                var seconds = secNum - (hours * 3600) - (minutes * 60);

                if (hours < 10) {
                    hours = "" + hours;
                }
                if (minutes < 10 && hours > 0) {
                    minutes = "0" + minutes;
                }
                if (seconds < 10) {
                    seconds = "0" + seconds;
                }
                return (hours > 0 ? hours + ':' : '') + minutes + ':' + seconds;
            },
            pauseVideo () {
                // If video is playing and pause is activated, pause the video
                if (this.pauseVideoWhileTyping) {
                    this.$emit('pause-video');
                }
            },
            handleTimeChange (time) {
                this.$emit('timechange', time);
            },
            submit () {
                this.create(this.comment);
            },
            handleOpenReplyInput (comment) {
                comment.showReplyInput = !comment.showReplyInput;
                window.setTimeout(function () {
                    document.getElementById('comment-reply-input-' + comment.id).focus();
                }, 0);
            },
            submitReply (parentComment) {
                // When a comment gets replied on
                this.create(this.commentReplies[parentComment.id], parentComment);
                this.commentReplies[parentComment.id] = ''
            },
            create (comment, parentComment = false) {
                var $this = this;
                var parentId = 0;
                var videoTime = $this.currentTime;
                if (parentComment) {
                    parentId = parentComment.id;
                }
                if (this.commentWithVideoTime === false) {
                    // extraordinary absolute value for 'do not set time'
                    videoTime = 91919191919191919191919191
                }

                this.loading = true;

                // Send post request
                this.axios({
                    url: 'editor/' + $this.video.id + '/comments',
                    method: 'POST',
                    data: {
                        id: $this.video.id,
			// project id as title
			title: $this.video.project.id,
                        body: comment,
                        parent_id: parentId,
                        video_time: videoTime,
                        returnHtml: false
                    }
                }).then(function (response) {
                    if (parentComment === false) {
                        $this.comments.push(response.data);
                        // todo: scroll to bottom
                    } else {
                        parentComment.children.push(response.data);
                    }
                    $this.$message({
                        showClose: true,
                        message: 'Your comment has been submitted.',
                        type: 'success'
                    });
                    $this.comment = '';
                    $this.formVisible = false;
                    $this.loading = false;
                }).catch(function (response) {
                    $this.$message({
                        showClose: true,
                        message: response.message,
                        type: 'error'
                    });
                    $this.loading = false;
                });
            },
            handleResolve (comment) {
                // A comment was resolved
                var $this = this;
                this.axios({
                    url: 'editor/' + this.video.id + '/comments/' + comment.id,
                    method: 'POST',
                    data: {
                        mark_as_resolved: true
                    }
                }).then(function (response) {
                    comment.is_resolved = true;
                    $this.$message({
                        showClose: true,
                        message: 'Comment was marked as resolved',
                        type: 'success'
                    });
                }).catch(function (response) {
                    $this.$message({
                        showClose: true,
                        message: response.message,
                        type: 'error'
                    });
                });
            },
            handleDelete (comment) {
                if (comment.parent_id !== null) {
                    // This is a child comment and has a parent comment.
                    var parentComment = this.comments.filter(function (parentComment) {
                        return (parentComment.id === comment.parent_id);
                    }).shift();

                   let parentCommentChild = this.comments.map(child => {
                       var x = child.children.filter((ch)=>{
                            return (ch.id === comment.parent_id)
                        }).shift();
                        return x
                    })

                    parentCommentChild = parentCommentChild.filter((parent)=>{
                        return parent !== undefined
                    })

                    let index = parentCommentChild[0] ? parentCommentChild[0].children.indexOf(comment)  : parentComment.children.indexOf(comment);
                    parentCommentChild[0] ? parentCommentChild[0].children.splice(index, 1)  : parentComment.children.splice(index, 1);

                } else {
                    let index = this.comments.indexOf(comment);
                    this.comments.splice(index, 1);
                }

                var $this = this;

                this.axios({
                    url: 'editor/' + this.video.id + '/comments/' + comment.id + '/delete',
                    method: 'DELETE'
                }).then(function (response) {
                    $this.$message({
                        showClose: true,
                        message: 'This comment has been deleted',
                        type: 'success'
                    });
                }).catch(function (response) {
                    $this.$message({
                        showClose: true,
                        message: response.message,
                        type: 'error'
                    });
                });
            }
        }
    }
</script>
