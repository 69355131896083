<template>
     
        <div class="sum-number" v-if="timeShow">{{ getDurF(filterValTotal) }}</div>
        <div class="sum-number " v-else>
                {{ formatNumber(filterValTotal) }}
                
            </div>
   
     
</template>

<script>
import { SUM_BODY_NUMBER_REG } from "../../../constants";

export default {
    name: "VideoSummaryBody",
    props: {
        filterValCurr: Number,
        filterValTotal: Number,
        filterValToday: Number,
        filterValPrev: {
            type: Number,
            default: 0
        },
        filterValYesterday: {
            type: Number,
            default: 0
        },
        dateLabel: String,
        showPercent: {
            type: Boolean,
            default: true
        },
        summaryUnit: {
            type: String,
            default: ""
        },
        timeShow: {
            type: Boolean,
            default: false
        },
        subShow: {
            type: Boolean,
            default: false
        },
        subParent: {
            type: Object,
            default: null
        }
    },
    data: function() {
        return {
            increaseProYesterday: false,
            decreaseProYesterday: false,
            normalProYesterday: false,
            increaseProPrev: false,
            decreaseProPrev: false,
            normalProPrev: false
        };
    },
    mounted() {
        this.increaseProYesterday =
            100 -
                this.getInDeStatus(
                    this.filterValYesterday,
                    this.filterValToday
                ) <
            0;

        this.decreaseProYesterday =
            100 -
                this.getInDeStatus(
                    this.filterValYesterday,
                    this.filterValToday
                ) >
                0 &&
            (this.filterValYesterday !== 0 || this.filterValToday !== 0);

        this.normalProYesterday =
            this.filterValYesterday === 0 || this.filterValToday === 0;

        this.increaseProPrev =
            100 - this.getInDeStatus(this.filterValPrev, this.filterValCurr) <
                0 ||
            this.getInDeStatus(this.filterValPrev, this.filterValCurr) === 100;

        this.decreaseProPrev =
            100 - this.getInDeStatus(this.filterValPrev, this.filterValCurr) >
                0 &&
            (this.filterValPrev !== 0 || this.filterValCurr !== 0);

        this.normalProPrev =
            100 - this.getInDeStatus(this.filterValPrev, this.filterValCurr) ===
                0 &&
            (this.filterValPrev === 0 || this.filterValCurr === 0);
    },
    methods: {
        /**
         * percentage calc
         * @param p - parent
         * @param c - child
         * @returns {number}
         */
        getInDeStatus(p, c) {
            if (p === undefined || p === 0) {
                if (c === undefined || c === 0) {
                    return 0;
                } else {
                    return 100;
                }
            }

            return Math.round((c / p) * 100 * 100) / 100;
        },
        formatNumber(num) {
            return num.toString().replace(SUM_BODY_NUMBER_REG, "$1,");
        },
        getDurF(s) {
            let d = new Date(s * 60 * 1000);
            let secs = d.getUTCSeconds();
            let minutes = d.getUTCMinutes();

            if (d.getUTCHours() > 0) {
                return (
                    d.getUTCHours() +
                    ":" +
                    (minutes >= 10 ? "" : "0") +
                    minutes +
                    ":" +
                    (secs >= 10 ? "" : "0") +
                    secs
                );
            } else {
                return (
                    (minutes >= 10 ? "" : "0") +
                    minutes +
                    ":" +
                    (secs >= 10 ? "" : "0") +
                    secs
                );
            }
        }
    }
};
</script>

<style lang="less" scoped>
.summary-body {
    display: flex;
    align-items: center;
    min-height: 104px;

    div {
        width: 50%;
        font-size: 13px;
        text-align: left;

        &.text-center {
            text-align: center;
        }

        &.w30 {
            width: 30%;
        }

        div {
            width: fit-content;

            &.sum-number {
                font-size: 20px;
                margin-bottom: 5px;

                .sub-value {
                    font-size: 12px;
                    cursor: pointer;
                }
            }

            &.increase-pro,
            &.decrease-pro,
            &.normal-pro {
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .arrow-margin {
                    margin-right: 5px;
                }
            }

            &.increase-pro {
                color: #0ad688;
            }

            &.decrease-pro {
                color: var(--color-red);
            }
        }
    }
}
</style>
