<template>
  <el-col :span="24" class="video-hero video-top-desc">
    <el-row type="flex">
      <el-col :span="12" class="d-flex align-items-center">
        <div class="title" :title="title" v-show="edit == false" style="width:100%">
          <span>{{ video.title }}</span>
          <img
            @click="toggleInput"
            alt="edit"
            src="../../assets/img/icons/edit.png"
            class="editIcon"
            style="background-color: #fff !important; border-radius: 50%; padding: 0;"
          />
        </div>
        <div class="el-input el-input--large" v-show="edit == true">
          <input
            class="el-input__inner"
            id="video-title-input"
            size="large"
            type="text"
            rows="2"
            v-model="title"
            ref="videoTitleInput"
            :disabled="saving"
            @focusout="save"
            @keydown.enter="save"
          />
        </div>
      </el-col>

      <el-col
        :span="12"
        class="fix-10-top d-flex align-items-center"
        style="text-align:right;justify-content: flex-end;"
      >
        <div>
          <el-dropdown
            @command="handleCommand"
            trigger="click"
            class="dropdown-container"
            menu-align="end"
            :placement="desktopResolution ? 'bottom' : 'left'"
          >
            <span class="el-dropdown-link"></span>
            <el-dropdown-menu
              slot="dropdown"
              class="video-settings-dropdown custom_dropdown dropdown_bottom_200"
              :class='!desktopResolution ? "rightArrow" : ""'
            >
              <el-dropdown-item command="copyVideoLink">Copy {{video.is_audio == 0 ? 'Video' : 'Audio'}} Link</el-dropdown-item>
              <li class="divider"></li>
              <el-dropdown-item command="editTitleAndDescription">Edit Title & Description</el-dropdown-item>
              <el-dropdown-item command="copySettings" v-if="video.power_player == 0">Copy Settings</el-dropdown-item>
              <li class="divider"></li>
              <el-dropdown-item command="copyToProject" v-if="video.power_player == 0">Copy To Project</el-dropdown-item>
              <el-dropdown-item command="moveToProject" v-if="video.power_player == 0">Move To Project</el-dropdown-item>
              <el-dropdown-item command="duplicateVideo">Duplicate</el-dropdown-item>
              <el-dropdown-item command="downloadVideo" v-if="video.power_player == 0">Download</el-dropdown-item>
              <li class="divider"></li>
              <el-dropdown-item command="replaceVideo" v-if="video.power_player == 0">Replace {{video.is_audio == 0 ? 'video' : 'audio'}}</el-dropdown-item>
              <el-dropdown-item command="deleteVideo" class="delete" style="color:red">Delete</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="fix-10-top">
          <button
            v-if='desktopResolution'
            class="btn btn-primary"
            style="margin-top: 20px;margin-left: 10px; background-color: #fff !important;"
            @click="handleCommand('openShareAndEmbed')"
          >Share & Embed</button>
        </div>
      </el-col>
    </el-row>

    <!-- updated at -->
    <el-row type="flex" style="margin-bottom: 10px">
      <div class="linkSection">
        <img
          src="../../assets/img/icons/link.png"
          alt="link"
          @click="handleCommand('openShareAndEmbed')"
          width="25px"
          class="linkIcon"
        />
        <a
          class="text-lightest video-link"
          :href="video.embed_url"
          target="_blank"
        >{{ video.embed_url }}</a>
      </div>

      <!-- <p class="smaller text-lightest meta-info">
                <span :title="video.updated_at | moment('d.m.Y H:m')">Updated {{ video.date_formatted }}</span>
                &nbsp;&middot;&nbsp;
                <a class="text-lightest video-link" :href="video.embed_url" target="_blank">{{ video.embed_url | truncate(30) }}</a> &middot; <i style="color: #bcc7ce" class="el-icon-share pointer" @click="handleCommand('openShareAndEmbed')"></i>
      </p>-->
    </el-row>
  </el-col>
</template>
<style lang="less">
  .video-top-desc{
    @media(max-width: 1024px){
      margin-top:20px !important;
    }
  }
.custom_dropdown.dropdown_bottom_200 {
  width: 200px !important;
}
.custom_dropdown.dropdown_bottom_200:before {
  top: -7px;
  left: 93px;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.custom_dropdown:before {
  content: "";
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  transform: rotate(45deg);
  border: 1px solid var(--color-blue);
  background-color: #fff;
  border-radius: 3px;
}

.rightArrow:before {

    content: "";
    display: block;
    position: absolute;
    width: 16px !important;
    height: 16px !important;
    box-sizing: border-box;
    transform: rotate(132deg);
    border: 1px solid var(--color-blue);
    background-color: #fff;
    border-radius: 3px;
    left: 25px !important;
    margin-left: 91px !important;
    margin-top: 95px !important;

}
.custom_dropdown {
  list-style: none;
  border-radius: 6px;
  background: #fff;
  border: 1px solid #0bacdb;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  margin-top: 15px;
  padding: 5px 0;
}
.video-hero {
  .el-dropdown-link {
    text-align: center;
    width: 40px;
    height: 40px;
    border: 2px solid #469edb;
    border-radius: 100px;
    background: url(../../assets/img/three_points_h.svg) center center no-repeat,
      url(../../assets/img/three_points_h.svg) -9999px no-repeat !important;
    background-size: 15px !important;
    display: inline-block;
    margin-top: 5px;
    cursor: pointer;
  }

  .title {
    display: flex;
    align-items: flex-end;
    span {
      font-weight: 700;
      font-size: 24px;
      letter-spacing: 1px;
      margin-bottom: 0;
      border: 1px solid transparent;
      padding: 10px 0 0 10px;
      margin-left: -5px;
      padding-left: 5px;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      line-height: 1.2em;
      color: var(--textThemeColor);
      /*color: var(--color-content);*/
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis !important;
    }
    .editIcon {
      cursor: pointer;
      padding-left: 15px;
    }
    // &:hover{
    //     border: 1px solid #DBE3E6;
    // }
  }

  input {
    font-size: 24px;
    font-weight: 700;
    padding: 10px 5px !important;
    margin: 0;
    letter-spacing: 1px;
    margin-left: -5px;
    height: 50px !important;
  }

  .dropdown-container {
    margin-top: 13px;
  }

  .meta-info {
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

.video-settings-dropdown {
  width: 160px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  &:before {
    left: 50% !important;
  }
}

.linkSection {
  background-color: #ebf9fa;
  padding: 5px 15px 5px 7px;
  border-radius: 100px;
  .linkIcon {
  }
  a {
    color: var(--color-blue) !important;
    font-size: 13px;
  }
}
@media (max-width: 960px) {
    .desktop-only {
        // #Css fix-5 added;
        display: none !important;
    }
    .no-desktop {
        display: block !important;
    }
    .no-desktop-flex {
        display: flex !important; 
    }
}
</style>
<script>
export default {
  props: ["video", 'desktopResolution'],
  data() {
    return {
      edit: false,
      title: this.video.title,
      saving: false
    };
  },
  methods: {
    toggleInput() {
      this.edit = true;
      window.setTimeout(function() {
        document.getElementById("video-title-input").focus();
      }, 0);
    },
    save() {
      this.edit = false;
      if (this.title !== this.video.title) {
        this.saving = true;
        this.$emit("change-title", this.title);
        this.saving = false;
      }
    },
    handleCommand(command) {
      this.$emit("handle-command", command);
    }
  },
  components: {}
};
</script>
