<template>
    <div>
        <p class="text-lightest row-space-1">Image size</p>
        <el-row type="flex" :gutter="30">
            <el-col style="width: auto;">
                <el-input-number v-model="width" style="width: 135px;" :step="30"></el-input-number>
            </el-col>
            <el-col style="width: auto;">
                <el-input-number v-model="height" style="width: 135px;" :step="30"></el-input-number>
            </el-col>
        </el-row>

        <div :style="{'background-image': 'url(' + video.thumbnail + ')', 'width': width + 'px', 'height': height + 'px'}"
                id="popover-video-preview">
            <div class="text-center play-button"></div>
        </div>

        <el-row class="row-space-3">
            <el-col :span="24">
                <p class="text-lightest row-space-1">
                    Embed code
                </p>
                <textarea spellcheck="false" id="share-embed-code-textarea" cols="30" rows="3"
                          onclick="this.select()"
                          contenteditable="false" v-text="html" class="form-control"></textarea>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <el-button data-type="copy-embed-code-to-clipboard"
                           type="primary"
                           v-clipboard:copy="html"
                           @click="copyText = 'Copied!'"
                           v-text="copyText">
                    Copy
                </el-button>
            </el-col>
        </el-row>

    </div>
</template>
<style>
    .el-checkbox__label {
        white-space: normal !important;
        word-break: break-all !important;
    }
</style>
<style lang="less" scoped>
 .play-button {
     &:before {
         font-family: VideoJS;
         font-weight: normal;
         font-style: normal;
         content: "\F101";
         color: #fff;
         font-size: 8em;
         line-height: 0;
         vertical-align: middle;
     }
 }

 #popover-video-preview {
     height: 300px;
     background-repeat: no-repeat;
     background-position: center center;
     background-size: cover;
     -webkit-background-size: cover;
     display: flex;
     align-items: center;
     justify-content: center;
     margin: 20px 0;
 }
</style>
<script>
    export default {
        props: ['video'],
        data () {
            return {
                width: 360,
                height: 200,
                responsiveness: false,
                copyText: 'Copy'
            }
        },
        components: {},
        computed: {
            'html' () {
                var string = '&lt;script type="text/javascript" src="' + process.env.VUE_APP_BACKEND_URL + 'js/floating.js" async=""&gt;&lt;/script&gt;<div class="motion_floating" style="width: ' + this.width + 'px !important; height: ' + this.height + 'px !important;" data-options="width=' + this.width + ' height=auto video=' + this.video.video_id + '" data-id="' + this.video.video_id + '"></div>';
                string = string.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
                return string;
            }
        }
    }
</script>
