<template>
  <div v-if="user" class="VideoEditor">
    <three-level-structure v-if="video">
      <three-level-aside :asideWidth="asideWidth" class='desktop-only'>
        <first-level :asideLevel1Width="asideLevel1Width">
          <el-menu>
            <el-menu-item index="1" @click="activeLevel2 = false">
              <i>
                <img src="../assets/img/overview.svg" alt style="margin-top: -7px;" />
              </i> Overview
            </el-menu-item>
            <el-submenu index="2">
              <template slot="title">
                <i>
                  <img src="../assets/img/customize_icon.svg" style="margin-top: -4px;" alt />
                </i>Customize
              </template>
              <el-menu-item-group>
                <el-menu-item index="2-5" @click="toggleActiveLevel('player_skin')">Player Skin</el-menu-item>
                <el-menu-item index="2-1" @click="toggleActiveLevel('appearance')">Appearance</el-menu-item>
                <el-menu-item index="2-2" @click="toggleActiveLevel('thumbnail')">Thumbnail</el-menu-item>
                <el-menu-item index="2-3" @click="toggleActiveLevel('controls')">Controls</el-menu-item>
                <el-menu-item index="2-4" @click="toggleActiveLevel('privacy')">Privacy</el-menu-item>
<!--                <el-menu-item index="2-5" @click="toggleActiveLevel('chapters')">Chapters</el-menu-item>-->
<!--                <el-menu-item index="2-6" @click="toggleActiveLevel('subtitles')">Subtitles</el-menu-item>-->
              </el-menu-item-group>
            </el-submenu>
            <el-submenu  index="3" v-if="(typeof user.allow_video_behaviours === 'undefined'  || (typeof user.allow_interaction_tools !== 'undefined' 
            && (user.allow_interaction_tools === true || user.allow_interaction_tools === 'true') ))  && (user.subuser == 0 
            || user.settings.subuser_interaction_tools_access == 1) ">
              <template slot="title">
                <i>
                  <img src="../assets/img/interaction_icon.svg" alt style="margin-top: -4px" />
                </i> Interaction tools
              </template>
              <el-menu-item-group>
                <el-menu-item index="3-1" @click="toggleActiveLevel('interaction-before')">
                  Before
                  video
                </el-menu-item>
                <el-menu-item index="3-2" @click="toggleActiveLevel('interaction-during')">
                  During
                  video
                </el-menu-item>
                <el-menu-item
                  index="3-3"
                  @click="toggleActiveLevel('interaction-after')"
                >After video</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
<!--            <el-menu-item index="8" @click="toggleActiveLevel('pixel_retargeting')" v-if="video.is_audio == 0">-->
<!--              <i>-->
<!--                <img src="../assets/img/icons/pixel-icon.png" style="margin-top: -10px;" alt />-->
<!--              </i>-->
<!--              Pixel Retargeting-->
<!--            </el-menu-item>-->
<!--            <el-menu-item index="4" @click="redirectAnalyitics()" v-if="video.is_audio == 0">-->
<!--              <i>-->
<!--                <img src="../assets/img/analytics_icon.svg" style="margin-top: -10px;" alt />-->
<!--              </i>Video Analytics-->
<!--            </el-menu-item>-->
              <!-- v-if="user.current_plan && (user.current_plan.stripe_plan == 'pro-monthly' || user.current_plan.stripe_plan == 'bussines-monthly')" -->
            <el-menu-item
              index="5"
              @click="toggleActiveLevel('content_security')"
              v-if="video.is_audio == 0 && video.power_player == 0"
            >
              <i>
                <img src="../assets/img/icons/vault.png" style="margin-top: -10px;" alt />
              </i>Advanced Content Security
            </el-menu-item>
            <!-- v-if="user.current_plan && (user.current_plan.stripe_plan == 'pro-monthly' || user.current_plan.stripe_plan == 'bussines-monthly')" -->
<!--            <el-menu-item-->
<!--              index="6"-->
<!--              @click="toggleActiveLevel('scheduled_publishing')"-->
<!--              v-if="video.is_audio == 0"-->
<!--            >-->
<!--              <i>-->
<!--                <img src="../assets/img/icons/publish-icon.png" style="margin-top: -10px;" alt />-->
<!--              </i>Scheduled Publishing-->
<!--            </el-menu-item>-->



            <template>
              <p class="pablishingDivider size-14">ADVANCED</p>
            </template>

<!--            <el-menu-item index="7" @click="toggleActiveLevel('stage')">-->
<!--              <i>-->
<!--                <img src="../assets/img/stage_icon.svg" style="margin-top: -10px;" alt />-->
<!--              </i>Stage-->
<!--            </el-menu-item>-->

            <el-menu-item index="8" @click="toggleActiveLevel('video_behaviour')" 
            v-if="(typeof user.allow_video_behaviours === 'undefined'  || (typeof user.allow_video_behaviours !== 'undefined' && (user.allow_video_behaviours === true 
            || user.allow_video_behaviours === 'true') )) && (user.subuser == 0 || user.settings.subuser_video_behaviors_access == 1) ">
              <i>
                <img src="../assets/img/stage_icon.svg" style="margin-top: -10px;" alt />
              </i>Video Behaviour
            </el-menu-item>
            <el-menu-item index="9" @click="toggleActiveLevel('video_funnel')" 
            v-if="((user.subscription !== null && user.subscription.plan_id !== 1)  || (typeof user.allow_dynamic_video_funnel !== 'undefined' 
            && (user.allow_dynamic_video_funnel === true || user.allow_dynamic_video_funnel === 'true') )) && (user.subuser == 0 || user.settings.subuser_dynamic_funnels_access == 1)  ">
              <i>
                <img src="../assets/img/playerskin/social-media.svg" style="margin-top: -10px;" alt />
              </i>Dynamic Video Funnels 
            </el-menu-item>


            <!-- <el-menu-item index="5">
                            <i>
                                <img src="../assets/img/social_media_icon.svg" alt="" style="margin-top: -7px;">
            </i>Social media</el-menu-item>-->
          </el-menu>
        </first-level>
        <second-level :asideLevel2Width="asideLevel2Width" :asideLevel2Display="asideLevel2Display">
          <form @submit.prevent="handleSave" ref="editorForm" class="">
            <div class="scroll">
              <editor-customize
                :video="video"
                :activeLevel2="activeLevel2"
                :user="user"
                :loading="loading"
                ref="customize"
                :currentTime="currentTime"
                :blockedModules='blockedModules'
              ></editor-customize>

              <editor-interaction-tools
                :video="video"
                :activeLevel2="activeLevel2"
                :user="user"
                :loading="loading"
                ref="customize"
                @shareImgLoad="shareImgLoad"
              ></editor-interaction-tools>

              <!-- save changes -->
              <!--<div id="level2actions" v-show="changesMade && videoLoaded" :style="{width: asideLevel2Width + 'px'}">-->
              <!-- <div id="level2actions" v-show="changesMade && videoLoaded"> -->
              <div
                id="level2actions"
                v-show="videoLoaded"
              >
                <el-button
                  type="gray"
                  name="button"
                  class="btn btn-info"
                  @click="handleReset"
                >Cancel</el-button>
                <el-button
                  type="submit"
                  @click="handleSave"
                  class="btn btn-primary"
                  :loading="loading"
                >Save</el-button>
                <!--<input type="submit" class="el-button btn btn-info btn-primary" :loading="loading"-->
                <!--value="Save">-->
              </div>
            </div>

            <!-- save changes -->
            <!-- <div id="level2actions" v-show="changesMade && videoLoaded" :style="{width: asideLevel2Width + 'px'}">
							<el-button type="gray" name="button" class="btn btn-info" @click="handleReset">Cancel</el-button>
							<el-button @click="handleSave" type="submit"  name="button" class="btn btn-primary" :loading="loading">Save</el-button>
            </div>-->
            <!-- save changes -->
          </form>
        </second-level>
      </three-level-aside>
      <third-level :asideWidth="asideWidth">
        <el-breadcrumb separator-class="el-icon-arrow-right" >
          <el-breadcrumb-item :to="{path: '/projects'}">Projects</el-breadcrumb-item>
          <el-breadcrumb-item
            :to="{path: '/projects/' + video.project.project_id + ''}"
          >{{video.project.title}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{video.title}}</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
          <el-breadcrumb-item :to="{path: '/projects'}">4KPlayer</el-breadcrumb-item>
          <el-breadcrumb-item>{{video.title}}</el-breadcrumb-item>
        </el-breadcrumb> -->

        <editor-hero
          :video="video"
          :desktopResolution='windowWidth > 1024'
          v-on:handle-command="handleCommand"
          v-on:change-title="changeTitle"
          style="margin-top: 30px;"
        ></editor-hero>

        <!-- Video -->
<!--        <Video-->
<!--          ref="video"-->
<!--          :video="video"-->
<!--          :ownerStatus='ownerStatus'-->
<!--          :bandwidthData='bandwidthData'-->
<!--          @changevideo="changeVideo"-->
<!--          @pouse="onPlayerPause($event)"-->
<!--          @play="onPlayerPlay($event)"-->
<!--          @timeupdate="onPlayerTimeupdate($event)"-->
<!--        ></Video>-->

        <div style="width: 100%; height: null; position: relative; padding-top: 56.25%;margin-top:140px"
        >
          <iframe class="video_iframe" id="video_iframe" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
                  allowtransparency="true"
                  :src="watchUrl + '?editor=true'"
                  frameborder="0" allowfullscreen
                  @load="loadIframe"
                  mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no">
          </iframe>

        </div>



        <!-- Editing Restriction Warning -->
        <VideoEditingRestrict v-if='windowWidth <= 1024' :message='"Video Editing is currently not available on mobile. Please switch to desktop to edit your videos."'></VideoEditingRestrict>

        <!-- video redirect notice -->
        <div class="row-space-top-3 relative" v-if="video.player_options.redirect_url">
          <p class="alert alert-info small">
            After the video, viewers will be automatically redirected to:
            <a
              target="_blank"
              class="text-bold"
              :href="video.player_options.redirect_url"
            >
              {{
              video.player_options.redirect_url }}
            </a>
          </p>
        </div>

        <!-- video redirect notice -->
        <div
          class="row-space-top-3 relative"
          v-if="video.player_options.password && video.player_options.permissions == 'password'"
        >
          <p class="alert alert-warning small">
            The password required for watching this video is "
            <b>{{ video.player_options.password }}</b>".
          </p>
        </div>

        <video-view-summary
          v-if="filteredData!=null && windowWidth > 1024"
          :filter-data="filteredData"
          :video="video"
          :date-label="date_range_label"
          :sideLevelActive="activeLevel2 != false"
        ></video-view-summary>
<!--        <VideoThumbnailSummary v-bind:data="thumbnail_details.a"></VideoThumbnailSummary>-->
<!--        <VideoThumbnailSummary v-bind:data="thumbnail_details.b"></VideoThumbnailSummary>-->
<!--        <editor-comments-->
<!--          class="row-space-top-3"-->
<!--          :video="video"-->
<!--          :currentTime="currentTime"-->
<!--          :user="user"-->
<!--          v-on:timechange="handleTimeChange"-->
<!--          v-on:pause-video="handlePause"-->
<!--          :desktopResolution='windowWidth > 1024'-->
<!--        ></editor-comments>-->

     <embed-modal ref="shareModal" :watchUrl="watchUrl" :video="video" :user="user" v-if='embedModal' @trigger='embedModal = false'></embed-modal>
    <video-ratings-summary v-if="video" :video_ratings="video.ratings"></video-ratings-summary>
    
      </third-level>
    </three-level-structure>

    <div v-else>
      <div class="text-center loading-space">
        <i class="el-icon-loading"></i>
      </div>
    </div>

    <el-dialog
      title="Title & Description"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_edit dialog_border_radius"
      :visible.sync="editDialogVisible"
      :modalAppendToBody="false"
    >
      <div class="relative mb-3">
        <label>Title</label>
        <div class="row-space-top-1 el-input">
          <input
            type="text"
            autocomplete="off"
            v-model="editedVideoTitle"
            class="el-input__inner custom_input_new_style"
            v-bind:class="{ errorBorder: showTitleRequiredError }"
          />
          <span style="color:red" v-if="showTitleRequiredError" class="errorBox">
            <img src="../assets/img/icons/error.png" alt="error" width="auto" class="errorIcon" />
            Video title is required
          </span>
        </div>
      </div>
      <div class="relative">
        <label>Description</label>
        <div class="row-space-top-1 el-input">
          <el-input
            type="textarea"
            v-model="editedVideoDescription"
            placeholder="Add a description"
            rows="4"
            class="resize_none"
          ></el-input>
        </div>
      </div>
      <div class="relative mb-3">
        <label>Tag</label>
        <div class="row-space-top-1 el-input">

          <div class="form-group contact-tags-area">
            <div class="inline-flex w-100">
              <el-input
                  @blur="handleInputConfirm"
                  @keyup.enter.native="handleInputConfirm"
                  class="custom-el-input"
                  placeholder="Add tag"
                  v-model="stage_tag_input"
              ></el-input>
              <el-button @click="handleInputConfirm" class="button-new-tag" size="medium">+</el-button>
            </div>
            <div class="w-100 m-top-3">
              <el-tag
                  :disable-transitions="false"
                  :key="`stage_tag_${tagInd}`"
                  @close="handleClose(tag)"
                  closable
                  v-for="(tag, tagInd) in this.videoTags"
              >{{ tag }}</el-tag>

            </div>
          </div>

        </div>
      </div>
      <div class="relative mb-3">
        <label>Author Name</label>
        <div class="row-space-top-1 el-input">
          <input
                  type="text"
                  autocomplete="off"
                  v-model="editedVideoAuthor"
                  class="el-input__inner custom_input_new_style"
          />

        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false" type="gray">Cancel</el-button>
        <el-button type="submit" class="btn i-btn-btn" @click="editTitleAndDescription">Apply</el-button>
      </div>
    </el-dialog>



    <el-dialog
      title="SAVE YOUR CHANGES"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_border_radius"
      :visible.sync="showConfirmPopup"
      :modalAppendToBody="false"
    >
      <div class="relative">
        <span>Note: Any unsaved changes will be lost. Are you sure you want to leave this page?</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showConfirmPopup = false" type="gray">Discard Changes</el-button>
        <el-button
          type="submit"
          class="btn btn-primary"
          @click="editTitleConfirmPopup"
        >Save & Continue</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="COPY SETTINGS"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_border_radius"
      :visible.sync="showCopySettings"
      @close="closeCopySettingsDialog()"
      :modalAppendToBody="false"
    >
      <div style="display:flex;flex-direction:column">
        <span class="text">Copy Customizations and Interactions Tools from another video</span>
        <span class="label_text">Copy from video</span>
      </div>
      <el-button
        class="sel-video-button custom_big_select"
        @click="showVideoListsPop('copySettings')"
        style="width:100%"
      >
        <div>
          <div
            v-if="selected_video"
            class="prefix content__col content__col--image"
            style="display:flex"
          >
            <div style="height:50px; position:relative">
              <img class="item__image" :src="selected_video.thumbnail" />

              <div class="item__duration">{{selected_video.duration_formatted}}</div>
            </div>
            <div class="item_title">{{selected_video.title}}</div>
          </div>
        </div>
        <i class="el-icon-arrow-up" v-if="copySettingsPopOver"></i>
        <i class="el-icon-arrow-down" v-else></i>
      </el-button>
      <el-popover
        :visible-arrow="true"
        placement="right"
        popper-class="custom_popover pop_bottom_464 video-popover"
        trigger="click"
        v-model="copySettingsPopOver"
      >
        <div class="popover-content">
          <div class="form-group search-group searchBox">
            <el-input
              class="custom-el-input search-input searchInput"
              placeholder="Search Videos"
              v-model="videoSearch"
            ></el-input>
            <img class="searchIcon" alt src="../assets/img/stage/icon_search.svg" />
          </div>
          <div class="videos-list-group">
            <ul class="scroll">
              <li v-for="item in filterVideosList()" :key="item.id" @click="selectVideo(item)">
                <div
                  class="content__col content__col--image"
                  style="display:flex; align-items: center; padding: 15px 0;"
                >
                  <div class="checkIconBox">
                    <i class="el-icon-check" v-if="selected_video && selected_video.id === item.id"></i>
                  </div>
                  <div style="height:50px; position:relative">
                    <img class="item__image" :src="item.thumbnail" />

                    <div class="item__duration">{{item.duration_formatted}}</div>
                  </div>
                  <div class="item_title">{{item.title}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </el-popover>
      <label v-if="!copySettingsPopOver" style="margin-top: 30px">
        <span>What do you want to copy</span>
      </label>
      <div v-if="!copySettingsPopOver" style="display:flex; margin-top: 15px">
        <div style="display:flex;flex-direction:column; width: 50%">
          <label class="label_tools">Customizations</label>
          <el-checkbox v-model="settings_apperance">Apperance</el-checkbox>
          <el-checkbox v-model="settings_thumbnail">Thumbnail</el-checkbox>
          <el-checkbox v-model="settings_controls">Controls</el-checkbox>
          <el-checkbox v-model="settings_privacy">Privacy</el-checkbox>
        </div>
        <div style="display:flex;flex-direction:column; width: 50%" v-if="video.is_audio == 0">
          <label class="label_tools">Interaction Tools</label>
          <el-checkbox v-model="settings_before">Before Video</el-checkbox>
          <el-checkbox v-model="settings_during">During Video</el-checkbox>
          <el-checkbox v-model="settings_after">After Video</el-checkbox>
        </div>
      </div>
      <div v-if="errorNotCheckedSettings">
        <span style="color:red">You must select at least one option to copy</span>
      </div>

      <span v-if="!copySettingsPopOver" slot="footer" class="dialog-footer">
        <el-button @click="cancelCopySettings" type="gray">Cancel</el-button>
        <el-button
          type="submit"
          class="btn btn-primary"
          @click="showCopySettingApplyConfirm"
        >Continue</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="COPY SETTINGS"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_border_radius"
      :visible.sync="showCopySettingApplyConfirmWindow"
      :modalAppendToBody="false"
    >
      <div class="relative text">
        <span>
          You are about to copy the following Customizations and Interaction Tools from
          <b>{{video.title}}</b>
        </span>
      </div>
      <div style="display:flex;">
        <div style="display:flex;flex-direction:column; width: 50%">
          <label class="label_tools">Customizations</label>
          <ul class="tools">
            <li v-if="settings_apperance">Apperance</li>
            <li v-if="settings_thumbnail">Thumbnail</li>
            <li v-if="settings_controls">Controls</li>
            <li v-if="settings_privacy">Privacy</li>
          </ul>
        </div>
        <div class="col-md-6" style="display:flex;flex-direction:column">
          <label class="label_tools">Interaction Tools</label>
          <ul class="tools">
            <li v-if="settings_before">Before Video</li>
            <li v-if="settings_during">During Video</li>
            <li v-if="settings_after">After Video</li>
          </ul>
        </div>
      </div>
      <div>
        <el-checkbox v-model="settings_confirm_understand" class="understand" style="display: flex">
          <span style="font-weight:400">
            I understand that the selected Customizations and Interaction Tools in
            <br />
            <b>"{{selectedVideoTitle}}"</b> will be replaced
          </span>
        </el-checkbox>
      </div>
      <div v-if="copySettingsErrorNotAgree">
        <span
          style="color:red"
        >Please confirm that the current Customizations and Interaction Tools will be replaced</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCopySettingApplyConfirmWindow = false" type="gray">Back</el-button>
        <el-button
          type="submit"
          class="btn btn-primary"
          @click="copyCheckedVidoeSettings"
        >Copy & Replace</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="COPY VIDEO TO PROJECT"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_border_radius copy-project"
      :visible.sync="showCopyProject"
      @close="closeCopyProjectsDialog()"
      :modalAppendToBody="false"
    >
      <div style="display:flex;flex-direction:column">
        <span class="text">Create a copy of this Video in another Project</span>
        <span class="label_text">Copy to Project</span>
      </div>
      <div>
        <el-button
          data-v-fb856748
          class="sel-video-button custom_big_select"
          @click="showProjectListsPop()"
          style="width:100%"
        >
          <div>
            <div
              v-if="selected_copy_project"
              class="prefix content__col content__col--image"
              style="display:flex"
            >
              <div style="height:50px; position:relative">
                <img style="width: 50px;height: 50px;" src="../assets/img/project-folder.svg" />
              </div>
              <div class="item_title project">
                <div>{{selected_copy_project.title}}</div>
                <div>{{selected_copy_project.videos_count}} Videos</div>
              </div>
            </div>
          </div>
          <i class="el-icon-arrow-up" v-if="copyProjectsPopOver"></i>
          <i class="el-icon-arrow-down" v-else></i>
        </el-button>
        <el-popover
          :visible-arrow="true"
          placement="right"
          popper-class="custom_popover pop_bottom_464 video-popover"
          trigger="click"
          v-model="copyProjectsPopOver"
        >
          <div class="popover-content">
            <div class="form-group search-group">
              <el-input
                class="custom-el-input search-input searchInput"
                placeholder="Search Projects"
                v-model="projectSearch"
              ></el-input>
              <img class="searchIcon" alt src="../assets/img/stage/icon_search.svg" />
            </div>
            <div class="videos-list-group">
              <ul class="scroll">
                <li
                  v-for="item in filterProjectsList()"
                  :key="item.id"
                  @click="selectCopyProject(item)"
                >
                  <div
                    class="content__col content__col--image"
                    style="display:flex; align-items: center; padding: 15px 0;"
                  >
                    <div class="checkIconBox">
                      <i
                        class="el-icon-check"
                        v-if="selected_copy_project && selected_copy_project.id === item.id"
                      ></i>
                    </div>
                    <div style="height:50px; position:relative">
                      <img style="width: 50px;height: 50px;" src="../assets/img/project-folder.svg" />
                    </div>
                    <div style="display:flex;flex-direction:column;justify-content: center;">
                      <div>{{item.title}}</div>
                      <div>{{item.videos_count}} Videos</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </el-popover>
      </div>
      <div v-if="copyProjectsError">
        <span style="color:red">Please choose project</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelCopyProject" :loading="loadingCopyToProject" type="gray">Cancel</el-button>
        <el-button
          type="submit"
          class="btn btn-primary"
          :loading="loadingCopyToProject"
          @click="copyCheckedProjectVideos"
        >Copy</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="MOVE VIDEO TO PROJECT"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_border_radius"
      :visible.sync="showMoveProject"
      @close="closeMoveProjectsDialog()"
      :modalAppendToBody="false"
    >
      <div style="display:flex;flex-direction:column">
        <span class="text">Move this Video in another Project</span>
        <span class="label_text">Move to Project</span>
      </div>
      <div>
        <el-button
          data-v-fb856748
          class="sel-video-button custom_big_select"
          @click="showProjectListsPop()"
          style="width:100%"
        >
          <div>
            <div
              v-if="selected_move_project"
              class="prefix content__col content__col--image"
              style="display:flex"
            >
              <div style="height:50px; position:relative">
                <img style="width: 50px;height: 50px;" src="../assets/img/project-folder.svg" />
              </div>
              <div class="item_title project">
                <div>{{selected_move_project.title}}</div>
                <div>{{selected_move_project.videos_count}} Videos</div>
              </div>
            </div>
          </div>
          <i class="el-icon-arrow-up" v-if="moveProjectsPopOver"></i>
          <i class="el-icon-arrow-down" v-else></i>
        </el-button>
        <el-popover
          :visible-arrow="true"
          placement="right"
          popper-class="custom_popover pop_bottom_464 video-popover"
          trigger="click"
          v-model="moveProjectsPopOver"
        >
          <div class="popover-content">
            <div class="form-group search-group">
              <el-input
                class="custom-el-input search-input searchInput"
                placeholder="Search Projects"
                v-model="projectSearch"
              ></el-input>
              <img class="searchIcon" alt src="../assets/img/stage/icon_search.svg" />
            </div>
            <div class="videos-list-group">
              <ul class="scroll">
                <li
                  v-for="item in filterProjectsList()"
                  :key="item.id"
                  @click="selectMoveProject(item)"
                >
                  <div
                    class="content__col content__col--image"
                    style="display:flex; align-items: center; padding: 15px 0;"
                  >
                    <div class="checkIconBox">
                      <i
                        class="el-icon-check"
                        v-if="selected_move_project && selected_move_project.id === item.id"
                      ></i>
                    </div>
                    <div style="height:50px; position:relative">
                      <img style="width: 50px;height: 50px;" src="../assets/img/project-folder.svg" />
                    </div>
                    <div style="display:flex;flex-direction:column;justify-content: center;">
                      <div>{{item.title}}</div>
                      <div>{{item.videos_count}} Videos</div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </el-popover>
      </div>
      <div v-if="moveProjectsError">
        <span style="color:red">Please choose project</span>
      </div>
      <span v-if="!moveProjectsPopOver" slot="footer" class="dialog-footer">
        <el-button @click="cancelMoveProject" type="gray">Cancel</el-button>
        <el-button type="submit" class="btn btn-primary" @click="moveCheckedProejctVideos">Move</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="Download"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_border_radius download-popup"
      :visible.sync="showDownloadVideo"
      :modalAppendToBody="false"
    >
      <div v-if="video.drm == 0" style="display:flex;flex-direction:column;padding-left:35px">
        <p>Select file to download</p>
      </div>
      <div v-else style="display:flex;flex-direction:column;padding-left:35px">
        <p class="text-center">This video is protected</p>
      </div>
      <div style="margin-top:20px" v-if="video.drm == 0">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>FILE</th>
              <th>TYPE</th>
              <th v-if="video.is_audio == 0">DIMENSION</th>
              <th>SIZE</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="resolution in video.resolutions" :key="resolution.id">
              <td>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 17.859 14.164"
                >
                  <g id="video-player" transform="translate(0 -6)">
                    <path
                      id="Path_9224"
                      data-name="Path 9224"
                      d="M27.86,23.2l-3.387-2.155a.308.308,0,0,0-.473.26v4.311a.308.308,0,0,0,.473.26l3.387-2.155a.308.308,0,0,0,0-.52Zm-3.244,1.855V21.869l2.505,1.595Z"
                      transform="translate(-16.61 -10.381)"
                      fill="#fffff"
                    />
                    <path
                      id="Path_9225"
                      data-name="Path 9225"
                      d="M17.551,6H.308A.308.308,0,0,0,0,6.308V19.856a.308.308,0,0,0,.308.308H17.551a.308.308,0,0,0,.308-.308V6.308A.308.308,0,0,0,17.551,6ZM3.079,12.774H.616V10H3.079ZM.616,13.39H3.079v2.771H.616Zm3.079,3.079V6.616H14.164V19.548H3.695Zm13.548-3.695H14.78V10h2.463Zm-2.463.616h2.463v2.771H14.78Zm2.463-6.774V9.387H14.78V6.616Zm-16.627,0H3.079V9.387H.616Zm0,12.932V16.777H3.079v2.771Zm16.627,0H14.78V16.777h2.463Z"
                      transform="translate(0 0)"
                      fill="#fffff"
                    />
                  </g>
                </svg>
              </td>
              <td style="vertical-align: middle">{{resolution.name}}</td>
              <td style="vertical-align: middle;">{{video.is_audio == 1 ? 'AAC' : 'MP4' }}</td>
              <td style="vertical-align: middle" v-if="video.is_audio == 0">{{getFileResolutionFull(resolution.name)}}</td>
              <td style="vertical-align: middle">{{bytesToSize(resolution.file_size)}}</td>
              <td>
                <div @click="download(resolution.file_path, resolution.name)">
                  <svg
                    height="30"
                    viewBox="0 0 512 512"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#0DABD8"
                      d="m448 512h-384c-35.285156 0-64-28.714844-64-64v-85.332031c0-11.777344 9.558594-21.335938 21.332031-21.335938 11.777344 0 21.335938 9.558594 21.335938 21.335938v85.332031c0 11.753906 9.578125 21.332031 21.332031 21.332031h384c11.753906 0 21.332031-9.578125 21.332031-21.332031v-85.332031c0-11.777344 9.558594-21.335938 21.335938-21.335938 11.773437 0 21.332031 9.558594 21.332031 21.335938v85.332031c0 35.285156-28.714844 64-64 64zm0 0"
                    />
                    <path
                      fill="#0DABD8"
                      d="m356.414062 271.082031-85.332031 85.332031c-8.339843 8.34375-21.824219 8.34375-30.164062 0l-85.332031-85.332031c-6.101563-6.101562-7.9375-15.273437-4.632813-23.253906 3.308594-7.957031 11.09375-13.160156 19.714844-13.160156h64v-213.335938c0-11.773437 9.554687-21.332031 21.332031-21.332031s21.332031 9.558594 21.332031 21.332031v213.335938h64c8.621094 0 16.40625 5.203125 19.714844 13.160156 3.304687 7.980469 1.46875 17.152344-4.632813 23.253906zm0 0"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-dialog>

    <el-dialog
      title="REPLACE VIDEO"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_border_radius"
      :visible.sync="showReplaceVideo"
      :modalAppendToBody="false"
    >
      <div class="el-dialog__body" v-show="replace_popup_step === 1" :style='{"padding": windowWidth <= 1024 ? "0px !important" : ""}'>
        <div style="display:flex;flex-direction:column">
          <span style="margin-top:10px">
            When a video is replaced,the previous version is removed and updated with the new video.
            However,all of you customizations and interaction tools are carried over to the new video
          </span>
          <span style="margin-top:10px">How do you want to manage the video stats?</span>
        </div>
        <div>
          <el-radio v-model="replace_type" label="no_archived">
            <b>Overwrite the original; build on top of stats</b>
          </el-radio>
          <br />
          <el-radio v-model="replace_type" label="archived">
            <b>Overwrite the original; start new stats from <br v-if='windowWidth <= 1024'> scratch</b>
          </el-radio>
        </div>
      </div>

      <span slot="footer" class="dialog-footer" v-show="replace_popup_step === 1">
        <el-button @click="closeDashboardArea" type="gray">Cancel</el-button>
        <el-button
          type="primary"
          class="btn btn-primary"
          @click="openVideoUploadPopup"
        >{{ windowWidth > 1024 ? 'Upload Replacement Video' : 'Upload' }}</el-button>
      </span>

      <div v-show="replace_popup_step === 2">
        <span class="back-to-replace" @click="replace_popup_step = 1">&laquo;</span>
        <div class="el-dialog__body">
          <div id="video_replace_area"></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="ARE YOU SURE?"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_border_radius"
      :visible.sync="showDeletePopup"
      :modalAppendToBody="false"
    >
      <div class="el-dialog__body" :style='{"padding": windowWidth <= 1024 ? "0px !important" : ""}'>
        <div style="display:flex;flex-direction:column">
          <span style="margin-top:10px">
            You are about to Delete this Video.Deleted videos are removed from your account
            and they will become unavailable wherever they are embeded
          </span>
        </div>
        <div class="col-md-12" style="margin-top:20px">
          <div class="col-md-4" style="display:flex;flex-direction:column">
            <label>VIDEO</label>
            <div style="word-break: break-word">{{video.title}}</div>
          </div>
          <div class="col-md-4" style="display:flex;flex-direction:column">
            <label>IMPRESSIONS</label>
            <span v-if="filteredData">{{filteredData.summary.impression.total}}</span>
          </div>
          <div class="col-md-4" style="display:flex;flex-direction:column">
            <label>VIEWS</label>
            <span v-if="filteredData">{{filteredData.summary.views.total}}</span>
          </div>
        </div>
        <div style="margin-top:100px">
          <label>
            Type
            <b style="color:red">DELETE</b> to confirm
          </label>
          <div class="row-space-top-1 el-input">
            <input
              type="text"
              autocomplete="off"
              v-model="typeConfirmDlete"
              class="el-input__inner custom_input_new_style"
            />
          </div>

          <span style="color:red" v-if="isTypedDeleteError">
            You must type
            <b style="color:red">DELETE</b> to confirm
          </span>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="showDeletePopup = false" :loading="loadingDelete" type="gray">Cancel</el-button>
        <el-button type="danger" :loading="loadingDelete" @click="deleteVideoConfirm">Delete</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="DUPLICATE VIDEO"
      center
      :width="windowWidth > 1024 ? `40%` : '100%'"
      class="el-dialog--huge dialog_border_radius"
      :visible.sync="showDuplicatePopup"
      :modalAppendToBody="false"
    >
      <div class="el-dialog__body">
        <div style="display:flex;flex-direction:column">
          <span style="margin-top:10px">Create a duplicate copy of this video in current project</span>
        </div>

        <div style="margin-top:20px">
          <label>Title</label>
          <div class="row-space-top-1 el-input">
            <input
              type="text"
              autocomplete="off"
              v-model="duplicateVideoTitle"
              class="el-input__inner custom_input_new_style"
            />
          </div>

          <span style="color:red" v-if="duplicateVideoTitle==''">This field is required</span>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          @click="showDuplicatePopup = false"
          :loading="loadingDupliacte"
          type="gray"
        >Cancel</el-button>
        <el-button
          type="primary"
          class="btn btn-primary"
          :loading="loadingDupliacte"
          @click="duplicateVideo"
        >Duplicate</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { Loading } from "element-ui";
import $ from "jquery";

import Uppy from "@uppy/core";
import { Dashboard, Tus, AwsS3 } from "uppy";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/webcam/dist/style.css";
import "@uppy/url/dist/style.css";


import VideoRatingsSummary from "./Analytics/Items/VideoRatingsSummary.vue";
import VideoViewSummary from "./Analytics/Items/VideoViewSummary.vue";
import VideoThumbnailSummary from "./Analytics/Items/VideoThumbnailSummary";
import VideoEditingRestrict from '../components/Errors/VideoEditingRestrict';
import EmbedModal from './Project/ChildPages/ProjectExperimentationPages/CommonComponents/EmbedModal.vue';

var
  //       BackHeadlineButton,
  // EditorComments,
  // EditorCustomize,
  // EditorInteractionTools,
  // EditorDetails,
  // EditorHero,
  // EditorShare,
  PasswordModal,
  shareModal;
//   EditorThumbnailOptions,
//   EditorPlayerOptions,
//   ThreeLevelStructure,
//   ThreeLevelAside,
//   FirstLevel,
//   SecondLevel,
//   ThirdLevel,
//   Video;
//
// ThreeLevelStructure = require("./UI/ThreeLevelStructure.vue");
// ThreeLevelAside = require("./UI/ThreeLevelAside.vue");
// FirstLevel = require("./UI/FirstLevel.vue");
// SecondLevel = require("./UI/SecondLevel.vue");
// ThirdLevel = require("./UI/ThirdLevel.vue");
//
// BackHeadlineButton = require("./UI/BackHeadlineButton.vue");
// EditorHero = require("./Editor/EditorHero.vue");
// EditorCustomize = require("./Editor/EditorCustomize.vue");
// EditorInteractionTools = require("./Editor/EditorInteractionTools.vue");
// EditorShare = require("./Editor/EditorShare.vue");
// EditorComments = require("./Editor/EditorComments.vue");
// EditorDetails = require("./Editor/EditorDetails.vue");
// EditorPlayerOptions = require("./Editor/Items/EditorPlayerOptions.vue");

// Video = require("./Video.vue");


import ThreeLevelStructure from './UI/ThreeLevelStructure.vue'
import ThreeLevelAside from "./UI/ThreeLevelAside.vue";
import FirstLevel from "./UI/FirstLevel.vue";
import SecondLevel from "./UI/SecondLevel.vue";
import ThirdLevel from "./UI/ThirdLevel.vue";
import { debounce } from 'lodash'
import BackHeadlineButton from "./UI/BackHeadlineButton.vue";
import EditorHero from "./Editor/EditorHero.vue";
import EditorCustomize from "./Editor/EditorCustomize.vue";
import EditorInteractionTools from "./Editor/EditorInteractionTools.vue";
import EditorShare from "./Editor/EditorShare.vue";
import EditorComments from "./Editor/EditorComments.vue";
import EditorDetails from "./Editor/EditorDetails.vue";
import EditorPlayerOptions from "./Editor/Items/EditorPlayerOptions.vue";

export default {
  props: ["user", "whitelabelBrandDetail"],
  data: function() {
    // console.log(this.user.current_plan.stripe_plan);

    return {
      shareCustomImage: '',
      copySettingsPopOver: false,
      copyProjectsPopOver: false,
      moveProjectsPopOver: false,
      videoSearch: "",
      projectSearch: "",
      showTitleRequiredError: false,
      editDialogVisible: false,
      showConfirmPopup: false,
      showCopyProject: false,
      showMoveProject: false,
      showDeletePopup: false,
      showDuplicatePopup: false,
      settings_confirm_understand: false,
      copySettingsErrorNotAgree: false,
      showCopySettings: false,
      settings_apperance: false,
      settings_thumbnail: false,
      settings_controls: false,
      settings_privacy: false,
      settings_before: false,
      settings_during: false,
      settings_after: false,
      showCopySettingApplyConfirmWindow: false,
      errorNotCheckedSettings: false,
      editedVideoTitle: "",
      selected_video: null,
      selected_copy_project: null,
      selected_move_project: null,
      selectedVideoTitle: "",
      editedVideoDescription: "",
      videoElement: null,
      replace_type: "no_archived",
      replace_popup_step: 1,
      videos: [],
      projects: [],
      copyProjectsError: false,
      moveProjectsError: false,
      showDownloadVideo: false,
      showReplaceVideo: false,
      typeConfirmDlete: "",
      isTypedDeleteError: false,
      duplicateVideoTitle: "",
      showDuplicationError: false,
      paused: null,
      date_range_label: "Any Time",
      filterQuery: {
        checkedVideos: [],
        timeline_type: "any_time"
      },
      filteredData: null,
      video: false,
      beforeEditingVideoCache: false,
      videoLoaded: false,
      details: {
        views: 0,
        clicks: 0,
        leads: 0
      },
      // three level structure
      activeLevel2: false, //'interaction-after',
      changesMade: false,
      loading: false,
      currentTime: 0,
      loadingCopyToProject: false,
      loadingDupliacte: false,
      loadingDelete: false,
      thumbnail_details: null,
      windowWidth: null,
      embedModal: false,
      ownerStatus: null,
      bandwidthData: null,
      videoTags: [],
      stage_tag_input: "",
      editedVideoAuthor: "",
      blockedModules: [],
      iframeLoading: true,
      urlVideoObj: null,
      watchUrl: ''
    };
  },
  components: {
    VideoRatingsSummary,
    VideoViewSummary,
    VideoThumbnailSummary,
    BackHeadlineButton,
    EditorHero,
    EditorCustomize,
    EditorInteractionTools,
    EditorShare,
    EditorComments,
    EditorDetails,
    EditorPlayerOptions,
    ThreeLevelStructure,
    ThreeLevelAside,
    FirstLevel,
    SecondLevel,
    ThirdLevel,
    // EditorThumbnailOptions: EditorThumbnailOptions,
    Dashboard,
    Tus,
    VideoEditingRestrict,
    EmbedModal,

  },
  created: function() {
    // console.log(this.whitelabelBrandDetail, 'whitelabelBrandDetail---');
    let redirectedFromEmbed = this.$route.query.s;
    // this.getRestrictions();
    if (redirectedFromEmbed) {
      this.activeLevel2 = "privacy";
    }
    return this.loadVideo();

  },
  mounted: function() {
    if (this.player) {
      // return console.log('this is current player instance object', this.player);
    }
    // this.$store.dispatch("getSubscriptionData");
    window.addEventListener('resize', this.onResize);
    this.onResize();

  },
  computed: {
    // subscriptionData() {
    //   return this.$store.state.subscription;
    // },
    player: function() {
      if (this.playerReady === true) {
        return this.$refs.video.videoPlayer.player;
      }
    },
    cssField: function() {
      var css, string;
      // background color
      css =
        ".vjs-play-control, .vjs-volume-menu-button, .video-js.vjs-custom-skin .vjs-volume-level, .vjs-playback-rate.vjs-menu-button, .vjs-icon-custom-share, .vjs-fullscreen-control, .vjs-resolution-button, .vjs-play-progress, .vjs-custom-control-spacer { background: " +
        this.video.player_options.color +
        " !important;}";
      css += ".video-js .vjs-tech {object-fit: cover !important; }";
      // control visibility
      if (this.video.player_options.autoplay) {
        this.muteIcon = true;
      } else {
        this.muteIcon = false;
      }
      if (
        this.video.player_options.branding_active === true &&
        this.video.player_options.branding_logo != "" &&
        this.video.player_options.branding_logo != null
      ) {
        css +=
          '.vjs-icon-adilo:before {background-image:url("' +
          this.video.player_options.branding_logo +
          '")}';
      }
      if (
        this.video.player_options.control_visibility === "always" ||
        this.video.player_options.control_visibility === "hide_progress"
      )
        css +=
          ".vjs-control-bar { opacity: 1 !important; display: -ms-flexbox !important; display: flex !important; visibility: visible !important;}";
      else if (this.video.player_options.control_visibility === "hide")
        css +=
          ".vjs-control-bar { opacity: 0 !important; display: none !important;}";
      if (this.video.player_options.control_visibility === "hide_progress")
        css +=
          ".vjs-progress-control.vjs-control, .vjs-time-control.vjs-control { opacity: 0 !important; pointer-events: none !important;}";

      // speed control
      if (this.video.player_options.settings === false)
        css += ".vjs-custom-settings-menu-button { display: none !important }";
      if (this.video.player_options.speed_control === false)
        css +=
          ".vjs-playback-rate.vjs-menu-button { display: none !important }";
      if (this.video.player_options.quality_control === false)
        css +=
          ".vjs-settings-main-quality.vjs-menu-button { display: none !important }";
      if (this.video.player_options.playbar === false)
        css +=
          ".vjs-progress-control.vjs-control, .vjs-time-control.vjs-control { opacity: 0 !important; pointer-events: none !important;}";

      // share control
      if (this.video.player_options.share_control === false)
        css += ".vjs-icon-custom-share {display: none !important;}";
      // volume control
      if (this.video.player_options.volume_control === false)
        css += ".vjs-volume-menu-button {display: none !important;}";
      // fullscreen control
      if (this.video.player_options.fullscreen_control === false)
        css += ".vjs-fullscreen-control {display: none !important;}";
      // if all are hidden, remove spacer as well
      if (
        !this.video.player_options.settings &&
        !this.video.player_options.share_control &&
        !this.video.player_options.volume_control &&
        !this.video.player_options.fullscreen_control
      )
        css += ".vjs-custom-control-spacer {display: none !important}";
      string = '&lt;style type="text/css"&gt; ' + css + "&lt;/style&gt;";
      string = string.replace(/&lt;/g, "<").replace(/&gt;/g, ">");
      return string;
    },
    // three level structure
    asideWidth() {
      if (!this.onResize()) return 0;
      return this.asideLevel1Width + this.asideLevel2Width + 5;
    },
    asideLevel1Width() {
      var width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      width = width / 4;
      if (width < 300) return 300;
      if (width > 400) return 400;
      return width;
    },
    asideLevel2Width() {
      if (this.activeLevel2) return this.asideLevel1Width;
      return 0;
    },
    asideLevel2Display() {
      if (this.asideLevel2Width == 0) return "none";
      return "inline-block";
    }
  },
  methods: {
    shareImgLoad(e) {
      this.shareCustomImage = e;
    },
    loadIframe(){
      this.iframeLoading = false;
      console.log('loadIframe');
    },
    getFileResolutionFull(res) {
      // var res = this.getFileResolution(filepath).toString();
      //
      var res = res.substring(0, res.length - 1); // remove 240'p'

      return res + "x" + Math.round(parseInt(res) * (16 / 9));
    },
    // getRestrictions() {
    //   this.axios.get('restrictions/index')
    //     .then(response => {
    //         this.loading = false;
    //         if (response.data.lifetime) {
    //           var data = response.data.lifetime;
    //           if (data) {
    //             const modules = data.modules;
    //             const subModules = data.sub_modules;
    //             this.blockedModules = [...modules, ...subModules];
    //           }
    //         }
    //     })
    //     .catch(error => {
    //         this.loading = false;
    //         console.log(error);
    //     });
    // },
    handleInputConfirm() {
      if (this.stage_tag_input) {
        // this.selContact.tags = this.display_data;
        this.videoTags.push(this.stage_tag_input);
        this.stage_tag_input = "";

        console.log(this.videoTags, 'videoTags');

        // this.updateContactInfo(); //ajax here
      }
    },
    handleClose(tag) {
      console.log(tag, 'tag');
      this.videoTags.splice(this.videoTags.indexOf(tag), 1);

    },
    download: function(file, text) {
      //creating an invisible element

      var re = /(?:\.([^.]+))?$/;

      var ext = re.exec(file)[1];


      file = file.replace(ext, "mp4");

      var element = document.createElement("a");
      element.setAttribute("href", file);
      element.setAttribute("download", text);
      element.setAttribute("target", "_blank");
      //the above code is equivalent to
      // <a href="path of file" download="file name">

      document.body.appendChild(element);

      //onClick property
      element.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(element);
    },
    bytesToSize: function(bytes) {
      var sizes = ["KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    closeCopySettingsDialog() {
      this.cancelCopySettings();
    },
    closeCopyProjectsDialog() {
      this.cancelCopyProject();
    },
    closeMoveSettingsDialog() {
      this.cancelMoveProject();
    },
    cancelCopySettings() {
      this.showCopySettings = false;
      this.selected_video = this.videos[0];
      this.videoSearch = "";
    },
    cancelCopyProject() {
      this.showCopyProject = false;
      this.selected_copy_project = this.projects[0];
      this.projectSearch = "";
    },
    cancelMoveProject() {
      this.showMoveProject = false;
      this.selected_move_project = this.projects[0];
      this.projectSearch = "";
    },
    selectVideo(video) {
      this.selected_video = video;
      this.copySettingsPopOver = false;
    },
    selectCopyProject(project) {
      this.selected_copy_project = project;
      this.copyProjectsPopOver = false;
    },
    selectMoveProject(project) {
      this.selected_move_project = project;
      this.moveVideoPopOver = false;
    },
    showVideoListsPop() {
      this.copySettingsPopOver = !this.copySettingsPopOver;
      this.videoSearch = "";
    },
    showProjectListsPop() {
      this.copyProjectsPopOver = !this.copyProjectsPopOver;
      this.moveProjectsPopOver = !this.moveProjectsPopOver;

      this.projectSearch = "";
    },
    filterVideosList() {
      let videos = this.videos.filter(video => {
        if (this.videoSearch) {
          return video.title
            .toLowerCase()
            .search(this.videoSearch.toLowerCase()) !== -1
            ? true
            : false;
        }
        return true;
      });
      return videos;
    },
    filterProjectsList() {
      let projects = this.projects.filter(project => {
        if (this.projectSearch) {
          return project.title
            .toLowerCase()
            .search(this.projectSearch.toLowerCase()) !== -1
            ? true
            : false;
        }
        return true;
      });
      return projects;
    },
    redirectAnalyitics: function() {
      window.location.href = "/analytics?video=" + this.video.id;
    },
    deleteVideoConfirm: function() {
      if (this.typeConfirmDlete == "" || this.typeConfirmDlete != "DELETE") {
        this.isTypedDeleteError = true;
      } else {
        this.isTypedDeleteError = false;
        this.loadingDelete = true;
        let $this = this;


        var url = "/editor/delete-video";

        if(this.video.power_player == 1){
          var url = `powerPlayerVideos/delete/${$this.video.id}`;
        }

        this.axios({
          method: "post",
          url: url,
          data: {
            video_id: $this.video.video_id
          }
        })
          .then(response => {
            this.loadingDelete = false;

            if(this.video.power_player == 1){
              $this.$router.push({
                name: "PowerPlayer",
              });
            }

            if (
              typeof response.data.success !== typeof undefined &&
              response.data.success === true
            ) {
              $this.$router.push({
                name: "ProjectView",
                params: {
                  id: $this.video.project.project_id
                }
              });
            }
          })
          ["catch"](error => {
            console.error("delete video ", error);
          });
      }
    },
    duplicateVideo() {
      if (this.duplicateVideoTitle == "") {
        this.showDuplicationError = true;
      } else {
        this.loadingDupliacte = true;
        this.showDuplicationError = false;

        var $this = this;
        var data = {};
        data.video_id = $this.video.video_id;
        data.video_title = $this.duplicateVideoTitle;


        var url = "/editor/video/duplicate";

        if(this.video.power_player == 1){
          var url = "/powerPlayerVideos/duplicate";
        }

        this.axios({
          method: "post",
          url: url,
          data: data
        }).then(response => {
          this.loadingDupliacte = false;
          if (
            typeof response.data.success !== typeof undefined &&
            response.data.success === true
          ) {
            $this.showDuplicatePopup = false;
            $this.$message({
              showClose: true,
              message: response.data.message,
              type: "success"
            });
          } else {
            console.warn("duplicate error", response.data.message);
          }
        });
      }
    },
    moveCheckedProejctVideos: function() {
      if (this.selected_move_project == null) {
        this.moveProjectsError = true;
      } else {
        this.moveProjectsError = false;
        var $this = this;
        var data = {};
        data.video_id = $this.video.id;
        data.moved_project_id = $this.selected_move_project.id;

        this.axios({
          method: "post",
          url: "/editor/video/move-video-to-project",
          data: data
        }).then(function(response) {
          if (
            typeof response.data.success !== typeof undefined &&
            typeof response.data.video !== typeof undefined
          ) {
            let video = response.data.video;
            window.location.href =
              "/projects/" +
              video.video_project.project_id +
              "/edit/" +
              video.video_id;
          } else {
            console.log("video move error", response);
          }
        });
      }
    },
    openVideoUploadPopup() {
      var $this = this;
      this.replace_popup_step = 2;

      const uppy = Uppy({
        debug: true,
        autoProceed: true,
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes: ["video/*"]
        }
      })
              .use(Dashboard, {
                inline: true,
                width: "100%",
                height: 250,
                target: "#video_replace_area",
                replaceTargetContent: true,
                showProgressDetails: true,
                animateOpenClose: true,
                locale: {
                  strings: {
                    dropPaste: "Drag and drop videos here or %{browse}",
                    browse: "browse",
                    myDevice: "BROWSE",
                    dropPasteImport: "Drag and drop videos here, browse or import",
                    addMoreFiles: "Add more videos",
                    addingMoreFiles: "Adding more videos",
                    editFile: "Edit video",
                    removeFile: "Remove video",
                    xFilesSelected: {
                      0: "%{smart_count} video selected",
                      1: "%{smart_count} videos selected"
                    },
                    uploadingXFiles: {
                      0: "%{smart_count} video uploading",
                      1: "%{smart_count} videos uploading"
                    },
                    processingXFiles: {
                      0: "%{smart_count} video processing",
                      1: "%{smart_count} videos processing"
                    }
                  }
                },
                metaFields: [{ id: "name", name: "Name", placeholder: "file name" }],
                browserBackButtonClose: true
              })
              .use(AwsS3, {
                getUploadParameters (file) {
                  // console.log(file.meta, file, '===');
                  // Send a request to our PHP signing endpoint.
                  // return fetch('http://powerplayer.g51.gopanear.com/api/video-upload/s3-sign', {
                  return fetch(`${process.env.VUE_APP_BACKEND_URL}api/video-replace/s3-sign/${$this.video.video_id}?replace_type=${$this.replace_type}`, {
                    method: 'POST',
                    // Send and receive JSON.
                    headers: {
                      accept: 'application/json',
                      'content-type': 'application/json',
                      'authorization' : 'Bearer ' + $this.$store.state.accessToken
                    },
                    body: JSON.stringify({
                      filename: file.name,
                      filesize: file.size,
                      duration_formatted: file.meta.duration_formatted,
                      duration: file.meta.duration,
                      contentType: file.type,
                      // projectId:$this.project.id
                    })
                  }).then((response) => {
                    // Parse the JSON response.
                    return response.json()
                  }).then((data) => {
                    $this.video_id = data.fields.video_id
                    // Return an object in the correct shape.
                    return {
                      method: data.method,
                      url: data.url,
                      fields: data.fields,
                      // Provide content type header required by S3
                      headers: {
                        'Content-Type': file.type
                      }
                    }
                  })
                },
                //  endpoint: `${process.env.VUE_APP_BACKEND_URL}api/uploader/${this.project.id}/chunked`,
                resume : false,
                chunkSize : 5242880,
                timeout: 0,
                limit: 5,
                autoRetry: true,
                retryDelays: [0, 1000, 3000, 5000],
                headers: {
                  'Authorization': `Bearer ${this.$store.state.accessToken}`
                }
              });

      uppy.on('file-added', (file) => {
        let vidoeTag = document.createElement('video');
        this.videoComplete = false;
        vidoeTag.src = URL.createObjectURL(file.data);
        console.log(file, '=========== 12');
        vidoeTag.ondurationchange = function() {
          let duration = $this.convertSecondsToTime(this.duration);
          uppy.setFileMeta(file.id, {
            duration: Math.floor(this.duration),
            duration_formatted: duration,

          });
        };
      });

      uppy.on('upload-success', (file, response) => {

        this.axios.post('video-replace/s3-sign/save/data', {
          // duration: file.meta.duration,
          // duration_formatted: file.meta.duration_formatted,
          // drm_protection:file.meta.drm_protection,
          video: response,
          video_id: $this.video.video_id,
          fileType: file.type,
          duration: Math.floor(this.duration),
          duration_formatted: $this.convertSecondsToTime(this.duration),
          drm_protection: $this.drm_protection === false ? 'false' : 'true',
        }).then(response => {

        }).catch(error => {

        });

      });

      uppy.on('file-removed', (file, reason) => {
        console.log('remove');
      });
    },
    // openVideoUploadPopup() {
    //   this.replace_popup_step = 2;
    //
    //   const uppy = Uppy({
    //     debug: true,
    //     autoProceed: true,
    //     restrictions: {
    //       maxNumberOfFiles: 1,
    //       allowedFileTypes: ["video/*"]
    //     }
    //   })
    //     .use(Dashboard, {
    //       inline: true,
    //       width: "100%",
    //       height: 250,
    //       target: "#video_replace_area",
    //       replaceTargetContent: true,
    //       showProgressDetails: true,
    //       animateOpenClose: true,
    //       locale: {
    //         strings: {
    //           dropPaste: "Drag and drop videos here or %{browse}",
    //           browse: "browse",
    //           myDevice: "BROWSE",
    //           dropPasteImport: "Drag and drop videos here, browse or import",
    //           addMoreFiles: "Add more videos",
    //           addingMoreFiles: "Adding more videos",
    //           editFile: "Edit video",
    //           removeFile: "Remove video",
    //           xFilesSelected: {
    //             0: "%{smart_count} video selected",
    //             1: "%{smart_count} videos selected"
    //           },
    //           uploadingXFiles: {
    //             0: "%{smart_count} video uploading",
    //             1: "%{smart_count} videos uploading"
    //           },
    //           processingXFiles: {
    //             0: "%{smart_count} video processing",
    //             1: "%{smart_count} videos processing"
    //           }
    //         }
    //       },
    //       metaFields: [{ id: "name", name: "Name", placeholder: "file name" }],
    //       browserBackButtonClose: true
    //     })
    //     .use(Tus, {
    //       endpoint: `${process.env.VUE_APP_BACKEND_URL}api/video-replace/${this.video.video_id}/chunked?replace_type=${this.replace_type}`,
    //       resume: true,
    //       chunkSize: 5242880,
    //       timeout: 0,
    //       limit: 1,
    //       autoRetry: true,
    //       retryDelays: [0, 1000, 3000, 5000],
    //       headers: {
    //         Authorization: `Bearer ${this.$store.state.accessToken}`
    //       }
    //     });
    // },
    convertSecondsToTime(given_seconds) {

      let dateObj = new Date(given_seconds * 1000),
              hours = dateObj.getUTCHours(),
              minutes = dateObj.getUTCMinutes(),
              seconds = dateObj.getSeconds();

      return hours.toString().padStart(2, '0') + ':' +
              minutes.toString().padStart(2, '0') + ':' +
              seconds.toString().padStart(2, '0');
    },
    closeDashboardArea() {
      this.$parent.showReplaceVideo = false;
      this.replace_popup_step = 1;

      uppy.close();
    },
    copyCheckedProjectVideos: function() {
      if (this.selected_copy_project == null) {
        this.copyProjectsError = true;
      } else {
        this.loadingCopyToProject = true;
        this.copyProjectsError = false;
        var $this = this;
        var data = {};
        data.video_id = $this.video.id;
        data.copied_project_id = $this.selected_copy_project.id;

        this.axios({
          method: "post",
          url: "/editor/video/copy-video-to-project",
          data: data
        }).then(function(response) {
          this.loadingCopyToProject = false;
          $this.showCopyProject = false;
          $this.$message({
            showClose: true,
            message: "Your video has been copied.",
            type: "success"
          });
        });
      }
    },

    copyCheckedVidoeSettings: function() {
      if (!this.settings_confirm_understand) {
        this.copySettingsErrorNotAgree = true;
      } else {
        this.copySettingsErrorNotAgree = false;
        var $this;
        $this = this;
        var data = {};
        data.video_id = $this.video.id;
        data.copied_video_id = $this.selected_video.id;
        data.settings_apperance = $this.settings_apperance;
        data.settings_thumbnail = $this.settings_thumbnail;
        data.settings_controls = $this.settings_controls;
        data.settings_privacy = $this.settings_privacy;
        data.settings_before = $this.settings_before;
        data.settings_during = $this.settings_during;
        data.settings_after = $this.settings_after;

        this.axios({
          method: "post",
          url: "/editor/video/copy-settings",
          data: data
        }).then(function(response) {
          $this.video.player_options = response.data.player_options;
          $this.showCopySettingApplyConfirmWindow = false;
          $this.showCopySettings = false;
          window.location.reload();
          $this.$message({
            showClose: true,
            message: "Your changes have been saved.",
            type: "success"
          });
        });
      }
    },
    editTitleAndDescription: function() {
      if (this.editedVideoTitle == "") {
        this.showTitleRequiredError = true;
      } else {
        this.showConfirmPopup = true;
      }
    },
    editTitleConfirmPopup: function() {
      var $this;
      $this = this;
      var data = {};
      data.video_id = $this.video.id;
      data.video_title = $this.editedVideoTitle;
      data.author_name = $this.editedVideoAuthor;
      data.video_description = $this.editedVideoDescription;
      data.video_tags = $this.videoTags;
      this.axios({
        method: "post",
        url: "/editor/video/edit-title",
        data: data
      }).then(function(response) {
        $this.video.title = $this.editedVideoTitle;
        $this.video.author_name = $this.editedVideoAuthor;
        $this.video.description = $this.editedVideoDescription;
        $this.video.tags = $this.videoTags;
        $this.showConfirmPopup = false;
        $this.editDialogVisible = false;
        $this.$message({
          showClose: true,
          message: "Your changes have been saved.",
          type: "success"
        });
      });
    },
    showCopySettingApplyConfirm: function() {
      if (
        this.selected_video == null ||
        (!this.settings_apperance &&
          !this.settings_thumbnail &&
          !this.settings_controls &&
          !this.settings_privacy &&
          !this.settings_before &&
          !this.settings_during &&
          !this.settings_after)
      ) {
        this.errorNotCheckedSettings = true;
      } else {
        this.errorNotCheckedSettings = false;
        this.showCopySettingApplyConfirmWindow = true;

        for (var i = 0; i < this.videos.length; i++) {
          if (this.videos[i].id == this.selected_video.id) {
            this.selectedVideoTitle = this.videos[i].title;
          }
        }
      }
    },
    onPlayerPlay: function(player) {
      this.$emit("play", player);
    },

    onPlayerPause: function(player) {
      this.$emit("pause", player);
    },
    getPlayer: function() {
      return this.$refs.video.videoPlayer.player;
    },
    loadVideo: function() {
      var $this;
      $this = this;
      if (!localStorage.getItem("user-video-cookie")) {
        localStorage.setItem("user-video-cookie", this.makeid(15));
      }
      return this.axios({
        method: "get",
        url: "/editor",
        params: {
          id: this.$route.params.id,
          userCookie: localStorage.getItem("user-video-cookie")
        }
      })
        .then(function(response) {
          if (
            typeof response.data.success !== typeof undefined &&
            response.data.success === false
          ) {
            $this.$message({
              type: "error",
              message: response.data.message
            });

            setTimeout(() => {
              $this.$router.push({ name: "AllProject" });
            }, 2000);
          } else {
            $this.video = response.data.video;


            var eUrl = response.data.video.embed_url;
            $this.watchUrl = eUrl.replace('/player/', '/watch/');
            //$ths.video.schedular = ($this.video.schedular)?$this.video.schedular:{};

            // $this.getThumbnailsDetails();
            $this.loadStatisticsData();
            // $this.loadAllVideosAndProjectsData();
            $this.beforeEditingVideoCache = $this.video;
            $this.details = response.data.details;
            $this.ownerStatus = response.data.ownerStatus;
            $this.bandwidthData = response.data.usedBandwidth;
            setTimeout(function() {
              $this.changesMade = false;
              $this.videoLoaded = true;
            }, 700);
            return (document.title = $this.video.title + " | 4KPlayer");
          }
        })
        ["catch"](function(response) {
          $this.$message({
            type: "error",
            message: response.message
          });
          // return document.location = '/404';
        });
    },
    loadStatisticsData() {
      this.filteredData = null;
      this.filterQuery.checkedVideos = [this.video.id];
      this.loading = true;

      let post_data = {
        url: "/analytics/all_statistics",
        data: {
          ...this.filterQuery,
          active: "overview"
        }
      };

      this.$store
        .dispatch("post", { ...post_data })
        .then(response => {
          this.filteredData = response.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    // loadAllVideosAndProjectsData() {
    //   let post_data = {
    //     url: "/editor/get_all_videos_and_projects",
    //     data: {
    //       user_id: this.user.id
    //     }
    //   };
    //   var $this;
    //   $this = this;
    //   this.$store
    //     .dispatch("post", { ...post_data })
    //     .then(response => {
    //       $this.videos = response.data.videos;
    //       $this.projects = response.data.projects;
    //       $this.selected_video = $this.videos[0];
    //       $this.selected_copy_project = $this.projects[0];
    //       $this.selected_move_project = $this.projects[0];
    //     })
    //     .catch(error => {});
    // },
    changeVideo(video) {
      this.video = video;
      // alert("Player would change now to " + video.title);
    },

    onPlayerTimeupdate: function(player) {
      var $this, currentTime;
      $this = this;
      if (player !== void 0) {
        currentTime = player.currentTime();
        if (
          Math.abs(currentTime - this.currentTime) > 1.5 ||
          this.currentTime <= 0
        )
          return (this.currentTime = currentTime);
      }
    },
    handlePause: function() {
      if (
        this.$el
          .querySelector(".vjs-play-control")
          .classList.contains("vjs-playing")
      ) {
        this.$el.querySelector(".vjs-play-control").click();
      }
    },
    handleTimeChange: function(time) {
      return this.player.currentTime(time);
    },
    changeTitle: function(newTitle) {
      this.video.title = newTitle;
      return this.handleSave();
    },
    handleCommand: function(command) {
      var $this;
      if (command === "deleteVideo") {
        this.showDeletePopup = true;
        // $this = this;
        // this.$swal({
        // 	title: 'Are you sure?',
        // 	text: 'Deleted videos are gone forever',
        // 	type: 'warning',
        // 	showCancelButton: true,
        // 	confirmButtonText: 'Delete',
        // 	cancelButtonText: 'Cancel'
        // }).then(function() {
        // 	var contentDiv;
        // 	contentDiv = document.getElementsByClassName('swal2-content').item(0);
        // 	contentDiv.innerHTML = '<i class="el-icon-loading"></i>';
        // 	contentDiv.style.display = 'block';
        // 	return $this.axios({
        // 		method: 'delete',
        // 		url: '/editor',
        // 		data: {
        // 			id: $this.video.id
        // 		}
        // 	}).then(function(response) {
        // 		contentDiv.style.visibility = 'hidden';
        // 		$this.$router.push({
        // 			name: 'ProjectView',
        // 			params: {
        // 				id: $this.video.project.id
        // 			}
        // 		});
        // 	})["catch"](function(error) {
        // 		$this.$message({
        // 			showClose: true,
        // 			message: error.message,
        // 			type: 'error'
        // 		});
        // 	});
        // });
      }
      if (command === "openShareAndEmbed") {
        this.embedModal = true;
      }

      if (command === "duplicateVideo") {
        this.showDuplicatePopup = true;
        this.duplicateVideoTitle = this.video.title + " 2";
      }
      if (command === "copyVideoLink") {
        var el = document.createElement("textarea");
        el.value = this.video.embed_url;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      }
      if (command === "editTitleAndDescription") {
        var $thisss = this;
        //need to open popup and edit vidoe title and description
        this.showTitleRequiredError = false;
        this.editDialogVisible = true;
        this.editedVideoTitle = this.video.title;
        this.editedVideoAuthor = this.video.author_name;
        this.editedVideoDescription = this.video.description;
        this.videoTags = this.video.tags;
      }
      if (command === "copySettings") {
        this.showCopySettings = true;
        //need to open popup and copy vidoe settings from selected video
      }
      if (command === "copyToProject") {
        this.showCopyProject = true;
        //need to open popup and copy vidoe in selected project
      }
      if (command === "moveToProject") {
        this.showMoveProject = true;
        //need to open popup and move vidoe in selected project
      }
      if (command === "downloadVideo") {
        this.showDownloadVideo = true;
        //need to open popup and download this.video.files
      }
      if (command === "replaceVideo") {
        this.showReplaceVideo = true;
        //need to open popup and upload new video for replacing existing video
      }

    },
    getSetDescendantProp: function(obj, desc, value) {
      var arr, arrayData, comp, match;
      arr = desc ? desc.split(".") : [];
      while (arr.length && obj) {
        comp = arr.shift();
        match = new RegExp("(.+)\\[([0-9]*)\\]").exec(comp);
        if (match !== null && match.length === 3) {
          arrayData = {
            arrName: match[1],
            arrIndex: match[2]
          };
          if (obj[arrayData.arrName] !== void 0) {
            if (typeof value !== "undefined" && arr.length === 0) {
              obj[arrayData.arrName][arrayData.arrIndex] = value;
            }
            obj = obj[arrayData.arrName][arrayData.arrIndex];
          } else {
            obj = void 0;
          }
          continue;
        }
        if (typeof value !== "undefined") {
          if (obj[comp] === void 0) {
            obj[comp] = {};
          }
          if (arr.length === 0) {
            obj[comp] = value;
          }
        }
        obj = obj[comp];
      }
      return obj;
    },
    handleChangeStartAndEndTime: function(event) {},
    handleSave() {
      var $this = this;
      this.$validator.validateAll().then(success => {
        if (this.errors.items.length >= 1) {
          return false;
        }
        $this.video.schedular = this.$store.state.publish.video_Publish
        $this.loading = true;
        let formData = new FormData;
        formData.append('shareGateCusImg', this.shareCustomImage);
        formData.append('video_id', $this.video.id);
        $this
          .axios({
            url: "/editor",
            method: "PUT",
            data: {
              video: $this.video
            }
          })
          .then(function(response) {
            // console.log($this.video.video_basecodes, 'this.video.video_basecodes');
            if($this.shareCustomImage) {
              $this.axios.post("/share-gate-image", formData)
              .then(function(res) {
                if(res.data.status != 200) {
                  $this.$message({
                    showClose: true,
                    message: res.data.message ? res.data.message : 'Something went wrong!',
                    type: "error"
                  });
                }
              })
            }
            $this.loading = false;
            $this.changesMade = false;
            $this.beforeEditingVideoCache = $this.video;
            if($this.video.video_basecodes !== null){

              document.getElementById('listPixelRetargeting').classList.add('apply')
              document.getElementById('videoEventCodeListing').classList.add('stored')
              document.getElementById('videoBaseCodeListing').classList.add('stored')
              var e = document.querySelectorAll('.video_basecode_input');
              for (var i = 0; i < e.length; i++) {
                e[i].classList.remove('edit')
              }
              $this.video = response.data.video;

            }
            // document.getElementById('video_basecode_input').classList.remove('edit')
            $this.$message({
              showClose: true,
              message: "Your changes have been saved.",
              type: "success"
            });
          })
          .catch(function(response) {
            $this.loading = false;
            $this.$message({
              showClose: true,
              message: response.message,
              type: "error"
            });
            return console.log("erResponse2", response);
          });
      });
    },
    handleReset() {
      // TODO: implement reset all changes fun
      window.location.reload();
    },
    // three level structure
    setActiveLevel(item) {
      this.activeLevel2 = item;
    },
    toggleActiveLevel(item) {
      if (this.activeLevel2 == item) return (this.activeLevel2 = false);
      this.setActiveLevel(item);
    },
    // getThumbnailsDetails() {
    //   this.thumbnail_details = false;
    //   this.axios
    //     .post("thumbnail-details", {
    //       video_id: this.video.id
    //     })
    //     .then(r => {
    //       if (r.data.success == 1) {
    //         let data = r.data.data;
    //         this.thumbnail_details = data;
    //       }
    //     });
    // },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1024) return false;
      return true;
    },
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
  },
  watch: {
    video: {
      handler: debounce(function(value) {

        this.changesMade = true;
        this.urlVideoObj = {
          id: value.id,
          video_id: value.video_id,
          project: value.project,
          power_player: value.power_player,
          power_player_type: value.power_player_type,
          owner: value.owner,
          team: value.team,
          is_audio: value.is_audio,
          title: value.title,
          visual_watermarking: value.visual_watermarking,
          restricted: value.restricted,
          drm_protection: value.drm_protection,
          dash_url_drm: value.dash_url_drm,
          drm: value.drm,
          files: value.files,
          thumbnail: value.thumbnail,
          hls_url_aes: value.hls_url_aes,
          path: value.path,
          duration: value.duration,
          thumbnail_scrumb: value.thumbnail_scrumb,
          forensic_watermarking: value.forensic_watermarking,
          // video_project: {
          //   id: value.project.id
          // },
          schedular: value.schedular,
          // schedular: value.schedular,
          video_owner: {
            id: value.video_owner_detail.id,
            name: value.video_owner_detail.name,
            payment_platforms_id: value.video_owner_detail.payment_platforms_id,
            billing_status: value.video_owner_detail.billing_status,
            status_id: value.video_owner_detail.status_id,
            settings: {
              pause_player: value.video_owner_detail.settings.pause_player,
              sticky_player: value.video_owner_detail.settings.sticky_player,
              global_player_color: value.video_owner_detail.settings.global_player_color,
            },
            current_plan: {},
            branding_logos:this.user.branding_logos
          },
          player_options: value.player_options,
          video_chapters: value.video_chapters,
          video_basecode: value.video_basecodes,
          video_subtitles: value.video_subtitles,
          resolutions: value.resolutions,
          child_videos: value.child_videos

        }


        if(document.getElementById('video_iframe') !== null){
          document.getElementById('video_iframe').contentWindow.postMessage({'video_changed': true, 'new_obj': JSON.stringify(this.urlVideoObj)}, '*');
        }


      }, 2000),
      deep: true
    },

    "video.thumbnail"(value) {
      if (this.playerReady) this.$refs.video.videoPlayer.player.poster(value);
    },
  }
};
</script>


<style lang="less">
@import "../assets/less/sprites";
@import "../assets/less/video";

#videoEditorWrap {
  background: #fafafa;
  padding-bottom: 80px;
}

.back-headline-btn {
  margin-bottom: 10px;
}

.custom_big_select {
  padding: 7px 20px 7px 7px;
  margin-top: 12px;
}

.custom_big_select .item_title {
  margin-left: 15px;
  text-transform: capitalize;
}

.custom_popover .item_title {
  margin-left: 15px;
  text-transform: capitalize;
}

.custom_big_select .el-input .el-input__inner {
  height: 70px;
}

.custom_big_select .el-input .el-input__prefix {
  position: absolute;
  left: 0;
}

.custom_big_select span {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.el-checkbox + .el-checkbox {
  margin-left: 0;
}

.el-select-dropdown__item {
  height: max-content;
}

.item__duration {
  background-color: #011a22;
  color: white;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  padding: 1px 4px 1px 5px;
  border-radius: 14px;
  position: absolute;
  right: 1px;
  z-index: 100;
  bottom: 1px;
}

.item_title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(31, 46, 61);
}

.item_title.project {
  flex-direction: column;
  align-items: flex-start;
}

.el-switch.is-disabled {
  opacity: 1.6;
}

.checkIconBox {
  height: 25px;
  width: 25px;
}

#videoEditor {
  .el-tabs {
    .el-tabs__active-bar {
      background-color: #239edb;
    }

    .el-tabs__item {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 6px;
      color: #e3e3e3;

      &:hover {
        color: rgb(131, 146, 165);
      }

      &.is-active {
        color: #14b3f2;
      }
    }
  }
}

.el-button--gray {
  background-color: #21455e;
  opacity: 0.7;
  color: #fff;
  text-decoration: none;
  outline: none;
  border-radius: 30px;
  transition: ease-in 0.2s;

  &:hover {
    background-color: #21455e;
    color: #fff;
    opacity: 1;
  }
}

.btn.btn-primary {
  background-color: #0dabd8;
}
.i-btn-btn {
  background-color: #065fa2 !important;
  border-radius: 22px !important;
}
.i-btn-btn:hover {
  background-color: #152c78 !important;
}

.item__image {
  width: 80px;
  height: 50px;
}

.prefix {
  width: max-content;
  height: 50px;
}

.videos-list-group ul {
  padding: 0;
  list-style: none;
  max-height: 200px;
  overflow-x: hidden;
}

.scroll::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  border-radius: 10px;
  /*background-color: #F5F5F5;*/
}

.scroll::-webkit-scrollbar {
  width: 12px;
  /*background-color: #F5F5F5;*/
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c7eff9;
}

.custom_popover {
  position: relative;
  padding: 25px 8px 10px 8px;
}

.custom_popover .popover-content .searchBox {
  position: relative;
  margin: 0 25px;
}

.searchInput .el-input__inner {
  border: 1px solid var(--color-border-inputbox-search);
  height: 32px !important;
  line-height: 34px !important;
  font-size: 13px !important;
  border-radius: 25px !important;
  padding-left: 30px !important;
}

.searchIcon {
  vertical-align: middle !important;
  position: absolute !important;
  left: 8px !important;
  top: 8px !important;
}

.el-select-dropdown__item.selected {
  color: rgb(72, 88, 106);
  background: none;
}

.el-breadcrumb__item .el-breadcrumb__inner.is-link, .el-breadcrumb__item {
  cursor: pointer;
  color: var(--textThemeColor);
}

.el-breadcrumb__item .el-breadcrumb__inner.is-link:hover {
  cursor: pointer;
  color: #62bfe2;
}

.btn-primary {
  color: #fff;
  background-color: #309ce3;
  border-color: #309ce3;
  border-radius: 30px;
  padding: 8px 25px;
  font-weight: 600;
  border: none !important;
}

.custom_input_new_style {
  width: 100%;
}

.dialog_edit {
  .el-dialog__title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    font-size: 15px;
    color: var(--color-content);
  }

  .el-dialog__body {
    width: 100%;

    label {
      font-weight: normal;
      font-size: 13px;
      margin-bottom: 7px;
      color: var(--color-content);
    }

    .el-textarea__inner:focus,
    .el-input__inner:focus {
      border-color: var(--color-blue);
    }

    .resize_none textarea {
      resize: none;
    }

    .errorBorder {
      border: 1px solid #ee5951;
    }

    .errorBox {
      display: flex;
      padding-top: 5px;

      .errorIcon {
        margin-right: 5px;
      }
    }
  }

  .dialog-footer {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    @media (max-width: 414px) {
      width: 100%;
    }

    button {
      margin: 0;
      padding: 8px 30px !important;

      &:hover,
      &:focus {
        border-color: initial;
        color: #fff;
      }
    }
  }
}

.dialog_border_radius {
  .el-dialog {
    border-radius: 5px;
    border: 1px solid var(--border-color);
  }
}
.el-switch__core {
  background: var(--backgroundThemeColor);
}
.VideoEditor {
  b,
  p,
  label {
    /*color: #21455e;*/
    color: #ffffff;
  }

  button {
    color: #ffffff;
    * {
      color: #ffffff;
    }
  }

  form {
    height: 100%;
    > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      padding-right: 10px;
    }
  }

  .el-dialog {
    border: 1px solid #0bacdb;
    border-radius: 12px;
  }

  .el-dialog--center {
    .el-dialog__header {
      padding: 25px 40px;
    }
    .el-dialog__footer {
      padding: 0 40px 25px 40px;
    }
  }

  .el-dialog__body {
    padding: 25px 40px 15px 40px;
    width: 100%;

    * {
      color: #21455e;
    }

    label {
      margin-bottom: 10px;
      font-weight: 500;
    }

    ul.tools {
      padding: 0;
      list-style-position: inside;
      li {
        margin-bottom: 15px;
      }
    }

    .text {
      padding-bottom: 25px;
      font-weight: 400;
      width: 100%;
    }

    .label_text {
      font-size: 13px;
      font-weight: 500;
    }

    .understand .el-checkbox__label {
      margin-left: 20px;
    }

    .label_tools {
      opacity: 0.7;
    }
  }

  .el-dialog__title {
    text-align: center;
    font-size: 15px;
    letter-spacing: 1.05px;
    text-transform: uppercase;
    color: #21455e;
  }
  .back-to-replace {
    font-size: 30px;
    position: absolute;
    top: 5px;
    text-decoration: none;
    left: 3%;
    color: #c0c5ca;
    cursor: pointer;
    &:hover {
      color: #0bacdb !important;
    }
  }
}

.blurred {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

@media (max-width: 1024px) {
    .desktop-only {
        // #Css fix-5 added;
        display: none !important;
    }
    .no-desktop {
        display: block !important;
    }
    .no-desktop-flex {
        display: flex !important;
    }
}
</style>

<style lang="less">


  .video_iframe{
    display: block;
  }

.download-popup{
.el-dialog__body {
  padding: 30px 0px !important;
}
p {
  font-weight: lighter;
  color: #21455e;
}
.table > tbody > tr > td {
  padding-top: 16px;
  padding-bottom: 16px;

  border-top: 1px solid #ebf9fa;
}
.table > thead > tr > th {
  letter-spacing: 0.91px;
  vertical-align: bottom;
  border-bottom: 0px solid #ddd;
}
.table > thead {
  background: #0bacdb05 0% 0% no-repeat padding-box;
}
.table > tbody > tr > td:first-child {
  padding-left: 35px;
}
.table tbody {
  padding: 0;
  list-style: none;
  max-height: 300px;
  overflow-x: hidden;
  width: 99.5%;
}
.table tbody::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  border-radius: 10px;
  /*background-color: #F5F5F5;*/
}

.table tbody::-webkit-scrollbar {
  width: 12px;
  /*background-color: #F5F5F5;*/
}

.table tbody::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c9edf8;
}

tbody {
  display: block;
  height: 300px;
  overflow: auto;
}
thead,
tbody tr {
  display: table;
  width: 99.5%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}
}
.copy-project{
    .custom_popover {
    position: absolute;

    padding: 25px 8px 10px;
    width: 89%;
}
.form-group.search-group {
    padding-left: 20px;
    padding-right: 21px;
}

   .searchIcon{
       vertical-align: middle !important;
    position: absolute !important;
    left: 39px !important;
    top: 33px !important;
   }
   .searchInput .el-input__inner {


    border: 1px solid var(--color-border-inputbox-search);
    height: 32px !important;
    line-height: 34px !important;
    font-size: 13px !important;
    border-radius: 25px !important;
    padding-left: 30px !important;
   }
}
</style>